import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import Card from "react-bootstrap/Card";
import { AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import {
  updateCabinetState,
  updateInfoCabinet,
} from "../../redux/actions/supAdmin";
import Loader from "../../Utils/Loader";

function ClientPro({ data, setGetUpdated }) {
  const [updateModal, setUpdateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalType, setModalType] = useState({});
  const [name, setName] = useState("");
  const [cabinetPhone, setCabinetPhone] = useState("");
  const [emailCabinet, setEmailCabinet] = useState("");
  const [dirigeantCabinetName, setDirigeantCabinetName] = useState("");
  const [secteur, setSecteur] = useState("");
  const [rc, setRc] = useState("");
  const [ice, setIce] = useState("");
  const [email, setEmail] = useState("");
  const [adresse, setAdresse] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [active, setActive] = useState();

  const dispatch = useDispatch();

  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  const handleOpenUpdate = () => {
    setUpdateModal(true);
    setName(data.name || "");
    setCabinetPhone(data.cabinetPhone || "");
    setEmailCabinet(data.emailCabinet || "");
    setDirigeantCabinetName(data.dirigeantCabinetName || "");
    setSecteur(data.secteur || "");
    setRc(data.rc || "");
    setIce(data.ice || "");
    setEmail(data.emailDirigeant || "");
    setAdresse(data.adresse || "");
    setMoreInfo(data.moreInfo || "");
  };

  const handleUpdateInfo = async () => {
    if (!data._id) {
      return alert("Il y a un problème dans l'actualisation des informations");
    }

    setUpdateModal(false);
    setShowLoadingModal(true);
    setUpdateModal(false);
    await dispatch(
      updateInfoCabinet(data._id, {
        name,
        cabinetPhone,
        emailCabinet,
        dirigeantCabinetName,
        secteur,
        rc,
        ice,
        email,
        adresse,
        moreInfo,
      })
    );
    setShowLoadingModal(false);
    setGetUpdated(true);
  };

  const handleUpdateActivation = async () => {
    await setModalType({
      type: data.active === true ? "Désactivation du " : "Activation du ",
      confirmation: data.active === true ? "désactiver " : "activer ",
    });
    setActive(!data.active);
    setShowConfirmModal(true);
  };

  const handleConfirmAction = async () => {
    //console.log(modalType);
    if (
      modalType.type === "Désactivation du " ||
      modalType.type === "Activation du "
    ) {
      //console.log("hello activation");
      //console.log(active);

      setShowLoadingModal(true);
      setUpdateModal(false);
      setShowConfirmModal(false);
      await dispatch(updateCabinetState(data._id, { active }));
      setShowLoadingModal(false);
      setGetUpdated(true);
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Card>
        <Card.Header>
          <div className="flex items-center justify-between">
            <span>Client : {data.name || ""}</span>
            <AiFillEdit className="cursor-pointer" onClick={handleOpenUpdate} />
          </div>
        </Card.Header>
        <Card.Body>
          <span className="font-bold">Informations du client : </span>
          <div className="800px:flex justify-around block">
            <div className="flex flex-col mr-5">
              <span>
                {" "}
                Directeur Cabinet : {data.dirigeantCabinetName || ""}{" "}
                {data.dirigeantCabinetPrenom || ""}
              </span>
              <span> Numéro de téléphone : {data.cabinetPhone || ""} </span>
              <span> Email : {data.emailDirigeant || ""}</span>
              <span> secteur : {data.secteur || ""}</span>
              <span> Plus d'informations : {data.moreInfo || ""} </span>
            </div>
            <div className="flex flex-col">
              <span> Adresse : {data.adresse || ""}</span>
              <span> Email cabinet :{data.emailCabinet || ""}</span>
              <span> ICE : {data.ice || ""} </span>
              <span> RC : {data.rc || ""} </span>
              <span className={data.active === false ? "bg-yellow-300" : ""}>
                {" "}
                Etat : {data.active === true ? "Active" : "Inactive" || ""}{" "}
              </span>
            </div>
          </div>

          <span className="font-bold">Disposition des documents : </span>
          <div className="800px:flex justify-around  block mt-2">
            <div className="flex items-center ">
              Arborescence :
              <Link to={`/supAdmin/${data._id}/arborescence`} className="ml-2">
                <Button className="text-gray-800 mt-1" variant="primary">
                  Voir Arborescences
                </Button>
              </Link>
            </div>
            <div>
              <div className="flex items-center ">
                Menus CRM :
                <Link to={`/supAdmin/${data._id}/menuClient`} className="ml-2">
                  <Button className="text-gray-800 mt-1" variant="primary">
                    Voir Menus
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={updateModal} onHide={closeUpdateModal}>
        <Modal.Header>
          <Modal.Title className="text-sm">
            Modifier les informations de la société et de l'admin
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeUpdateModal}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formSocieteName">
            <Form.Label>Nom de la société</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Directeur (admin)</Form.Label>
            <Form.Control
              type="text"
              value={dirigeantCabinetName}
              onChange={(e) => setDirigeantCabinetName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Numéro de téléphone</Form.Label>
            <Form.Control
              type="tel"
              value={cabinetPhone}
              onChange={(e) => setCabinetPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email Cabinet</Form.Label>
            <Form.Control
              type="email"
              value={emailCabinet}
              onChange={(e) => setEmailCabinet(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formDirigeantName">
            <Form.Label>Secteur</Form.Label>
            <Form.Control
              type="text"
              value={secteur}
              onChange={(e) => setSecteur(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formDirigeantName">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPortableNumber">
            <Form.Label>RC</Form.Label>
            <Form.Control
              type="text"
              value={rc}
              onChange={(e) => setRc(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formAdresseSociete">
            <Form.Label>ICE</Form.Label>
            <Form.Control
              type="text"
              value={ice}
              onChange={(e) => setIce(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formAdresseSociete">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type="text"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formAdresseSociete">
            <Form.Label>Informations complémentaires</Form.Label>
            <textarea
              className="form-control"
              rows="4" // Nombre de lignes visibles de la zone de texte
              value={moreInfo}
              onChange={(e) => setMoreInfo(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="flex justify-between">
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={handleUpdateActivation}
          >
            {data.active === true ? "Désactiver" : "Activer"}
          </Button>
          <div>
            <Button
              className="bg-gray-500 mr-2 hover:bg-gray-700"
              onClick={closeUpdateModal}
            >
              Annuler
            </Button>
            <Button className="bg-blue-500" onClick={handleUpdateInfo}>
              Mettre à jour
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmModal}
        dialogClassName="max-w-[700px] top-1/4"
        className=" px-2 bg-transparent "
        onHide={() => setShowConfirmModal(false)}
      >
        {" "}
        {/* Ajouter la classe "modal-lg" pour le rendre plus large */}
        <Modal.Header>
          <Modal.Title className="text-sm font-bold">
            Confirmation : {modalType.type} {data?.name}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowConfirmModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="mb-2 text-sm">
            {" "}
            Êtes vous sur de vouloir {modalType.confirmation}
            <span className="font-bold"> le compte {data?.name}</span> ? <br />
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setShowConfirmModal(false)}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={handleConfirmAction}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </div>
  );
}

export default ClientPro;
