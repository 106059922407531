import React from "react";

import ClosedFolderFile from "./ClosedFolderFile.jsx";
import OpenedFolderFile from "./OpenedFolderFile.jsx";

const ArborescenceSideMove = ({
  dossierClient,
  openedFile,
  setOpenedFile,
  folderFile,
  
}) => {
  
  

  const position = dossierClient?.parentId?.length;
  

  // page folderFile du dossier client : charger from reducer

  

  return (
    <div className="">
      {dossierClient?.files?.map((file1) => {
        if (file1.type === "folder" && openedFile?.includes(file1?.pathById)) {
          return (
            <div key={file1._id}>
              <OpenedFolderFile
                file={file1}
                position={position}
                setOpenedFile={setOpenedFile}
                openedFile={openedFile}
                showMoveModal='true'
              />
              {file1?.files?.length > 0 ? (
                <ArborescenceSideMove
                  dossierClient={file1}
                  folderFile={file1}
                  openedFile={openedFile}
                  setOpenedFile={setOpenedFile}
                  showMoveModal='true'
                />
              ) : (
                <div className="text-center">
                  <span className="italic text-gray-500">Dossier vide</span>
                </div>
              )}
            </div>
          );
        } 
        else if (file1?.type === "folder") {
          return (
            <ClosedFolderFile
              key={file1._id}
              file={file1}
              setOpenedFile={setOpenedFile}
              openedFile={openedFile}
              position={position}
              folderFile={folderFile}
              showMoveModal='true'
            />
          );
        }
        return null;
      })}
      
     
    </div>
  );
};

export default ArborescenceSideMove;
