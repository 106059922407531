import React, { useEffect, useState } from "react";
import { HiSearch, HiXCircle } from "react-icons/hi";
import {
  AiFillFileAdd,
  AiOutlineFileWord,
  AiFillFileText,
} from "react-icons/ai";
import { FiMenu, FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { PDFDocument } from "pdf-lib";
import { useDispatch } from "react-redux";
import { FaFilePdf, FaFileExcel, FaRegFilePowerpoint } from "react-icons/fa";
import Loader from "../../Utils/Loader";
import { uploadFiles } from "../../redux/actions/fileAction";

const SubHeader = ({
  isOpen,
  toggleSidebar,
  myDossier,
  categories,
  categoryFiles,
  setIsUpdated,
  keywordSearch,
  setKeywordSearch,
}) => {
  const [showDropzone, setShowDropzone] = useState(false);
  const [files, setFiles] = useState([]);
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const [showDuplicateModals, setShowDuplicateModals] = useState(false);
  const [isCompressing, setIsCompressing] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [dossierClient, setDossierClient] = useState("");
  const [clientFiles, setClientFiles] = useState([]);

  const { catId } = useParams();
  //console.log("catId", catId);

  //console.log("categoryFiles3", categoryFiles);

  const dispatch = useDispatch();
  const handleCloseDropzone = () => {
    setShowDropzone(false);
    setFiles([]);
  };

  const onChangeSearch = (e) => {
    localStorage.setItem("keyword", e.target.value);
    setKeywordSearch(e.target.value);
  };

  const clearKeywordSearch = () => {
    localStorage.setItem("keyword", "");
    setKeywordSearch("");
  };
  // get category where uploading
  let category;
  if (categories?.length > 0 && catId !== undefined) {
    category = categories.find((cat) => cat._id === catId);
  }

  // get id client and id documents client
  useEffect(() => {
    if (myDossier?.client) {
      // console.log(
      //   "myDossier.client.dossierClient",
      //   myDossier?.client?.dossierClient
      // );
      setDossierClient(myDossier?.client?.dossierClient);
    }
  }, [myDossier?.client]);

  let id;
  let folderFileId;

  if (dossierClient !== "" && dossierClient?.files?.length > 0) {
    id = dossierClient.client;
    folderFileId = dossierClient.files.find(
      (dos) => dos.name === "Documents Client"
    )._id;
  }

  //console.log("id", id);
  //console.log("folderFileId", folderFileId);

  const onDrop = async (acceptedFiles) => {
    const updatedFiles = acceptedFiles.filter((file) => {
      const isDuplicate = files.some(
        (existingFile) => existingFile.name === file.name
      );
      if (isDuplicate) {
        toast.warn(`Le fichier '${file.name}' a déjà été ajouté.`);
      }
      return !isDuplicate;
    });

    try {
      const compressedFiles = await Promise.all(
        updatedFiles.map(async (file) => {
          if (file.type.startsWith("image/")) {
            setIsCompressing(true);
            // Compress the image
            const compressedImage = await imageCompression(file, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
            });
            setIsCompressing(false);
            // Create a new file object with the compressed image
            return new File([compressedImage], file.name, {
              type: compressedImage.type,
              lastModified: Date.now(),
            });
          } else if (file.type === "application/pdf") {
            setIsCompressing(true);
            // Compress the PDF
            const compressedPDF = await compressPDF(file);
            setIsCompressing(false);
            // Create a new file object with the compressed PDF
            return new File([compressedPDF], file.name, {
              type: "application/pdf",
              lastModified: Date.now(),
            });
          } else {
            return file;
          }
        })
      );

      // Create URLs of previews using the compressed files
      const updatedFilesWithPreview = compressedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setFiles((prevFiles) => [...prevFiles, ...updatedFilesWithPreview]);
    } catch (error) {
      console.error("Error compressing files:", error);
      toast.error(
        "Une erreur s'est produite lors de la compression des fichiers."
      );
    }
  };

  const compressPDF = async (file) => {
    try {
      const pdfDoc = await PDFDocument.load(await file.arrayBuffer());

      // Perform the PDF compression here using pdf-lib
      // ...

      // Serialize the modified PDF
      const compressedPDFBytes = await pdfDoc.save();

      return compressedPDFBytes;
    } catch (error) {
      console.error("Error compressing PDF:", error);
      throw error;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  // verif if same file in documents client folder

  useEffect(() => {
    if (dossierClient !== "" && dossierClient?.files?.length > 0) {
      setClientFiles(
        dossierClient.files.find((dos) => dos.name === "Documents Client").files
      );
    }
  }, [dossierClient]);

  useEffect(() => {
    //console.log("clientFiles", clientFiles);
    setDuplicateFiles(
      files.filter((file) =>
        clientFiles?.some(
          (existingFile) =>
            existingFile.name.toLowerCase() === file.name.toLowerCase()
        )
      )
    );
    //console.log("filess", files);
  }, [files, dossierClient]);

  // Rubrique de duplicated

  const handleGetRuprique = (fileName) => {
    const idCategory = clientFiles.find(
      (file) => file.name === fileName
    ).visible;
    return categories.find((cat) => cat._id === idCategory).name;
  };

  const handleReplaceFile = async (file, index) => {
    if (duplicateFiles.length > 1) {
      const updatedDuplicateFiles = duplicateFiles.filter(
        (existingFile) => existingFile.name !== file.name
      );
      setDuplicateFiles(updatedDuplicateFiles);
      setShowDuplicateModals(false);
    } else {
      setShowLoadingModal(true);
      const newForm = new FormData();
      files?.forEach((file) => {
        newForm.append("files", file);
      });

      newForm.append("createdBy", "Client");
      newForm.append("importedBy", "Client");
      newForm.append("catId", catId);
      setShowDropzone(false);

      setShowDuplicateModals(false);
      await dispatch(uploadFiles(id, folderFileId, newForm));
      setFiles([]);
      setIsUpdated(true);

      setShowLoadingModal(false);
    }
  };

  const handleCancelImport = (fileToRemove) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file !== fileToRemove);
      return updatedFiles;
    });

    setShowDuplicateModals(false);
  };

  const handleUploadFiles = async () => {
    if (files.length === 0) {
      return toast.warning("Merci de d'importer au moins un fichier");
    }
    if (duplicateFiles.length > 0) {
      //console.log("duplicateFiles", duplicateFiles);
      setShowDuplicateModals(true);
    } else {
      setShowLoadingModal(true);
      const newForm = new FormData();
      files?.forEach((file) => {
        newForm.append("files", file);
      });

      newForm.append("createdBy", "Client");
      newForm.append("importedBy", "Client");
      newForm.append("catId", catId);
      setShowDropzone(false);

      await dispatch(uploadFiles(id, folderFileId, newForm));
      setFiles([]);
      setIsUpdated(true);

      setShowLoadingModal(false);
    }
  };

  return (
    <div
      className={`flex items-center 800px:justify-end justify-between ${
        catId !== undefined ? "h-[6vh]" : ""
      }  sticky bg-[#fff]   800px:px-10  px-3`}
    >
      <div className="800px:hidden block pt-2">
        {isOpen ? (
          <FiX
            onClick={toggleSidebar}
            className="cursor-pointer text-center "
            color="rgb(59 130 246)"
            size={24}
          />
        ) : (
          <FiMenu
            onClick={toggleSidebar}
            size={24}
            className="cursor-pointer text-center "
            color="rgb(59 130 246)"
          />
        )}
      </div>
      {catId === undefined ? null : (
        <div className="flex items-center">
          <div className="flex items-center relative">
            <input
              type="text"
              value={keywordSearch}
              onChange={onChangeSearch}
              placeholder="Rechercher..."
              className="py-2 pl-8 pr-6 border-b bg-inherit text-sm border-gray-500 w-[150px] md:w-[200px]"
            />
            {keywordSearch ? (
              <div
                className="absolute right-2 cursor-pointer"
                onClick={clearKeywordSearch}
              >
                <HiXCircle size={20} className="text-gray-500" />
              </div>
            ) : (
              <div className="absolute left-2">
                <HiSearch size={20} className="text-gray-500" />
              </div>
            )}
          </div>
          <AiFillFileAdd
            className="cursor-pointer 800px:mr-4 mr-2"
            size={23}
            color="rgb(59 130 246)"
            onClick={() => setShowDropzone(true)}
          />
        </div>
      )}
      <Modal show={showDropzone} onHide={handleCloseDropzone}>
        <Modal.Header>
          <Modal.Title className="text-[16px] font-bold">
            Ajouter des fichiers dans {category?.name}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={handleCloseDropzone}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              {...getRootProps({ className: "dropzone" })}
              className="flex items-center justify-center flex-col text-center cursor-pointer"
            >
              <input {...getInputProps()} />

              <Button className="bg-blue-500 rounded-xl py-3 px-3 my-3">
                {isCompressing ? "En cours..." : "Télécharger un fichier"}
              </Button>
              <p>
                Glissez et déposez des fichiers ici, ou cliquez pour
                sélectionner des fichiers
              </p>
            </div>

            <div className="flex flex-wrap text-center justify-center mt-2">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  {file.type === "application/pdf" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <FaFilePdf size={25} color={"red"} />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : file.type.startsWith("image/") ? (
                    <>
                      <img
                        src={file.preview}
                        alt="file"
                        className="w-[60px] h-[50px]"
                      />
                      <span
                        className="remove-file cursor-pointer"
                        onClick={() => removeFile(index)}
                      >
                        &times;
                      </span>
                    </>
                  ) : file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    file.type === "application/vnd.ms-excel" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <FaFileExcel size={25} color="#217346" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <AiOutlineFileWord size={25} color="#1A73E8" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : file.type ===
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                    file.type === "application/vnd.ms-powerpoint" ||
                    file.type === "application/octet-stream" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <FaRegFilePowerpoint size={25} color="#D24726" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <AiFillFileText size={25} />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={handleCloseDropzone}
          >
            Annuler
          </Button>
          {isCompressing ? (
            <Button className="bg-blue-300">En cours</Button>
          ) : (
            <Button className="bg-blue-500" onClick={handleUploadFiles}>
              Ajouter
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {showDuplicateModals &&
        duplicateFiles.map((file, index) => (
          <Modal
            show={showDuplicateModals}
            key={file.name}
            onClose={() => {
              const updatedShowModals = [...showDuplicateModals];
              updatedShowModals[index] = false;
              setShowDuplicateModals(updatedShowModals);
            }}
            dialogClassName="max-w-[700px] top-1/4"
            className=" px-2 bg-transparent "
          >
            <Modal.Title className="px-2 py-2">Fichier existant</Modal.Title>

            <p className="text-center px-4">
              Le fichier " <span className="font-bold">{file.name}</span>"
              existe déjà dans la rubrique{" "}
              <span className="font-bold">{handleGetRuprique(file.name)} </span>{" "}
              . Voulez-vous le remplacer ?
            </p>
            <div className="flex justify-end space-x-2 px-3 py-3">
              <Button
                className="bg-gray-500 rounded-xl py-3 px-3 my-3 hover:bg-gray-700"
                onClick={() => handleCancelImport(file)}
              >
                Annuler l'importation
              </Button>
              <Button
                className="bg-blue-500 rounded-xl py-3 px-3 my-3"
                onClick={() => handleReplaceFile(file, index)}
              >
                Remplacer le fichier
              </Button>
            </div>
          </Modal>
        ))}
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </div>
  );
};

export default SubHeader;
