import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../Styles/Styles";

import SideBar from "../../Components/UserComponents/SideBar.jsx";
import ClientContent from "../../Components/UserComponents/ClientContent.jsx";
import Header from "../../Components/UserComponents/Header.jsx";

import {
  loadMyDossierClient,
  loadSpcificMenuClient,
} from "../../redux/actions/clientAction";
import SubHeader from "../../Components/UserComponents/SubHeader";
import Loader from "../../Utils/Loader";

const EspaceClientPage = () => {
  const [loading, setLoading] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [id, setId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryFiles, setCategoryFiles] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const { catId } = useParams();

  //console.log("id", id);
  //console.log("catId", catId);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myDossier = useSelector((state) => state.client);

  //console.log("my data", myDossier);
  //console.log("my data 2", myDossier?.client?.dossierClient?.menuClient);
  // //console.log("my files", files);
  //console.log("menu", myDossier?.specificMenu?.menuClient);

  // getMe
  useEffect(() => {
    if (isUpdated === true) {
      handleGetDossierClient();
    }
  }, [isUpdated]);

  useEffect(() => {
    handleGetDossierClient();
  }, []);

  const handleGetDossierClient = async () => {
    setLoading(true);
    await dispatch(loadMyDossierClient());
    setLoading(false);
    setIsUpdated(false);
  };
  useEffect(() => {
    if (loading === false) {
      if (myDossier.error) {
        navigate("/login");
        toast.error(myDossier.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [loading]);

  // loadSpcificMenuClient with all categories
  useEffect(() => {
    if (
      myDossier?.client &&
      myDossier?.client?.dossierClient?.menuClient !== undefined
    ) {
      handleGetSpecificMenu();
      setId(myDossier?.client?.dossierClient?.client);
    }
  }, [myDossier?.client?.dossierClient?.menuClient]);

  const handleGetSpecificMenu = async () => {
    await dispatch(
      loadSpcificMenuClient(myDossier?.client?.dossierClient?.menuClient)
    );
  };

  useEffect(() => {
    if (myDossier?.specificMenu && myDossier?.specificMenu?.menuClient) {
      setCategories(myDossier?.specificMenu?.menuClient?.menu);
    }
  }, [myDossier?.specificMenu]);

  // load files of a specific category method 2
  const getFilesFromFolder = (arr, targetCatId, result = []) => {
    for (const file of arr) {
      if (file.type === "file" && file.visible === targetCatId.toString()) {
        result.push(file);
      }
    }

    for (const subFolder of arr.filter((f) => f.type === "folder")) {
      getFilesFromFolder(subFolder.files, targetCatId, result); // Utilisez subFolder.files pour parcourir les sous-dossiers
    }

    return result;
  };

  useEffect(() => {
    if (catId !== undefined && id !== undefined && id !== null) {
      //console.log("defined", catId);
      //console.log("defined2", id);
      setCategoryFiles(
        getFilesFromFolder(myDossier?.client?.dossierClient?.files, catId)
      );
    }
  }, [catId, id]);

  useEffect(() => {
    if (catId !== undefined && id !== undefined && id !== null) {
      //console.log("defined", catId);
      //console.log("defined2", id);
      setCategoryFiles(
        getFilesFromFolder(myDossier?.client?.dossierClient?.files, catId)
      );
    }
  }, [myDossier]);

  const getSelectedCategory = (data) => {
    setSelectedCategory(data);
  };

  return (
    <div>
      {myDossier?.getMenuLoading === true ||
      myDossier?.getMenuLoading === undefined ? (
        <Loader />
      ) : (
        <>
          <Header myDossier={myDossier} />

          <SubHeader
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
            myDossier={myDossier}
            categories={categories}
            setIsUpdated={setIsUpdated}
            categoryFiles={categoryFiles}
          />
          <div className={`${styles.section} h-[83vh] flex bg-[#f5f5f5] `}>
            <div className=" h-[100%]  ">
              <SideBar
                isOpen={isOpen}
                categories={categories}
                getSelectedCategory={getSelectedCategory}
              />
            </div>
            <div className="w-[80%] border-t">
              {loading === true ? (
                <Loader />
              ) : (
                <ClientContent
                  categoryFiles={categoryFiles}
                  setIsUpdated={setIsUpdated}
                  myDossier={myDossier}
                  categories={categories}
                  selectedCategory={selectedCategory}
                  isOpen={isOpen}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EspaceClientPage;
