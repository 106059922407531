import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import { logoutCollaborateur } from "../../redux/actions/collaborateurAction";
import logo from "../../Files/logo/logo.png";
import { logoutSupAdmin } from "../../redux/actions/supAdmin";

const SupHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logOut = useSelector((state) => state.supAdmin);

  const supAdmin = JSON.parse(localStorage.getItem("SupAdmin"));

  //console.log("supadmin", supAdmin);

  let initiales;
  let name;
  let prenom;

  if (supAdmin) {
    name = supAdmin?.name;
    prenom = supAdmin?.prenom;
    const nameFirstLetter = name?.charAt(0);
    const prenomFirstLetter = prenom?.charAt(0);
    initiales = nameFirstLetter + prenomFirstLetter;
  }

  useEffect(() => {
    if (logOut?.supAdmin?.message) {
      toast.success("Vous êtes déconnectés");
    }
  }, [logOut]);

  const handleLogout = async () => {
    await dispatch(logoutSupAdmin());
    localStorage.removeItem("SupAdmin");
    navigate("/logins-m");
  };

  return (
    <div className="w-full h-[10vh] bg-white  shadow-sm sticky top-0 left-0 z-40 flex items-center justify-between px-10">
      <div>
        <Link to="/supAdmin/dashboard">
          <img src={logo} alt="" className="w-[100px] h-[80px]" />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className=" relative  h-[40px] w-[40px] bg-blue-500 rounded-full flex items-center justify-center cursor-pointer  "
            >
              <span className=" text-white text-xl font-bold uppercase ">
                {initiales}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="z-50">
              <Dropdown.ItemText className="text-center">
                ESSBAI
              </Dropdown.ItemText>
              <Dropdown.ItemText className="text-center">
                Mohammed
              </Dropdown.ItemText>

              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout} className="text-center">
                Déconnexion
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SupHeader;
