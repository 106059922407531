import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Files/logo/logo.png";

const PageNotFound = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className=" text-white p-4">
        <div className="container mx-auto flex items-center justify-between">
          <Link to="/espaceClient">
            <img src={logo} alt="Logo" className="w-25 h-25" />
          </Link>
          {/* Autres éléments de navigation */}
        </div>
      </nav>
      <div className="container mx-auto py-16 flex flex-col items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-md md:flex md:items-center">
          <div>
            <h1 className="text-2xl md:text-4xl font-semibold mb-2 md:mb-4 mt-2 md:mt-0">
              Page introuvable 404
            </h1>
            <p className="text-gray-600">
              Désolé, la page que vous cherchez n'existe pas.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
