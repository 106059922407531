import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import Loader from "../../Utils/Loader";

const ResetCode = ({ type }) => {
  const [resetCode, setResetCode] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleCodeChange = (e) => {
    setResetCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (resetCode === "") {
      return alert("Merci de saisir le code que vous avez reçu par mail");
    }
  
    setLoading(true);
    let endpoint, newPasswordPath;
  
    if (type === "client") {
      endpoint = "client";
      newPasswordPath = "/new-password";
    } else if (type === "collaborateur") {
      endpoint = "collaborateur";
      newPasswordPath = "/collaborateur-new-password";
    }
  
    try {
      const response = await axios.post(
        `${server}/${endpoint}/verifyResetCode`,
        {
          resetCode,
        },
        { withCredentials: true }
      );
  
      
  
      setResetCode("");
      navigate(newPasswordPath);
    } catch (error) {
    
  
      alert("Code de réinitialisation expiré");
    }
  
    setLoading(false);
  };
  

  return (
    <div>
      {" "}
      {loading === true ? (
        <Loader />
      ) : (
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-6 mt-4 text-center">
            Code de validation
          </h2>
          <form onSubmit={handleSubmit} className="max-w-md mx-auto pt-4">
            <div className="mb-4">
              <label htmlFor="email" className="block font-medium mb-1">
                Code de validation :
              </label>
              <input
                type="number"
                id="email"
                value={resetCode}
                onChange={handleCodeChange}
                className="w-full p-2 border rounded"
                required
                placeholder="Saire le code que vous avez reçu par e-mail"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Valider le code
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ResetCode;
