import React, { useEffect, useState } from "react";
import { HiFolder } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { allClientsCollaborateur } from "../../redux/actions/collaborateurAction";
import Loader from "../../Utils/Loader";

const ListClients = ({ searchClient }) => {
  const [isLoading, setIsLoading] = useState();
  const [clients, setClients] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const res = useSelector((state) => state.collaborateur);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      handleDispatchAllClients();
    }, 1000);
  }, [searchClient]);

  useEffect(() => {
    setIsLoading(true);
    handleDispatchAllClients();
  }, []);

  const handleDispatchAllClients = async () => {
    let search = "";
    if (localStorage.getItem("searchClient") !== null) {
      search = localStorage.getItem("searchClient");
    }
    await dispatch(allClientsCollaborateur(`search=${search}`));

    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading === false) {
      //console.log("res", res);
      if (res?.clients?.success === true && res?.clients?.clients !== "") {
        setClients(res.clients.clients);
      }
      dispatch({ type: "clearErrors" });
    }
  }, [isLoading]);

  const handleOpenDossierClient = (id) => {
    navigate(`/client/${id}`);
  };

  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="overflow-y-auto">
          <div className="flex flex-wrap justify-center  800px:px-4 px-1 mt-8 ">
            {clients.length > 0 ? (
              <>
                {clients.map((client) => (
                  <div
                    key={client._id}
                    onClick={() => handleOpenDossierClient(client._id)}
                    className="bg-white rounded-lg shadow-md 800px:w-[150px] 800px:h-[150px] w-[120px] h-[120px] 800px:p-4 p-2 md:mb-4 mb-3 ml-6 flex flex-col items-center justify-center cursor-pointer transition-all hover:scale-105"
                  >
                    <HiFolder className="text-blue-600 800px:w-full 800px:h-full w-[50px] h-[50px] " />
                    <span className="text-sm text-center">{client.name}</span>
                  </div>
                ))}
              </>
            ) : (
              <h1 className="text-center">
                Vous n'avez encore ajouté aucun client
              </h1>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListClients;
