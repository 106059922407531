import React from "react";
import { AiFillFolder } from "react-icons/ai";
import { FaTasks } from "react-icons/fa";
import { RiDashboardLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const ComptableDashboard = () => {
  const navigate = useNavigate();
  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));
  const role = collaborateurStore?.user?.role;
  //console.log("role: ", role);

  const goClients = () => {
    navigate("/clients");
  };

  const goDashboard = () => {
    navigate("/dashboard/collaborateurs");
  };

  return (
    <div className=" h-[550px]  flex flex-col justify-center">
      <h1 className="font-Bold text-4xl text-center mb-12">Espace Cabinet</h1>
      <div className="w-full flex items-center justify-center  ">
        <div
          className="bg-white rounded-lg shadow-md w-[250px] h-[280px]  text-center p-4  ml-7 flex flex-col items-center  cursor-pointer transition-all hover:scale-105"
          onClick={goClients}
        >
          <AiFillFolder className="text-blue-600  " size={140} />
          <div className=" flex flex-col mt-3 mb-auto">
            <span className="font-bold text-[18px]">Clients</span>
            <span>Gérer vos dossiers clients</span>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md w-[250px] text-center h-[280px] p-4  ml-7 flex flex-col items-center justify-center cursor-pointer transition-all hover:scale-105">
          <FaTasks className="text-blue-600  " size={140} />
          <div className=" flex flex-col mt-3 mb-auto">
            <span className="font-bold text-[18px]">Suivi</span>
            <span>Suivre l'avancement de vos dossiers</span>
          </div>
        </div>
        <div
          className={`${
            role === "Manager" || role === "Admin" ? "" : "hidden "
          }bg-white rounded-lg shadow-md w-[250px] text-center h-[280px] p-4 ml-7 flex flex-col items-center justify-center cursor-pointer transition-all hover:scale-105`}
          onClick={goDashboard}
        >
          <RiDashboardLine className="text-blue-600  " size={140} />
          <div className=" flex flex-col mt-3 mb-auto">
            <span className="font-bold text-[18px]">Admininistration</span>
            <span>Espace Administration</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComptableDashboard;
