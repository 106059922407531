import axios from "axios";
import { server } from "../../server";

// in params : id and idFolder and files req, body : created by

export const uploadFiles = (id, idFolder, newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "UploadFileRequest",
    });
    //console.log("req upload file");

    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const { data } = await axios.post(
      `${server}/client/uploadFile/${id}/${idFolder}`,

      newForm,
      config,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "UploadFileSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "UploadFileFail",
      payload:
        error.response?.data?.message || error.response?.data?.errors[0].msg,
    });
  }
};

export const renameFile =
  (id, idFolderFile, name, modificatedBy, modificatedAt) =>
  async (dispatch) => {
    //console.log(name);
    try {
      dispatch({
        type: "RenameFileRequest",
      });
      //console.log("req rename file");

      const { data } = await axios.post(
        `${server}/client/renameFile/${id}/${idFolderFile}`,

        { name, modificatedBy, modificatedAt },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "RenameFileSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "RenameFileFail",
        payload:
          error.response?.data?.message || error.response?.data?.errors[0].msg,
      });
    }
  };

export const changeCatFile = (id, idFolderFile, newCat) => async (dispatch) => {
  try {
    dispatch({
      type: "ChangeCatFileRequest",
    });

    const { data } = await axios.post(
      `${server}/client/change-cat-file/${id}/${idFolderFile}`,
      { newCat },

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "ChangeCatFileSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "ChangeCatFileFail",
      payload:
        error.response?.data?.message || error.response?.data?.errors[0].msg,
    });
  }
};

export const moveFileAction =
  (id, idFolderFile, idTo, modificatedBy, modificatedAt) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "MoveFileRequest",
      });
      //console.log("req move file");

      const { data } = await axios.post(
        `${server}/client/fileMove/${id}/${idFolderFile}`,

        { idTo, modificatedBy, modificatedAt },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "MoveFileSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "MoveFileFail",
        payload:
          error.response?.data?.message || error.response?.data?.errors[0].msg,
      });
    }
  };

export const deleteFileAction = (id, idFile) => async (dispatch) => {
  try {
    dispatch({
      type: "DeleteFileRequest",
    });
    //console.log("req delete file");

    const { data } = await axios.post(
      `${server}/client/deleteFile/${id}/${idFile}`,
      {},

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "DeleteFileSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "DeleteFileFail",
      payload:
        error?.response?.data?.message || error?.response?.data?.errors[0]?.msg,
    });
  }
};
