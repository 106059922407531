import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Utils/Loader";

const ProtectedRoute = ({ allowed, children }) => {
  const data = useSelector((state) => state.collaborateur);
  const alreadyConnected = JSON.parse(localStorage.getItem("collaborateur"));

  // //console.log("reducer_collaborateur", data);
  // //console.log("reducer_collaborateur2", data?.isAuthenticated);
  // //console.log("reducer_collaborateur3", data?.collaborateur?.user?.role);
  // //console.log("allowed", allowed);
  // //console.log("alreadyConnected", alreadyConnected);

  if (alreadyConnected === null) {
    if (data?.isAuthenticated === undefined) {
      return <Loader />;
    } else if (data?.isAuthenticated === false) {
      return <Navigate to="/login-pro" replace />;
    } else if (
      data?.isAuthenticated &&
      !allowed.includes(data?.collaborateur?.user?.role)
    ) {
      toast.warning("Vous ne pouvez pas accéder à cet URL");
      return <Navigate to="/dashboard" replace />;
    } else if (
      data?.isAuthenticated === true &&
      data?.collaborateur?.user?.active === false
    ) {
      toast.warning("Votre compte a été désactivé par un administrateur !!");
      localStorage.removeItem("collaborateur");
      return <Navigate to="/login-pro" replace />;
    }
  } else if (alreadyConnected !== null) {
    if (alreadyConnected?.user?.active === false) {
      toast.warning("Votre compte a été désactivé par un administrateur !!");
      return <Navigate to="/login-pro" replace />;
    } else if (!allowed.includes(alreadyConnected?.user?.role)) {
      toast.warning("Vous ne pouvez pas accéder à cet URL");
      return <Navigate to="/dashboard" replace />;
    }
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
