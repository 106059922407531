import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewPassword from "../../Components/UserComponents/NewPassword.jsx";

const NewPasswordPage = () => {
  const alreadyConnected = JSON.parse(localStorage.getItem("clt"));
  const navigate = useNavigate();
  useEffect(() => {
    if (alreadyConnected !== null) {
      navigate("/espaceClient");
    }
  }, []);
  return <div><NewPassword type={'client'}/></div>;
};

export default NewPasswordPage;
