import { configureStore } from "@reduxjs/toolkit";
import { collaborateurReducer } from "./reducers/collaborateur";
import { filesReducer } from "./reducers/files";
import { foldersReducer } from "./reducers/folders";
import { clientReducer } from "./reducers/client";
import { supAdminReducer } from "./reducers/supAdmin";

const Store = configureStore({
  reducer: {
    collaborateur: collaborateurReducer,
    files: filesReducer,
    folders: foldersReducer,
    client: clientReducer,
    supAdmin: supAdminReducer,
  },
});

export default Store;
