import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../Components/UserComponents/ForgotPassword.jsx";

const ForgotPasswordPage = () => {
  const alreadyConnected = JSON.parse(localStorage.getItem("clt"));
  const navigate = useNavigate();
  useEffect(() => {
    if (alreadyConnected !== null) {
      navigate("/espaceClient");
    }
  }, []);
  return (
    <div>
      <ForgotPassword type={"client"} />
    </div>
  );
};

export default ForgotPasswordPage;
