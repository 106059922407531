import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { updateCollaborateurPassword } from "../../redux/actions/collaborateurAction";
import { updateClientPassword } from "../../redux/actions/clientAction";

const ChangePassword = ({ userType }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.collaborateur);
  const dataClient = useSelector((state) => state.client);

  //console.log(data);
  //console.log("dataClient", dataClient);

  const validatePassword = (value) => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === "" || confirmPassword === "" || oldPassword === "") {
      return alert("Merci de remplir toutes les informations demandées");
    }
    if (!validatePassword(newPassword)) {
      alert(
        "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule et un chiffre."
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error(
        "Le nouveau mots de passe ne correspond pas au mots de passe confirmé"
      );
      return;
    }

    setLoading(true);
    if (userType === "pro") {
      await dispatch(
        updateCollaborateurPassword(oldPassword, newPassword, confirmPassword)
      );
    } else if (userType === "client") {
      await dispatch(
        updateClientPassword(oldPassword, newPassword, confirmPassword)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    // Vérifier si le chargement est terminé avant de procéder
    if (!loading) {
      if (userType === "pro") {
        //console.log(data);
        if (data?.updatedPasswordCollaborateur) {
          // Gérer la mise à jour du mot de passe pour les collaborateurs
          const successMessage = data?.updatedPasswordCollaborateur?.message;
          handlePasswordUpdateSuccess(successMessage, "/login-pro");
        } else if (data?.error !== "") {
          // Gérer les erreurs pour les collaborateurs
          const errorMessage = data?.error;
          handlePasswordUpdateError(errorMessage, "/login-pro");
        }
      } else if (userType === "client") {
        if (dataClient?.updatedClientPassword) {
          // Gérer la mise à jour du mot de passe pour les clients
          const successMessage = dataClient?.updatedClientPassword?.message;
          handlePasswordUpdateSuccess(successMessage, "/login");
        } else if (dataClient?.error !== "") {
          // Gérer les erreurs pour les clients
          const errorMessage = dataClient?.error;
          handlePasswordUpdateError(errorMessage, "/login");
        }
      }

      // Marquer le chargement comme terminé une fois que tout a été traité
      setLoading(false);
    }
  }, [loading]);

  // Fonction pour gérer les succès de mise à jour de mot de passe
  const handlePasswordUpdateSuccess = (message, navigateTo) => {
    toast.success(message);
    clearPasswordFields();
    setTimeout(() => {
      navigate(navigateTo);
    }, 1500);
  };

  // Fonction pour gérer les erreurs de mise à jour de mot de passe
  const handlePasswordUpdateError = (errorMessage, navigateTo) => {
    toast.error(errorMessage);
    navigate(navigateTo);
    dispatch({ type: "clearErrors" });
  };

  // Fonction pour réinitialiser les champs de mot de passe
  const clearPasswordFields = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Modifier votre mots de passe
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="identifiant"
                className="block text-sm font-medium text-gray-700"
              >
                Mots de passe
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="password"
                  value={oldPassword}
                  placeholder="Mots de passe actuel"
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Nouveau mots de passe
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  placeholder="Nouveau mots de passe"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  title="Veuillez remplir ce champ"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirmation mots de passe
              </label>
              <div className="mt-1 relative">
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirmer le nouveau mots de passe"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  title="Veuillez remplir ce champ"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Confirmer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
