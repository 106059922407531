import React from "react";
import ResetCode from "../../Components/UserComponents/ResetCode.jsx";

const CollaborateurResetCodePage = () => {
  return (
    <div>
      <ResetCode type={"collaborateur"} />
    </div>
  );
};

export default CollaborateurResetCodePage;
