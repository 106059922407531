import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CabinetHeader from "../../Components/ComptableComponents/CabinetHeader";
import ComptabledSideBar from "../../Components/ComptableComponents/ComptabledSideBar.jsx";
import AllClients from "../../Components/ComptableComponents/AllClients.jsx";
import styles from "../../Styles/Styles";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Arborescences from "../../Components/SupAdminComponents/Arborescences";
import { loadArboPerCabinet } from "../../redux/actions/supAdmin";
import { useEffect } from "react";
import Loader from "../../Utils/Loader";

const AdminListArboPage = () => {
  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));
  const cabId = collaborateurStore?.user?.cabinet;
  //console.log(cabId);

  const arbos = useSelector((state) => state.supAdmin);
  const dispatch = useDispatch();

  const getArbos = async (cabId) => {
    await dispatch(loadArboPerCabinet(cabId));
  };

  useEffect(() => {
    getArbos(cabId);
  }, []);

  //console.log(arbos);

  return (
    <div>
      <CabinetHeader />
      {arbos?.loadingArbos === true ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-end px-3 mb-4 "></div>
          <div className="flex ">
            <div className="w-[80px]  800px:w-[280px] mr-[1rem] 800px:mr-[3rem]">
              <ComptabledSideBar active={3} />
            </div>
            <div className="flex flex-wrap ">
              {arbos.arbos?.data?.length > 0 ? (
                arbos.arbos?.data?.map((arbo) => (
                  <Arborescences
                    key={arbo._id}
                    data={arbo}
                    pasDelete={"pasDelete"}
                  />
                ))
              ) : (
                <h1 className="text-center">
                  {" "}
                  Aucune arborescence n'a été ajoutée
                </h1>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminListArboPage;
