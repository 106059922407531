import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
};

export const filesReducer = createReducer(initialState, {
  UploadFileRequest: (state) => {
    state.loading = true;
  },
  UploadFileSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.files = action.payload;
  },
  UploadFileFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  RenameFileRequest: (state) => {
    state.loading = true;
  },
  RenameFileSuccess: (state, action) => {
    state.loading = false;
    state.file = action.payload;
  },
  RenameFileFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  MoveFileRequest: (state) => {
    state.loading = true;
  },
  MoveFileSuccess: (state, action) => {
    state.loading = false;
    state.movedFile = action.payload;
  },
  MoveFileFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  DeleteFileRequest: (state) => {
    state.loading = true;
  },
  DeleteFileSuccess: (state, action) => {
    state.loading = false;
    state.deletedFile = action.payload;
  },
  DeleteFileFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

 

  ChangeCatFileRequest: (state) => {
    state.loading = true;
  },
  ChangeCatFileSuccess: (state, action) => {
    state.loading = false;
    state.fileChangeCat = action.payload;
  },
  ChangeCatFileFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
