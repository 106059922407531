import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import SupHeader from "../../Components/SupAdminComponents/SupHeader.jsx";
import ClientPro from "../../Components/SupAdminComponents/ClientPro.jsx";
import { getAllComptePro } from "../../redux/actions/supAdmin.js";
import Loader from "../../Utils/Loader";

const SupAdminDashboardPage = () => {
  const [getUpdated, setGetUpdated] = useState();

  const dispatch = useDispatch();
  const cabinets = useSelector((state) => state.supAdmin);
  //console.log(cabinets?.updateComptePro?.success);

  const getAllCabinets = async () => {
    await dispatch(getAllComptePro());
  };

  useEffect(() => {
    getAllCabinets();
  }, []);

  useEffect(() => {
    if (getUpdated === true) getAllCabinets();
  }, [getUpdated]);

  return (
    <div>
      {cabinets.loadingCabinets === true ? (
        <Loader />
      ) : (
        <>
          <SupHeader />
          <div className="flex justify-end px-3 ">
            <Link to="/supAdmin/create-compte">
              <div className="w-[180px] bg-blue-600 h-[50px] mr-6 mt-3 flex items-center justify-center rounded-xl cursor-pointer hover:bg-blue-700">
                <h1 className="text-[#fff] flex items-center">
                  {" "}
                  Ajouter un client pro <IoIosArrowForward className="ml-" />
                </h1>
              </div>
            </Link>
          </div>
          <div className="px-4 mt-5 w-full space-y-4 mb-4">
            {cabinets?.comptePro?.data?.length > 0 ? (
              cabinets?.comptePro?.data?.map((comptePro) => (
                <ClientPro
                  key={comptePro._id}
                  data={comptePro}
                  setGetUpdated={setGetUpdated}
                />
              ))
            ) : (
              <h1 className="text-center"> Aucun cabinet n'a été ajouté</h1>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SupAdminDashboardPage;
