import * as React from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEye, AiFillEdit } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteOneCollaborateur,
  geCollaborateursPerCabinet,
  updateeOneCollaborateur,
} from "../../redux/actions/collaborateurAction";
import Loader from "../../Utils/Loader";

const AllCollaborateurs = () => {
  const [collaborateurs, setCollaborateurs] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [specificCollaborateur, setSpecificCollaborateur] = useState("");
  const [modalType, setModalType] = useState({});
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(true);
  const [isUpdating, setIsUpdating] = useState(true);
  const [collaborateurState, setCollaborateurState] = useState("");
  const [collaborateurName, setCollaborateurName] = useState("");
  const [collaborateurPrenom, setCollaborateurPrenom] = useState("");
  const [collaborateurEmail, setCollaborateurEmail] = useState("");
  const [collaborateurRole, setCollaborateurRole] = useState("");
  const [collaborateurPoste, setCollaborateurPoste] = useState("");
  const [collaborateurService, setCollaborateurService] = useState("");

  const [showModalCollaborateurState, setShowModalCollaborateurState] =
    useState(false);
  const [showModalEditCollaborateurInfo, setShowModalEditCollaborateurInfo] =
    useState(false);

  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));
  const cabId = collaborateurStore?.user.cabinet;
  const dispatch = useDispatch();

  const allCollaborateurs = useSelector((state) => state.collaborateur);

  //console.log("allCollaborateurs", allCollaborateurs?.allCollaborateurs);

  const handleDispatchGetAllCollaborateurs = async () => {
    await dispatch(geCollaborateursPerCabinet(cabId));
  };

  useEffect(() => {
    if (allCollaborateurs?.allCollaborateursCabinet?.data)
      setCollaborateurs(allCollaborateurs?.allCollaborateursCabinet?.data);
  }, [allCollaborateurs]);

  useEffect(() => {
    handleDispatchGetAllCollaborateurs();
  }, [dispatch]);

  const handleDelete = async (params) => {
    //console.log("params", params.row);
    await setSpecificCollaborateur(params.row);
    await setModalType({ type: "suppression", confirmation: "supprimer" });

    setShowConfirmModal(true);
  };
  let state;
  const handleConfirmAction = async () => {
    const id = specificCollaborateur?.id;
    if (id === undefined || id === null || id === "") {
      return toast.warn("Merci de cliquer sur un collaborateur");
    }
    if (modalType.type === "suppression") {
      //console.log("specificCollaborateur", specificCollaborateur);
      setShowLoadingModal(true);
      setShowConfirmModal(false);
      setIsDeleting(true);
      await dispatch(deleteOneCollaborateur(id));

      setShowLoadingModal(false);
      setIsDeleting(false);

      handleDispatchGetAllCollaborateurs();
      // console.log(
      //   "deletedCollaborateur",
      //   allCollaborateurs?.deletedCollaborateur?.data
      // );
    }
    if (modalType.type === "Modification de l'état du compte") {
      //console.log("specificCollaborateur", specificCollaborateur);
      state = collaborateurState === "Active" ? true : false;
      setShowLoadingModal(true);
      setShowConfirmModal(false);
      setShowModalCollaborateurState(false);
      setIsUpdating(true);
      await dispatch(
        updateeOneCollaborateur(id, {
          active: state,
        })
      );
      setCollaborateurState("");

      setShowLoadingModal(false);
      setIsUpdating(false);

      handleDispatchGetAllCollaborateurs();

      // console.log(
      //   "deletedCollaborateur",
      //   allCollaborateurs?.deletedCollaborateur?.data
      // );
    }
    if (modalType.type === "Modification des informations du collaborateur") {
      setShowLoadingModal(true);
      setShowConfirmModal(false);
      setShowModalEditCollaborateurInfo(false);
      setIsUpdating(true);
      await dispatch(
        updateeOneCollaborateur(id, {
          name: collaborateurName,
          prenom: collaborateurPrenom,
          email: collaborateurEmail,
          service: collaborateurService,
          poste: collaborateurPoste,
          role: collaborateurRole,
        })
      );
      setShowLoadingModal(false);
      setIsUpdating(false);

      handleDispatchGetAllCollaborateurs();
    }
  };

  useEffect(() => {
    if (!isDeleting) {
      if (
        allCollaborateurs?.deletedCollaborateur?.success === true &&
        allCollaborateurs?.deletedCollaborateur?.message ===
          "Collaborateur supprimé avec succès"
      ) {
        toast.success(
          `Collaborateur ${allCollaborateurs.deletedCollaborateur.data.name} a été supprimé avec succès`
        );
      } else {
        dispatch({ type: "clearErrors" });
      }
    }
  }, [isDeleting]);

  useEffect(() => {
    if (!isUpdating) {
      if (
        allCollaborateurs?.updatedCollaborateur?.success === true &&
        allCollaborateurs?.updatedCollaborateur?.data?.name
      ) {
        toast.success(
          `Collaborateur ${allCollaborateurs.updatedCollaborateur.data.name} a été actualisé avec succès`
        );
      } else {
        dispatch({ type: "clearErrors" });
      }
    }
  }, [isUpdating]);

  // activer / désactiver
  const updateState = (params) => {
    setSpecificCollaborateur(params.row);
    //console.log("paramsRow", params.row);
    //console.log("setSpecificCollaborateur", params.row);
    // //console.log("state", state);
    setShowModalCollaborateurState(true);
  };

  const closeModalCollaborateurState = () => {
    setShowModalCollaborateurState(false);
    setCollaborateurState("");
  };

  const handleUpdate = async () => {
    //console.log("collaborateurState", collaborateurState);
    //console.log("specificCollaborateur", specificCollaborateur.Etat);
    if (collaborateurState !== "Active" && collaborateurState !== "Inactive") {
      return toast.warn("Merci de choisir une option");
    }

    const collaborateurStateBoolean = collaborateurState === "Active";

    if (collaborateurStateBoolean === specificCollaborateur.Etat) {
      const message = collaborateurStateBoolean ? "activé" : "désactivé";
      return toast.warn(`Le compte est déjà ${message}`);
    }
    setShowConfirmModal(true);
    const confirmation =
      collaborateurState === "Active"
        ? "activer"
        : collaborateurState === "Inactive"
        ? "désactiver"
        : "";
    await setModalType({
      type: "Modification de l'état du compte",
      confirmation: confirmation,
    });
  };

  // update collaborator info
  const updateCollaborateur = (params) => {
    setSpecificCollaborateur(params.row);
    //console.log("data", params.row);
    //console.log("updateCollaborateur", params.row);
    setCollaborateurName(params.row.Nom);
    setCollaborateurPrenom(params.row.Prénom);
    setCollaborateurEmail(params.row.Email);
    setCollaborateurRole(params.row.Rôle);
    setCollaborateurPoste(params.row.Poste);
    setCollaborateurService(params.row.Service);
    setShowModalEditCollaborateurInfo(true);
  };

  const closeModalEditCollaborateurInfo = () => {
    setShowModalEditCollaborateurInfo(false);
  };

  const handleUpdateCollaborateurInfo = async () => {
    //console.log(collaborateurName);

    await setModalType({
      type: "Modification des informations du collaborateur",
      confirmation: "modifier les informations du",
    });
    setShowConfirmModal(true);
  };

  const columns = [
    {
      field: "Nom",
      headerName: "Nom",
      minWidth: 40,
    },
    {
      field: "Prénom",
      headerName: "Prénom",
      minWidth: 40,
    },
    {
      field: "Email",
      headerName: "Email",
      minWidth: 100,
    },
    {
      field: "Service",
      headerName: "Service",
      minWidth: 40,
    },
    {
      field: "Poste",
      headerName: "Poste",
      minWidth: 40,
    },

    {
      field: "Rôle",
      headerName: "Rôle",
      minWidth: 40,
    },
    {
      field: "Etat",
      headerName: "Etat",
      minWidth: 40,

      renderCell: (params) => {
        const { Etat } = params.row;

        if (Etat === true) {
          return <FaCheckCircle size={20} color="green" />;
        } else if (Etat === false) {
          return <FaTimesCircle size={20} color="red" />;
        }

        return "État inconnu";
      },
    },
    {
      field: "Actualiser",

      minWidth: 40,
      headerName: "Voir/Modifier",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => updateCollaborateur(params)}>
              <AiOutlineEye /> / <AiFillEdit />
            </Button>
          </>
        );
      },
    },
    {
      field: "Désactiver",

      minWidth: 40,
      headerName: "Désactiver",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        const { Etat } = params.row;

        if (Etat === true) {
          return (
            <Button onClick={() => updateState(params)}>Désactiver</Button>
          );
        } else if (Etat === false) {
          return <Button onClick={() => updateState(params)}>Activer</Button>;
        }

        return "État inconnu";
      },
    },

    {
      field: "Supprimer",

      minWidth: 40,
      headerName: "Supprimer",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  collaborateurs &&
    collaborateurs?.forEach((item) => {
      row.push({
        id: item._id,
        Nom: item.name,
        Prénom: item.prenom,
        Email: item.email,
        Service: item.service,
        Poste: item.poste,
        Rôle: item.role,
        Etat: item.active,
      });
    });

  return (
    <>
      <div className="w-full mx-8 pt-1 mt-10 bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={8}
          disableSelectionOnClick
          autoHeight
        />
      </div>
      <Modal
        show={showConfirmModal}
        dialogClassName="max-w-[700px] top-1/4"
        className=" px-2 bg-transparent "
        onHide={() => setShowConfirmModal(false)}
      >
        {" "}
        {/* Ajouter la classe "modal-lg" pour le rendre plus large */}
        <Modal.Header>
          <Modal.Title className="text-sm font-bold">
            Confirmation : {modalType.type} {specificCollaborateur?.Nom}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowConfirmModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="mb-2 text-sm">
            {" "}
            Êtes vous sur de vouloir {modalType.confirmation}{" "}
            <span className="font-bold">{specificCollaborateur?.Nom}</span> ?{" "}
            <br />
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setShowConfirmModal(false)}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={handleConfirmAction}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalCollaborateurState}
        onHide={closeModalCollaborateurState}
      >
        <Modal.Header>
          <Modal.Title className="text-sm">
            Modifier l'état d'activation du compte {specificCollaborateur?.Nom}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModalCollaborateurState}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formResponsableName">
            <select
              name="collaborateurState"
              value={collaborateurState}
              onChange={(event) => setCollaborateurState(event.target.value)}
              className="block ml-2 w-70 cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="">Etat du compte</option>
              <option value="Active">Activer</option>
              <option value="Inactive">Désactiver</option>
            </select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={closeModalCollaborateurState}
          >
            Annuler
          </Button>
          <Button className="bg-blue-500" onClick={handleUpdate}>
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalEditCollaborateurInfo}
        onHide={closeModalEditCollaborateurInfo}
      >
        <Modal.Header>
          <Modal.Title className="text-sm">
            Modifier les informations du collaborateur
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModalEditCollaborateurInfo}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formSocieteName">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              value={collaborateurName}
              onChange={(e) => setCollaborateurName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              value={collaborateurPrenom}
              onChange={(e) => setCollaborateurPrenom(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={collaborateurEmail}
              onChange={(e) => setCollaborateurEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formDirigeantName">
            <Form.Label>Rôle</Form.Label>
            <Form.Control
              type="text"
              value={collaborateurRole}
              onChange={(e) => setCollaborateurRole(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPortableNumber">
            <Form.Label>Poste</Form.Label>
            <Form.Control
              type="text"
              value={collaborateurPoste}
              onChange={(e) => setCollaborateurPoste(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formAdresseSociete">
            <Form.Label>Service</Form.Label>
            <Form.Control
              type="text"
              value={collaborateurService}
              onChange={(e) => setCollaborateurService(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={closeModalEditCollaborateurInfo}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500"
            onClick={handleUpdateCollaborateurInfo}
          >
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </>
  );
};

export default AllCollaborateurs;
