import { React, useState } from "react";
import { FaArrowLeft, FaCopy, FaCheck } from "react-icons/fa";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { getAllCollaborateurs } from "../../redux/actions/collaborateurAction";
import { addMenuClient } from "../../redux/actions/supAdmin";
import Loader from "../../Utils/Loader";

const SupAdminAddMenuPage = () => {
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([""]);
  const [inputList, setInputList] = useState([{ id: 1 }]);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [page, setPage] = useState(1);

  const { cabId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.supAdmin);
  //console.log(menu);
  const validationAddCategory = (titre) => {
    if (categories.some((value) => value.trim() === "")) {
      alert(`Veuillez remplir tous les noms des catégories avant ${titre}.`);
      return false;
    }
    const repeatedNames = categories.filter(
      (value, index) => categories.indexOf(value) !== index
    );
    if (repeatedNames.length > 0) {
      alert(
        `Le nom du catégorie "${repeatedNames[0]}" est répété plusieurs fois.`
      );
      return false;
    }
    return true;
  };

  const handleAddInput = () => {
    if (!validationAddCategory("d'ajouter une nouvelle catégorie")) {
      return;
    }

    setInputList([...inputList, { id: inputList.length + 1 }]);
    setCategories([...categories, ""]);
  };

  // Gestionnaire d'événement pour supprimer un input
  const handleRemoveInput = (id) => {
    const newInputList = inputList.filter((item) => item.id !== id);
    setInputList(newInputList);

    const newValues = [...categories];
    newValues.splice(id - 1, 1);
    setCategories(newValues);
  };

  // Gestionnaire d'événement pour mettre à jour la valeur d'un champ d'entrée
  const handleChange = (id, event) => {
    const newValues = [...categories];
    newValues[id - 1] = event.target.value;
    setCategories(newValues);
  };
  const isNextButtonActive = () => {
    if (page === 1) {
      return name !== "";
    } else if (page === 2) {
      return categories[0] !== "";
    }
    return false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === "") {
      return alert("Merci de donner un nom à cette arborescence");
    }

    if (!validationAddCategory(" de valider")) {
      return;
    }

    setShowLoadingModal(true);
    await dispatch(addMenuClient(cabId, { name, categories }));
    setShowLoadingModal(false);
  };

  useEffect(() => {
    //console.log(menu?.addMenuloading);
    if (menu?.addMenuloading === false) {
      if (
        menu?.addMenu &&
        menu?.addMenu.success === true &&
        menu?.addMenu.message
      ) {
        //console.log(menu?.addMenu.message);
        setName("");
        toast.success(menu?.addMenu.message);
        setTimeout(navigate(`/supAdmin/${cabId}/menuClient`), 2000);
        dispatch({ type: "AddMenuRequest" });
      } else if (menu?.error) {
        //console.log(menu.error);
        toast.warn(menu.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [menu?.addMenuloading]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md mb-4">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Nouveau menu client
        </h2>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center">
          <div
            className={`${
              page === 1 || page === 2 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            1
          </div>
          <div className="800px:w-10 w-5 h-1 bg-[#90a4e6] "></div>
          <div
            className={`${
              page > 1 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            2
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="bg-white pb-10 pt-4 px-4 shadow sm:rounded-lg sm:px-10">
          {page === 1 && (
            <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
              Titre du Menu
            </h1>
          )}
          {page === 2 && (
            <div className="mb-6">
              <h1 className="mb-1 text-center text-2xl font-bold text-gray-900">
                <FaArrowLeft
                  onClick={() => setPage(page - 1)}
                  className="cursor-pointer"
                />
                La liste des catégories du menu
              </h1>
              <p className="text-sm text-gray-600 text-center italic">
                Ajouter au moins une catégorie.{" "}
                <span className="text-red-500">*</span> <br /> Vous pouvez
                toujours modifier les catégories du menu.{" "}
              </p>
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSubmit}>
            {page === 1 && (
              <div className="800px:mr-2">
                <label
                  htmlFor="nom"
                  className="block text-sm font-medium text-gray-700"
                >
                  Donner un nom à ce menu
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
            {page === 2 && (
              <div className="container mx-auto text-center mt-8">
                {inputList.map((input, index) => (
                  <div
                    key={input.id}
                    className="flex flex-wrap items-center mb-4"
                  >
                    <label
                      htmlFor={`input-${input.id}`}
                      className="mr-2 block text-sm font-medium text-gray-700"
                    >
                      Nom de la catégorie {input.id}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        id={`input-${input.id}`}
                        value={categories[input.id - 1]}
                        onChange={(event) => handleChange(input.id, event)}
                        className="appearance-none block w-[250px] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    {index !== 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveInput(input.id)}
                        className="ml-2 px-2 py-1 bg-gray-800 text-white rounded-md hover:bg-gray-500"
                      >
                        -
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddInput}
                  className="px-4 py-2 bg-gray-100 text-blue-500 border-2 border-blue-500 rounded-md hover:scale-105"
                >
                  + Ajouter une catégorie
                </button>
              </div>
            )}
            <div>
              {page !== 2 && (
                <div
                  className={`${
                    page === 1 ? "flex justify-end" : "flex justify-between"
                  }`}
                >
                  <button
                    onClick={() => setPage(page - 1)}
                    className={`${
                      page > 1
                        ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        : "hidden"
                    }`}
                  >
                    Retour
                  </button>
                  {page === 1 && (
                    <button
                      onClick={() => setPage(page + 1)}
                      className={`bg-blue-${
                        isNextButtonActive() ? "500 hover:bg-blue-700" : "300"
                      } text-white font-bold py-2 px-4 rounded`}
                      disabled={!isNextButtonActive()} // Désactiver le bouton si le bouton n'est pas actif
                    >
                      Suivant
                    </button>
                  )}
                </div>
              )}
              <div className="flex items-center justify-center">
                {page === 2 && (
                  <button
                    type="submit"
                    className={`${
                      isNextButtonActive()
                        ? "bg-blue-600 hover:bg-blue-700"
                        : "bg-blue-300"
                    } text-white font-bold py-2 px-4 rounded w-80 `}
                    disabled={!isNextButtonActive()}
                  >
                    Valider
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </div>
  );
};

export default SupAdminAddMenuPage;
