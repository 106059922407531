import axios from "axios";
import { server } from "../../server";

// login Collaborateur
export const loginCollaborateur =
  (identifiant, password) => async (dispatch) => {
    try {
      dispatch({
        type: "LoginCollaborateurRequest",
      });
      const { data } = await axios.post(
        `${server}/collaborateur/login-collaborateur`,
        {
          identifiant,
          password,
        },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "LoginCollaborateurSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "LoginCollaborateurFail",
        payload:
          error?.response?.data?.message ||
          error?.response?.data?.errors[0]?.msg,
      });
    }
  };

// logout Collaborateur
export const logoutCollaborateur = () => async (dispatch) => {
  try {
    dispatch({
      type: "LogoutCollaborateurRequest",
    });
    const { data } = await axios.get(
      `${server}/collaborateur/logout-collaborateur`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "LogoutCollaborateurSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "LogoutCollaborateurFail",
      payload: error.response?.data.message,
    });
  }
};

// Get All collaborateurs
export const getAllCollaborateurs = () => async (dispatch) => {
  try {
    //console.log("req from get all collaborators");
    dispatch({
      type: "GetCollaborateursRequest",
    });
    const { data } = await axios.get(
      `${server}/collaborateur/get-all-collaborateurs`,

      {
        withCredentials: true,
      }
    );
    //console.log("req from get all collaborators", data);

    dispatch({
      type: "GetCollaborateursSuccess",
      payload: data,
    });
    // //console.log(data);
  } catch (error) {
    dispatch({
      type: "GetCollaborateursFail",
      payload: error.response?.data.message,
    });
  }
};

// Get All collaborateurs by cabinet
export const getAllCollaborateursByCab = (cabId) => async (dispatch) => {
  try {
    //console.log("req from get all collaborators");
    dispatch({
      type: "GetCollaborateursRequest",
    });
    const { data } = await axios.get(
      `${server}/collaborateur/get-collaborateurs-cabinet/${cabId}`,

      {
        withCredentials: true,
      }
    );
    //console.log("req from get all collaborators", data);

    dispatch({
      type: "GetCollaborateursSuccess",
      payload: data,
    });
    // //console.log(data);
  } catch (error) {
    dispatch({
      type: "GetCollaborateursFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Get  collaborateurs per cabinet
export const geCollaborateursPerCabinet = (cabId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetCollaborateursCabinetRequest",
    });
    const { data } = await axios.get(
      `${server}/collaborateur/get-collaborateurs-cabinet/${cabId}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "GetCollaborateursCabinetSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GetCollaborateurCabinetsFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Delete a specific collaborateur
export const deleteOneCollaborateur = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "DeleteCollaborateurRequest",
    });
    const { data } = await axios.delete(
      `${server}/collaborateur/delete-collaborateur/${id}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "DeleteCollaborateurSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "DeleteCollaborateurFail",
      payload:
        error?.response?.data?.message || error?.response?.data?.errors[0]?.msg,
    });
  }
};

// Update a specific collaborateur
export const updateeOneCollaborateur =
  (
    id,
    {
      name: collaborateurName,
      prenom: collaborateurPrenom,
      email: collaborateurEmail,
      service: collaborateurService,
      poste: collaborateurPoste,
      role: collaborateurRole,
      active: state,
    }
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UpdateCollaborateurRequest",
      });

      const { data } = await axios.put(
        `${server}/collaborateur/update-collaborateur/${id}`,
        {
          name: collaborateurName,
          prenom: collaborateurPrenom,
          email: collaborateurEmail,
          service: collaborateurService,
          poste: collaborateurPoste,
          role: collaborateurRole,
          active: state,
        },

        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "UpdateCollaborateurSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "UpdateCollaborateurFail",
        payload:
          error?.response?.data?.message ||
          error?.response?.data?.errors[0]?.msg,
      });
    }
  };

// Get user by Collaborateur

export const loadDossierClientByCollaborateur = (id) => async (dispatch) => {
  try {
    //console.log("hello from action");
    dispatch({
      type: "LoadDossierClientByCollaborateurRequest",
    });
    const { data } = await axios.get(
      `${server}/collaborateur/get-dossier-client/${id}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "LoadDossierClientByCollaborateurSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "LoadDossierClientByCollaborateurFail",
      payload:
        error?.response?.data?.message || error?.response?.data?.errors[0]?.msg,
    });
  }
};

// Update information client by Collaborateur

export const updateDossierClientByCollaborateur =
  (
    id,
    {
      name,
      societePhone,
      email,
      dirigeantName,
      phoneNumber,
      adressePro,
      responsableDossierName,
    }
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UpdateDossierClientByCollaborateurRequest",
      });

      const { data } = await axios.post(
        `${server}/client/update-dossier-client/${id}`,
        {
          name,
          societePhone,
          email,
          dirigeantName,
          phoneNumber,
          adressePro,
          responsableDossierName,
        },

        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "UpdateDossierClientByCollaborateurSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "UpdateDossierClientByCollaborateurFail",
        payload:
          error?.response?.data?.message ||
          error?.response?.data?.errors[0]?.msg,
      });
    }
  };

// Get all users(clients) by Collaborateur
export const allClientsCollaborateur = (search) => async (dispatch) => {
  try {
    //console.log("server request get all clients");
    //console.log("server request get all clients", search);
    dispatch({
      type: "AllClientsCollaborateurRequest",
    });

    const { data } = await axios.get(
      `${server}/collaborateur/get-all-clients?${search}`,

      {
        withCredentials: true,
      }
    );
    // //console.log(data);

    dispatch({
      type: "AllClientsCollaborateurSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "AllClientsCollaborateurFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Get all users(clients) by Collaborateur per Cabinet

export const allClientsCabinetCollaborateur = (cabId) => async (dispatch) => {
  try {
    //console.log("server request get all clients per cabId");
    dispatch({
      type: "AllClientsCabinetCollaborateurRequest",
    });

    const { data } = await axios.get(
      `${server}/collaborateur/get-clients-cabinet/${cabId}`,

      {
        withCredentials: true,
      }
    );
    // //console.log(data);

    dispatch({
      type: "AllClientsCabinetCollaborateurSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "AllClientsCabinetCollaborateurFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Delete a specific client
export const deleteOneClientAndDossierClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "DeleteClientRequest",
    });

    const { data } = await axios.delete(
      `${server}/collaborateur/delete-client/${id}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "DeleteClientSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "DeleteClientFail",
      payload:
        error.response?.data?.message || error.response?.data?.errors[0].msg,
    });
  }
};

// Update a  client state
export const updateClientState = (id, active) => async (dispatch) => {
  try {
    dispatch({
      type: "UpdateClientRequest",
    });

    const { data } = await axios.put(
      `${server}/collaborateur/update-client-state/${id}`,
      { active },

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "UpdateClientSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "UpdateClientFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Update collaborateur password
export const updateCollaborateurPassword =
  (oldPassword, newPassword, confirmPassword) => async (dispatch) => {
    try {
      dispatch({
        type: "UpdateCollaborateurPasswordRequest",
      });

      const { data } = await axios.put(
        `${server}/collaborateur/update-collaborateur-password`,
        { oldPassword, newPassword, confirmPassword },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "UpdateCollaborateurPasswordSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "UpdateCollaborateurPasswordFail",
        payload: error?.response?.data?.message,
      });
    }
  };
