import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CabinetHeader from "../../Components/ComptableComponents/CabinetHeader";
import ComptabledSideBar from "../../Components/ComptableComponents/ComptabledSideBar.jsx";
import AllClients from "../../Components/ComptableComponents/AllClients.jsx";
import styles from "../../Styles/Styles";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { loadMenuPerCabinet } from "../../redux/actions/supAdmin";
import { useEffect } from "react";
import Loader from "../../Utils/Loader";
import MenuCrm from "../../Components/SupAdminComponents/MenuCrm";

const AdminListMenuoPage = () => {
  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));
  const cabId = collaborateurStore?.user.cabinet;
  //console.log(cabId);

  const dispatch = useDispatch();
  const menus = useSelector((state) => state.supAdmin);

  const getMenus = async (cabId) => {
    await dispatch(loadMenuPerCabinet(cabId));
  };

  useEffect(() => {
    getMenus(cabId);
  }, []);

  return (
    <div>
      <CabinetHeader />
      {menus?.loadingMenu === true ? (
        <Loader />
      ) : (
        <>
          <div className="flex ">
            <div className="w-[80px]  800px:w-[280px] mr-[1rem] 800px:mr-[3rem]">
              <ComptabledSideBar active={4} />
            </div>
            <div className="flex flex-wrap mt-4">
              {menus.menus?.data?.length > 0 ? (
                menus.menus?.data?.map((menu) => (
                  <MenuCrm key={menu._id} data={menu} pasDelete={"pasDelete"} />
                ))
              ) : (
                <h1 className="text-center"> Aucun menu n'a été ajouté</h1>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminListMenuoPage;
