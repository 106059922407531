import { React, useState, useEffect } from "react";
import { FaArrowLeft, FaCopy, FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

import Loader from "../../Utils/Loader";

import axios from "axios";
import { server } from "../../server";
import { useDispatch } from "react-redux";

const SupAdminCreateCabinetd = () => {
  const [name, setName] = useState("");
  const [cabinetPhone, setCabinetPhone] = useState("");
  const [emailCabinet, setEmailCabinet] = useState("");
  const [dirigeantCabinetName, setDirigeantCabinetName] = useState("");
  const [dirigeantCabinetPrenom, setDirigeantCabinetPrenom] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [password, setPassword] = useState("");
  const [secteur, setSecteur] = useState("");
  const [rc, setRc] = useState("");
  const [ice, setIce] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [adresse, setAdresse] = useState("");
  const [poste, setPoste] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [page, setPage] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCopyPassword = () => {
    const passwordInput = document.getElementById("passwordInput");
    passwordInput.select();
    document.execCommand("copy");
    setIsCopied(true); // Mettre à jour l'état pour indiquer que le code est copié
    // Réinitialiser l'état après quelques secondes (facultatif)
    setTimeout(() => setIsCopied(false), 5000); // Remettre à false après 3 secondes
  };

  const isNextButtonActive = () => {
    if (page === 1) {
      return name !== "" && cabinetPhone !== "" && emailCabinet !== "";
    } else if (page === 2) {
      return dirigeantCabinetName !== "" && phoneNumber !== "" && email !== "";
    } else if (page === 3) {
      return identifiant !== "" && password !== "";
    }
    return false;
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    const nameslice = name.slice(0, 2);

    setPassword(nameslice + randomPassword + nameslice);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      {
        name: "name",
        label: "Nom de la société",
      },
      { name: "cabinetPhone", label: "Numéro de téléphone" },
      { name: "emailCabinet", label: "Adresse email" },
      { name: "dirigeantCabinetName", label: "Nom du dirigeant" },
      { name: "phoneNumber", label: "Téléphone du dirigeant" },
      { name: "email", label: "Email du dirigeant" },
      { name: "password", label: "Mot de passe" },
      { name: "identifiant", label: "Identifiant" },
    ];

    // Créez un objet pour stocker les valeurs des champs
    const formValues = {
      name,
      cabinetPhone,
      emailCabinet,
      dirigeantCabinetName,
      phoneNumber,
      email,
      password,
      identifiant,
    };

    // Filtrer les champs vides et récupérer les labels des champs vides
    const emptyFields = requiredFields
      .filter((field) => !formValues[field.name])
      .map((field) => field.label);

    if (emptyFields.length > 0) {
    // console.log(
    //     "Merci de remplir les informations obligatoires : ",
    //     emptyFields.join(", ")
    //   );
      // Afficher une alerte avec les champs vides
      alert(
        "Merci de remplir les informations obligatoires : " +
          emptyFields.join(", ")
      );
      return;
    }

    setShowLoadingModal(true);
    await axios
      .post(
        `${server}/supAdmin/create-cabinet-admin`,
        {
          name,
          cabinetPhone,
          emailCabinet,
          dirigeantCabinetName,
          dirigeantCabinetPrenom,
          identifiant,
          password,
          secteur,
          rc,
          ice,
          email,
          phoneNumber,
          poste,
          adresse,
          moreInfo,
          role: "Admin",
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        //console.log(res);
        if (res && res.status === 200) {
          toast.success(
            `le compte pro ${res.data?.data?.name} a bien été créée`
          );
          setIdentifiant("");
          setPassword("");
          setTimeout(() => navigate("/supAdmin/dashboard"), 2000);
        }
      })
      .catch((error) => {
        //console.log(error?.response?.data?.message);
        alert(error?.response?.data?.message);
        dispatch({ type: "clearErrors" });
      });
    setShowLoadingModal(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md mb-4">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Nouveau client
        </h2>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center">
          <div
            className={`${
              page === 1 || 2 || 3 || 4 ? "bg-[#355ee2]  " : "bg-gray-500 "
            }w-14 h-14 rounded-full  flex items-center justify-center text-white font-bold`}
          >
            1
          </div>

          <div className="800px:w-10 w-5 h-1 bg-[#90a4e6] "></div>
          <div
            className={`${
              page > 1 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            2
          </div>

          <div className="800px:w-10 w-5 h-1 bg-[#90a4e6]"></div>
          <div
            className={`${
              page > 2 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            3
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl ">
        <div className="bg-white pb-10 pt-4 px-4 shadow sm:rounded-lg sm:px-10">
          {page === 1 && (
            <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
              Informations de la societé
            </h1>
          )}

          {page === 2 && (
            <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
              <FaArrowLeft
                onClick={() => setPage(page - 1)}
                className="cursor-pointer"
              />
              Informations Admin
            </h1>
          )}

          {page === 3 && (
            <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
              <FaArrowLeft
                onClick={() => setPage(page - 1)}
                className="cursor-pointer"
              />
              Informations de connexion d'administrateur
            </h1>
          )}

          <form className="space-y-6" onSubmit={handleSubmit}>
            {page === 1 && (
              <>
                <div className="800px:mr-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom de la société
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 ">
                    <input
                      type="text"
                      name="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="800px:flex  ">
                  <div className="800px:mr-4">
                    <label
                      htmlFor="cabinetPhone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Numéro de téléphone
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="tel"
                        name="cabinetPhone"
                        value={cabinetPhone}
                        onChange={(e) => setCabinetPhone(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="secteur"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Secteur d'activité
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="text"
                        name="secteur"
                        value={secteur}
                        onChange={(e) => setSecteur(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="800px:flex  ">
                  <div className="800px:mr-4">
                    <label
                      htmlFor="rc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      N° RC
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="text"
                        name="rc"
                        value={rc}
                        onChange={(e) => setRc(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="ice"
                      className="block text-sm font-medium text-gray-700"
                    >
                      N° ICO
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="text"
                        name="phone"
                        value={ice}
                        onChange={(e) => setIce(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="adresse"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adresse de la société
                  </label>
                  <div className="mt-1 ">
                    <input
                      type="text"
                      name="adresse"
                      value={adresse}
                      onChange={(e) => setAdresse(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adresse email
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      value={emailCabinet}
                      onChange={(e) => setEmailCabinet(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </>
            )}
            {page === 2 && (
              <>
                <div className="800px:flex justify-between">
                  <div className="800px:mr-4">
                    <label
                      htmlFor="dirigeantCabinetName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nom du dirigeant
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="text"
                        name="text"
                        value={dirigeantCabinetName}
                        onChange={(e) =>
                          setDirigeantCabinetName(e.target.value)
                        }
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="dirigeantCabinetPrenom"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prénom du dirigeant
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="text"
                        name="dirigeantCabinetPrenom"
                        value={dirigeantCabinetPrenom}
                        onChange={(e) =>
                          setDirigeantCabinetPrenom(e.target.value)
                        }
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="800px:flex justify-between">
                  <div className="800px:mr-4">
                    <label
                      htmlFor="poste"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Poste
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="text"
                        name="poste"
                        value={poste}
                        onChange={(e) => setPoste(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Téléphone du dirigeant
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 800px:w-[250px]">
                      <input
                        type="text"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email du dirigeant
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="moreInfo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Informations supplémentaires
                  </label>
                  <div className="mt-1">
                    <textarea
                      type="text"
                      name="moreInfo"
                      value={moreInfo}
                      onChange={(e) => setMoreInfo(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </>
            )}

            {page === 3 && (
              <>
                <div className="">
                  <label
                    htmlFor="identifiant"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Identifiant
                  </label>
                  <div className="mt-1 ">
                    <input
                      type="text"
                      name="identifiant"
                      value={identifiant}
                      onChange={(e) => setIdentifiant(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="button"
                    onClick={generateRandomPassword}
                    className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded"
                  >
                    Générer un mot de passe
                  </button>
                </div>

                <div className="mt-4">
                  <label className="block font-medium text-gray-700">
                    Mot de passe à envoyer au client:
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="text"
                      value={password}
                      placeholder="Cliquez sur 'Générer un mot de passe'"
                      onChange={(e) => setPassword(e.target.value)}
                      readOnly
                      id="passwordInput" // Assurez-vous que l'input a un ID pour la sélection
                      className="mt-1 p-2 pl-10 border border-gray-300 800px:text-base text-sm rounded w-full"
                    />
                    <button
                      type="button"
                      onClick={handleCopyPassword}
                      className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                    >
                      {isCopied ? <FaCheck /> : <FaCopy />}
                    </button>
                  </div>
                </div>
              </>
            )}

            <div>
              {page !== 3 && (
                <div
                  className={`${
                    page === 1 ? "flex justify-end" : "flex justify-between"
                  }`}
                >
                  <button
                    onClick={() => setPage(page - 1)}
                    className={`${
                      page > 1
                        ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        : "hidden"
                    }`}
                  >
                    Retour
                  </button>

                  {page === 1 && (
                    <button
                      onClick={() => setPage(page + 1)}
                      className={`bg-blue-${
                        isNextButtonActive() ? "500 hover:bg-blue-700" : "300"
                      } text-white font-bold py-2 px-4 rounded`}
                      disabled={!isNextButtonActive()} // Désactiver le bouton si le bouton n'est pas actif
                    >
                      Suivant
                    </button>
                  )}

                  {page === 2 && (
                    <button
                      onClick={() => setPage(page + 1)}
                      className={`bg-blue-${
                        isNextButtonActive() ? "500 hover:bg-blue-700" : "300"
                      } text-white font-bold py-2 px-4 rounded`}
                      disabled={!isNextButtonActive()} // Désactiver le bouton si le bouton n'est pas actif
                    >
                      Suivant
                    </button>
                  )}
                </div>
              )}
              <div className="flex items-center justify-center">
                {page === 3 && (
                  <button
                    type="submit"
                    className={`${
                      isNextButtonActive()
                        ? "bg-blue-600 hover:bg-blue-700"
                        : "bg-blue-300"
                    } text-white font-bold py-2 px-4 rounded w-80 `}
                    disabled={!isNextButtonActive()}
                  >
                    Valider
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </div>
  );
};

export default SupAdminCreateCabinetd;
