import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import DossierContentDetails from "./DossierContentDetails.jsx";
import Loader from "../../Utils/Loader";
import {
  getAllCollaborateursByCab,
  updateDossierClientByCollaborateur,
} from "../../redux/actions/collaborateurAction.js";

const DossierContent = ({
  dossierClient,
  folderFile,
  openedFile,
  setOpenedFile,
  collaborateurInfo,
  getUpdated,
  searchText,
}) => {
  //console.log("dossierClient", dossierClient);
  //console.log("dossierClient", dossierClient?.cabinet);

  const { id, folderFileId } = useParams();

  //console.log("params", id, folderFileId);

  const [showModalEditResponsableDossier, setShowModalEditResponsableDossier] =
    useState(false);
  const [showModalEditClientInfo, setShowModalEditClientInfo] = useState(null);
  const [responsableDossierName, setResponsableDossierName] = useState(null);
  const [isLoadingUpdating, setIsLoadingUpdating] = useState();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [isLoadingAllColaborateurs, setIsLoadingAllColaborateurs] = useState();
  const [collaborateursList, setCollaborateursList] = useState([]);
  const [name, setName] = useState(null);
  const [societePhone, setSocietePhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [dirigeantName, setDirigeantName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [adressePro, setAdressePro] = useState(null);

  const dispatch = useDispatch();
  const res = useSelector((state) => state.collaborateur);

  useEffect(() => {
    if (isLoadingAllColaborateurs === false) {
      if (
        res &&
        res.allCollaborateurs &&
        res.allCollaborateurs.success === true &&
        res.allCollaborateurs.data
      )
        setCollaborateursList(res.allCollaborateurs?.data);
    }
  }, [isLoadingAllColaborateurs]);

  useEffect(() => {
    if (
      dossierClient?.cabinet &&
      JSON.parse(localStorage.getItem("collaborateur"))?.user?.role !==
        "Collaborateur"
    )
      allCollaborateurs();
  }, [dossierClient]);

  const allCollaborateurs = async () => {
    setIsLoadingAllColaborateurs(true);
    await dispatch(getAllCollaborateursByCab(dossierClient?.cabinet));
    setIsLoadingAllColaborateurs(false);
  };

  useEffect(() => {
    if (dossierClient && dossierClient.responsableDossier) {
      setResponsableDossierName(dossierClient.responsableDossier);
      setName(dossierClient.name);
      setPhoneNumber(dossierClient.phoneNumber);
      setSocietePhone(dossierClient.societePhone);
      setEmail(dossierClient.email);
      setDirigeantName(dossierClient.dirigeantName);
      setAdressePro(dossierClient.adressePro);
    }
  }, [dossierClient]);

  const closeModalEditResponsable = () => {
    setShowModalEditResponsableDossier(false);
    setResponsableDossierName(dossierClient.responsableDossier);
  };

  const handleUpdateResponsableDossier = async (e) => {
    e.preventDefault();
    // validations

    setIsLoadingUpdating(true);
    setShowLoadingModal(true);

    await dispatch(
      updateDossierClientByCollaborateur(id, { responsableDossierName })
    );
    getUpdated("yes");
    setShowLoadingModal(false);
    setIsLoadingUpdating(false);
  };

  const closeModalEditClientInfo = () => {
    setShowModalEditClientInfo(false);
    setName(dossierClient?.name);
    setSocietePhone(dossierClient?.societePhone);
    setEmail(dossierClient?.email);
    setDirigeantName(dossierClient?.dirigeantName);
    setPhoneNumber(dossierClient?.phoneNumber);
    setAdressePro(dossierClient?.adressePro);
  };

  const handleUpdateDossierInfo = async (e) => {
    e.preventDefault();

    setIsLoadingUpdating(true);

    setShowLoadingModal(true);

    await dispatch(
      updateDossierClientByCollaborateur(id, {
        name,
        societePhone,
        email,
        dirigeantName,
        phoneNumber,
        adressePro,
      })
    );
    getUpdated("yes");
    setShowLoadingModal(false);
    setIsLoadingUpdating(false);

    setShowModalEditClientInfo(false);
  };

  return (
    <>
      {id && (folderFileId === undefined || folderFileId === "") && (
        <div className="py-4 px-4 800px:text-base text-sm  ">
          <h1 className="font-bold mt-2 flex flex-wrap mb-2">
            Responsable du dossier :{" "}
            <span className=" flex  ml-2">
              {" "}
              {dossierClient?.responsableDossier || ""}{" "}
              {collaborateurInfo.role === "Admin" ||
              collaborateurInfo.role === "Manager" ? (
                <AiFillEdit
                  className="ml-3 cursor-pointer"
                  onClick={() => setShowModalEditResponsableDossier(true)}
                />
              ) : null}
            </span>
          </h1>

          <h1 className=" font-bold  mt-3">Informations client :</h1>
          <div className="shadow-md  px-3 py-4 rounded-md   ">
            <div className="flex justify-end mr-3 mb-2">
              <AiFillEdit
                className="ml-3 cursor-pointer"
                onClick={() => setShowModalEditClientInfo(true)}
              />
            </div>
            <div className="800px:flex 800px:justify-around 800px:space-y-0 space-y-2">
              <div className="space-y-2 mr-7">
                <h1>
                  Nom de la société :{" "}
                  <span className="font-bold">{dossierClient?.name || ""}</span>
                </h1>
                <h1>
                  Numéro de téléphone :{" "}
                  <span className="font-bold">
                    {dossierClient?.societePhone || ""}
                  </span>
                </h1>

                <h1>
                  Email de société :{" "}
                  <span className="font-bold">
                    {dossierClient?.email || ""}
                  </span>
                </h1>
              </div>
              <div className="space-y-2">
                <h1>
                  Nom du dirigeant :{" "}
                  <span className="font-bold">
                    {dossierClient?.dirigeantName || ""}
                  </span>
                </h1>
                <h1>
                  Numéro de portable :{" "}
                  <span className="font-bold">
                    {dossierClient?.phoneNumber || ""}
                  </span>
                </h1>
                <h1>
                  Adresse de société :{" "}
                  <span className="font-bold">
                    {dossierClient?.adressePro || ""}
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}
      {id && folderFileId && (
        <DossierContentDetails
          folderFile={folderFile}
          openedFile={openedFile}
          setOpenedFile={setOpenedFile}
          dossierClient={dossierClient}
          collaborateurInfo={collaborateurInfo}
          getUpdated={getUpdated}
          searchText={searchText}
        />
      )}

      <Modal
        show={showModalEditResponsableDossier}
        onHide={closeModalEditResponsable}
      >
        <Modal.Header>
          <Modal.Title>Modifier le responsable du dossier</Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModalEditResponsable}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formResponsableName">
            <select
              name="responsableDossier"
              value={responsableDossierName}
              onChange={(event) =>
                setResponsableDossierName(event.target.value)
              }
              className="block ml-2 w-70 cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="">Affecter un collaborateur à ce dossier</option>
              {collaborateursList?.map((collaborateur) => (
                <option
                  key={collaborateur._id}
                  value={`${collaborateur.prenom} ${collaborateur.name}`}
                >
                  {`${collaborateur.prenom} ${collaborateur.name}`}
                </option>
              ))}
            </select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={closeModalEditResponsable}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500"
            onClick={handleUpdateResponsableDossier}
          >
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalEditClientInfo} onHide={closeModalEditClientInfo}>
        <Modal.Header>
          <Modal.Title>Modifier les informations client</Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModalEditClientInfo}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formSocieteName">
            <Form.Label>Nom de société</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Numéro de téléphone</Form.Label>
            <Form.Control
              type="text"
              value={societePhone}
              onChange={(e) => setSocietePhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email </Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formDirigeantName">
            <Form.Label>Nom du dirigeant</Form.Label>
            <Form.Control
              type="text"
              value={dirigeantName}
              onChange={(e) => setDirigeantName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPortableNumber">
            <Form.Label>Numéro de portable</Form.Label>
            <Form.Control
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formAdresseSociete">
            <Form.Label>Adresse de société</Form.Label>
            <Form.Control
              type="text"
              value={adressePro}
              onChange={(e) => setAdressePro(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={closeModalEditClientInfo}
          >
            Annuler
          </Button>
          <Button className="bg-blue-500" onClick={handleUpdateDossierInfo}>
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </>
  );
};

export default DossierContent;
