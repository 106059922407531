import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import Loader from "../../Utils/Loader";

const ForgotPassword = ({ type }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (email === "") {
      return alert(
        "Merci de saisir votre email de connexion pour recevoir le code de réinitialisation"
      );
    }
  
    setLoading(true);
    let endpoint, storageKey;
  
    if (type === "client") {
      endpoint = "client";
      storageKey = "emailClient";
    } else if (type === "collaborateur") {
      endpoint = "collaborateur";
      storageKey = "emailCollaborateur";
    }
  
    try {
      const response = await axios.post(
        `${server}/${endpoint}/forgetPassword`,
        {
          email,
        },
        { withCredentials: true }
      );
  
      
  
      localStorage.setItem(storageKey, JSON.stringify(email));
      setEmail("");
  
      if (type === "client") {
        navigate("/reset-code");
      } else if (type === "collaborateur") {
        navigate("/collaborateur-reset-code");
      }
    } catch (error) {
      
  
      alert("Il y a un problème dans l'envoi du mail");
    }
  
    setLoading(false);
  };
  

  return (
    <div className="container mx-auto p-4">
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-2xl font-bold mb-6 mt-4 text-center">
            Mots de passe oublié?
          </h2>
          <form onSubmit={handleSubmit} className="max-w-md mx-auto pt-4">
            <div className="mb-4">
              <label htmlFor="email" className="block font-medium mb-1">
                Adresse e-mail :
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                className="w-full p-2 border rounded"
                placeholder="Saire votre adresse mail de connexion"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Recevoir le code
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
