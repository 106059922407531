import React, { useEffect } from "react";
import { AiFillFolderOpen, AiOutlineFolderOpen } from "react-icons/ai";
import { BiSolidDownArrow } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

const OpenedFolderFile = ({
  file,
  setOpenedFile,
  openedFile,
  position,
  showMoveModal,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  // lastOpened a partir de l'array openFile pour colorer celui qui est ouvert et aussi pour mettre l'id dans le params
  const lastOpened = openedFile[openedFile.length - 1];

  const handleParams = () => {
    if (showMoveModal === "false") {
      const lastOpenedArray = lastOpened?.split("/");
      const idLastOpened = lastOpenedArray[lastOpenedArray?.length - 1];
      //console.log(idLastOpened);
      navigate(`/client/${id}/${idLastOpened}`);
    }
  };

  const handleCloseFile = () => {
    if (lastOpened === file.pathById) {
      const filteredArray = openedFile?.filter(
        (item) => !item?.startsWith(file?.pathById)
      );

      setOpenedFile([...filteredArray]);
    } else {
      const newArray = openedFile.filter((item) => item !== file?.pathById);
      newArray.push(file?.pathById);
      setOpenedFile([...newArray]);
    }
  };
  useEffect(() => {
    handleParams();
  }, [openedFile]);

  return (
    <div
      className={`flex  items-center cursor-pointer mr-1 mb-1 pl-1 space-x-1 ${
        position === 0
          ? "ml-2"
          : position === 1
          ? "ml-4"
          : position === 2
          ? "ml-6"
          : position === 3
          ? "ml-8"
          : "ml-10"
      } ${lastOpened === file.pathById ? "bg-blue-200 rounded-sm" : ""}`}
      onClick={handleCloseFile}
    >
      <BiSolidDownArrow className="text-blue-500 mr-1" size={10} />
      {file?.files?.length > 0 ? (
        <AiFillFolderOpen size={25} className="text-blue-500 mr-3" />
      ) : (
        <AiOutlineFolderOpen size={25} className="text-blue-500 mr-3" />
      )}

      <h3>
        {" "}
        {file?.name.length > 20
          ? file?.name.substring(0, 20) + ".."
          : file?.name}
      </h3>
    </div>
  );
};

export default OpenedFolderFile;
