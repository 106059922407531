import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import {
  HomePage,
  ComptableDashboardPage,
  ComptableDossierClientPage,
  LoginProPage,
  ComptableAllClientsPage,
  GestionCollaborateurs,
  GestionClients,
  EspaceClientPage,
  ChangePasswordPage,
} from "./Routes/ComptableRoutes.jsx";

import {
  ComptableRegisterPage,
  UserRegisterPage,
  SupAdminDashboardPage,
  SupAdminCreateCabinetdPage,
  SupAdminArboPage,
  SupAdminMenuClientPage,
  LoginSupAdmin,
  SupAdminAddArboPage,
  SupAdminAddMenuPage,
  AdminListArboPage,
  AdminListMenuoPage,
  CollaborateurForgotPasswordPage,
  CollaborateurResetCodePage,
  CollaborateurNewPasswordPage,
} from "./Routes/AdminRoutes.jsx";
import {
  LoginPage,
  MyClientPage,
  ClientChangePasswordPage,
  PageNotFound,
  ForgotPasswordPage,
  ResetCodePage,
  NewPasswordPage,
} from "./Routes/UserRoutes.jsx";

import CollaborateurProtectedRoute from "./Routes/CollaborateurProtectedRoute.js";
import ProtectedRoute from "./Routes/ProtectedRoute";
import SupAdminProtectedRoute from "./Routes/SupAdminProtectedRoute";
import ProtectedRouteSup from "./Routes/ProtectedRouteSup";
import ClientProtectedRoute from "./Routes/ClientProtectedRoute";
import ProtectedRouteClt from "./Routes/ProtectedRouteClt";

function App() {
  const [isAuthenticated, active, , isManager, isAdmin, ,] =
    CollaborateurProtectedRoute();

  // const [isSupAdmin, isActive] = SupAdminProtectedRoute();
  const [isAuthentificated, activeClient, setIsAuthentificated] =
    ClientProtectedRoute();

  const resetScrollPosition = () => {
    window.scrollTo(0, 0);
  };

  // Utilisez useEffect pour exécuter la fonction de réinitialisation après chaque actualisation de la page
  useEffect(() => {
    resetScrollPosition();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<LoginPage />} />

        {/*  client Routes */}

        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-code" element={<ResetCodePage />} />
        <Route path="/new-password" element={<NewPasswordPage />} />
        <Route element={<ProtectedRouteClt />}>
          <Route
            path="/modifier-mots-de-passe"
            element={<ClientChangePasswordPage />}
          />
          <Route path="/espaceClient" element={<MyClientPage />} />

          <Route path="/espaceClient/:catId" element={<MyClientPage />} />
        </Route>
        {/*  Comptable Routes */}

        <Route path="/login-pro" element={<LoginProPage />} />

        <Route
          element={
            <ProtectedRoute allowed={["Admin", "Manager", "Collaborateur"]} />
          }
        >
          <Route path="/dashboard" element={<ComptableDashboardPage />} />
          <Route path="/clients" element={<ComptableAllClientsPage />} />

          <Route path="/client/:id" element={<ComptableDossierClientPage />} />
          <Route path="/espaceClient/:id" element={<EspaceClientPage />} />

          <Route
            path="/client/:id/:folderFileId"
            element={<ComptableDossierClientPage />}
          />
        </Route>
        {/*  Admin  Routes */}

        <Route element={<ProtectedRoute allowed={["Admin", "Manager"]} />}>
          <Route path="/client-register" element={<UserRegisterPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route
            path="/admin/collaborateur-register"
            element={<ComptableRegisterPage />}
          />
          <Route
            path="/dashboard/collaborateurs"
            element={<GestionCollaborateurs />}
          />
          <Route path="/dashboard/clients" element={<GestionClients />} />
          <Route
            path="/dashboard/arborescences"
            element={<AdminListArboPage />}
          />
          <Route path="/dashboard/menus" element={<AdminListMenuoPage />} />

          <Route
            path="/collaborateur-forgot-password"
            element={<CollaborateurForgotPasswordPage />}
          />
          <Route
            path="/collaborateur-reset-code"
            element={<CollaborateurResetCodePage />}
          />
          <Route
            path="/collaborateur-new-password"
            element={<CollaborateurNewPasswordPage />}
          />
        </Route>

        {/*  SupAdmin Routes */}
        <Route path="/logins-m" element={<LoginSupAdmin />} />
        <Route
          element={<ProtectedRouteSup  />}
        >
          <Route
            path="/supAdmin/dashboard"
            element={<SupAdminDashboardPage />}
          />
          <Route
            path="/supAdmin/create-compte"
            element={<SupAdminCreateCabinetdPage />}
          />
          <Route
            path="/supAdmin/:cabId/arborescence"
            element={<SupAdminArboPage />}
          />
          <Route
            path="/supAdmin/:cabId/add-arbo"
            element={<SupAdminAddArboPage />}
          />
          <Route
            path="/supAdmin/:cabId/menuClient"
            element={<SupAdminMenuClientPage />}
          />
          <Route
            path="/supAdmin/:cabId/add-menu"
            element={<SupAdminAddMenuPage />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
