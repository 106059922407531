import React from "react";
import ComptableLogin from "../../Components/ComptableComponents/LoginPro.jsx";

const LoginProPage = () => {
  return (
    <div>
      <ComptableLogin />
    </div>
  );
};

export default LoginProPage;
