import React from "react";

import CabinetHeader from "../../Components/ComptableComponents/CabinetHeader.jsx";
import ComptableDashboard from "../../Components/ComptableComponents/ComptableDashboard.jsx";

const ComptableDashboardPage = () => {
  return (
    <div className="h-screen ">
      <CabinetHeader />
      <div className="mt-4 w-10/12 mx-auto">
        <ComptableDashboard />
      </div>
    </div>
  );
};

export default ComptableDashboardPage;
