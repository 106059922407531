import * as React from "react";
import CabinetHeader from "../../Components/ComptableComponents/CabinetHeader";
import ComptabledSideBar from "../../Components/ComptableComponents/ComptabledSideBar.jsx";
import AllCollaborateurs from "../../Components/ComptableComponents/AllCollaborateurs.jsx";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const GestionCollaborateurs = () => {
  return (
    <div>
      <CabinetHeader />
      <div className="flex  justify-end">
        <div className="flex flex-col justify-end px-3 w-[290px]  ">
          <Link to="/admin/collaborateur-register">
            <div className=" px-2 bg-blue-600  h-[50px] mr-6 mt-3 flex items-center justify-center rounded-xl cursor-pointer hover:bg-blue-700">
              <h1 className="text-[#fff] flex items-center 800px:text-[16px] text-sm">
                {" "}
                Nouveau collaborateur <IoIosArrowForward className="ml-1" />
              </h1>
            </div>
          </Link>
          <Link
            to="/collaborateur-forgot-password"
            className="h-[30px] mr-6 mt-3 px-2 flex items-center"
          >
            <h1 className="text-[red] flex items-center 800px:text-[16px] text-sm ">
              {" "}
              MDP collaborateur oublié
            </h1>
          </Link>
        </div>
      </div>
      <div className="flex justify-between w-full h-[80vh] ">
        <div className="w-[80px] 800px:w-[280px]">
          <ComptabledSideBar active={1} />
        </div>
        <div className="w-full justify-center flex">
          <AllCollaborateurs />
        </div>
      </div>
    </div>
  );
};

export default GestionCollaborateurs;
