import React from "react";
import { AiOutlineFolder } from "react-icons/ai";
import { BiSolidRightArrow } from "react-icons/bi";
import { HiFolder } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";

const ClosedFloderFile = ({
  file,
  setOpenedFile,
  position,
  openedFile,
  showMoveModal,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleRepFolder = (folderId) => {
    if (openedFile?.includes(folderId)) {
      const filteredArray = openedFile.filter((value) => value !== folderId);

      const updatedArray = [...filteredArray, folderId];

      setOpenedFile(updatedArray);
    } else {
      setOpenedFile((prevFolders) => [...prevFolders, file?.pathById]);
    }
  };

  //console.log("showMoveModal", showMoveModal);
  const handleOpenFile = () => {
    handleRepFolder(file?.pathById);
    if (showMoveModal === "false") navigate(`/client/${id}/${file._id}`);
  };

  return (
    <div
      className={`flex items-center cursor-pointer mb-1 mr-1 pl-1 space-x-1 ${
        position === 0
          ? "ml-2"
          : position === 1
          ? "ml-4"
          : position === 2
          ? "ml-6"
          : position === 3
          ? "ml-8"
          : "ml-10"
      }`}
      onClick={handleOpenFile}
    >
      <BiSolidRightArrow className="text-blue-500 mr-1" size={10} />
      {file?.files?.length > 0 ? (
        <HiFolder size={25} className="text-blue-600 mr-3" />
      ) : (
        <AiOutlineFolder size={25} className="text-blue-600 mr-3" />
      )}

      <h3>
        {file?.name.length > 20
          ? file?.name.substring(0, 20) + ".."
          : file?.name}
      </h3>
    </div>
  );
};

export default ClosedFloderFile;
