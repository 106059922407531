import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { HiSearch, HiXCircle } from "react-icons/hi";
import CabinetHeader from "../../Components/ComptableComponents/CabinetHeader";
import ArborescenceSide from "../../Components/ComptableComponents/ArborescenceSide.jsx";
import CabinetHeaderPath from "../../Components/ComptableComponents/CabinetHeaderPath";
import DossierContent from "../../Components/ComptableComponents/DossierContent.jsx";
import { loadDossierClientByCollaborateur } from "../../redux/actions/collaborateurAction";
import Loader from "../../Utils/Loader";
import { AiOutlineEye } from "react-icons/ai";

const ComptableDossierClientPage = () => {
  // Dans cette page on affiche le dossier client et le contenu de ce dossier (arborescence et dossier qui sont dedans)
  const { id, folderFileId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dossierClient, setDossierClient] = useState(null);
  const [openedFile, setOpenedFile] = useState([]);
  const [folderFile, setFolderFile] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchText, setSearchText] = useState("");

  const getUpdated = (data) => {
    if (data === "yes") setIsUpdated(true);
  };

  const handleGetDossierClient = async () => {
    await dispatch(loadDossierClientByCollaborateur(id));
    setIsLoading(false);
    setIsUpdated(false);
  };

  useEffect(() => {
    if (isUpdated === true) {
      setIsLoading(true);
      handleGetDossierClient();
    }
  }, [isUpdated]);

  useEffect(() => {
    setIsLoading(true);
    handleGetDossierClient();
  }, []);

  const response = useSelector((state) => state.collaborateur);

  //console.log("response", response);

  useEffect(() => {
    if (isLoading === false) {
      if (
        response?.client?.success === true &&
        response?.client?.dossierClient !== ""
      ) {
        setDossierClient(response.client.dossierClient);
        //console.log(dossierClient);
      }
      if (response?.error) {
        if (response?.error === "Merci de vous connecter pour continuer") {
          toast.error(response?.error);
          setTimeout(() => {
            navigate("/login-pro");
          }, 1500);
        } else {
          toast.error(response?.error);
          setTimeout(() => {
            navigate("/clients");
          }, 1500);
        }
        dispatch({ type: "clearErrors" });
      }

      if (
        response?.client?.success === undefined &&
        response?.error === undefined
      ) {
        toast.error("Id client invalide");
        setTimeout(() => {
          navigate("/clients");
        }, 1500);
      }
      dispatch({ type: "clearErrors" });
    }
  }, [isLoading, dossierClient]);

  useEffect(() => {
    if (dossierClient !== null && folderFile.length === 0) {
      setOpenedFile((prevFolders) => [...prevFolders, dossierClient?.pathById]);
    }
  }, [dossierClient, folderFile]);
  //console.log(openedFile);

  const getDossierById = (data, id) => {
    // Parcours de la liste des dossiers
    for (const item of data) {
      // Vérification de l'ID du dossier actuel
      if (item._id === id) {
        return item; // Retourne le dossier trouvé
      }

      // Vérification des sous-dossiers
      if (item.files && item.files.length > 0) {
        const subDossier = getDossierById(item.files, id);
        if (subDossier) {
          return subDossier; // Retourne le sous-dossier trouvé
        }
      }
    }

    return null; // Aucun dossier trouvé avec l'ID donné
  };
  useEffect(() => {
    if (dossierClient && folderFileId) {
      const getFolderFile = getDossierById(dossierClient?.files, folderFileId);
      if (getFolderFile) {
        setFolderFile(getFolderFile);
      } else {
        toast.error("Id dossier introuvable");
      }
    }
  }, [folderFileId, dossierClient]);

  let collaborateur;
  const getCollaborateur = localStorage.getItem("collaborateur");
  if (getCollaborateur) {
    collaborateur = JSON.parse(getCollaborateur);
  }
  const collaborateurInfo = collaborateur?.user;

  //console.log("isUpdated", isUpdated);
  //console.log("folderFile", folderFile);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <CabinetHeader />
          <div className=" flex  w-full relative ">
            <div className="w-[280px] 800px:block hidden pl-3 shadow-sm  h-[90vh] overflow-y-auto pb-2">
              {dossierClient?.name && (
                <div>
                  <h2 className="pt-3 mb-3 text-center font-bold">
                    Dossier :{" "}
                    <span className="font-bold">
                      {" "}
                      {dossierClient?.name?.length > 12
                        ? dossierClient?.name.substring(0, 12) + "..."
                        : dossierClient?.name}
                    </span>
                  </h2>
                  <div className="flex justify-end pr-2">
                    <Link to={`/espaceClient/${id}`} className="text-blue-700">
                      <AiOutlineEye />
                    </Link>
                  </div>
                </div>
              )}

              <ArborescenceSide
                openedFile={openedFile}
                setOpenedFile={setOpenedFile}
                isLoading={isLoading}
                dossierClient={dossierClient}
                folderFile={folderFile}
              />
            </div>
            <div className=" 800px:w-[80%] w-full">
              <CabinetHeaderPath
                isOpen={isOpen}
                toggleSidebar={toggleSidebar}
                dossierClient={dossierClient}
                folderFile={folderFile}
                openedFile={openedFile}
                setOpenedFile={setOpenedFile}
                getUpdated={getUpdated}
                collaborateurInfo={collaborateurInfo}
                showMoveModal="false"
                searchText={searchText}
                setSearchText={setSearchText}
              />
              <div className="flex justify-end">
                {isOpen && (
                  <div className=" w-[250px]   bg-white z-50 800px:hidden block absolute  pl-3 shadow-sm  min-h-[75vh] pr-4 overflow-y-auto ">
                    {dossierClient?.name && (
                      <div>
                        <h2 className="pt-3 mb-3 text-center font-bold">
                          Dossier :{" "}
                          <span className="font-bold">
                            {" "}
                            {dossierClient?.name?.length > 12
                              ? dossierClient?.name.substring(0, 12) + "..."
                              : dossierClient?.name}
                          </span>
                        </h2>
                        <div className="flex justify-end pr-2">
                          <Link
                            to={`/espaceClient/${id}`}
                            className="text-blue-700"
                          >
                            <AiOutlineEye />
                          </Link>
                        </div>
                      </div>
                    )}
                    <div className="flex items-center justify-center relative mb-3 ">
                      <input
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Rechercher..."
                        className="  py-2 pl-8 mr-6 border-b bg-inherit border-gray-500  text-sm   "
                      />
                      {searchText ? (
                        <div
                          className="absolute left-2 cursor-pointer"
                          onClick={() => setSearchText("")}
                        >
                          <HiXCircle size={18} className="text-gray-500" />
                        </div>
                      ) : (
                        <div className="absolute left-2">
                          <HiSearch size={18} className="text-gray-500" />
                        </div>
                      )}
                    </div>

                    <ArborescenceSide
                      openedFile={openedFile}
                      setOpenedFile={setOpenedFile}
                      isLoading={isLoading}
                      dossierClient={dossierClient}
                      folderFile={folderFile}
                    />
                  </div>
                )}
              </div>
              <DossierContent
                dossierClient={dossierClient}
                folderFile={folderFile}
                openedFile={openedFile}
                setOpenedFile={setOpenedFile}
                collaborateurInfo={collaborateurInfo}
                getUpdated={getUpdated}
                searchText={searchText}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ComptableDossierClientPage;
