import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SupAdminSideBar from "../../Components/SupAdminComponents/SupAdminSideBar.jsx";
import MenuCrm from "../../Components/SupAdminComponents/MenuCrm.jsx";

import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import SupHeader from "../../Components/SupAdminComponents/SupHeader";
import { useState } from "react";
import { loadMenuPerCabinet } from "../../redux/actions/supAdmin.js";
import { useEffect } from "react";
import Loader from "../../Utils/Loader";
const SupAdminMenuClientPage = () => {
  const { cabId } = useParams();

  const [getUpdated, setGetUpdated] = useState(false);

  const dispatch = useDispatch();
  const menus = useSelector((state) => state.supAdmin);
  //console.log(menus);
  //console.log(menus.loadingMenu);
  //console.log(menus.menus?.data);

  const getMenus = async (cabId) => {
    await dispatch(loadMenuPerCabinet(cabId));
  };

  useEffect(() => {
    getMenus(cabId);
  }, []);

  useEffect(() => {
    if (getUpdated === true) getMenus(cabId);
  }, [getUpdated]);

  return (
    <div>
      <SupHeader />
      {menus?.loadingMenu === true ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-end px-3 mb-4 ">
            <Link to={`/supAdmin/${cabId}/add-menu`}>
              <div className="w-[180px] bg-blue-600 h-[50px] mr-6 mt-3 flex items-center justify-center rounded-xl cursor-pointer hover:bg-blue-700">
                <h1 className="text-[#fff] flex items-center">
                  {" "}
                  Ajouter Menu <IoIosArrowForward className="ml-1" />
                </h1>
              </div>
            </Link>
          </div>
          <div className="flex ">
            <div className="w-[80px]  800px:w-[280px] mr-[1rem] 800px:mr-[3rem]">
              <SupAdminSideBar active={2} />
            </div>
            <div className="flex flex-wrap ">
              {menus.menus?.data?.length > 0 ? (
                menus.menus?.data?.map((menu) => (
                  <MenuCrm
                    key={menu._id}
                    data={menu}
                    setGetUpdated={setGetUpdated}
                  />
                ))
              ) : (
                <h1 className="text-center"> Aucun menu n'a été ajouté</h1>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SupAdminMenuClientPage;
