import axios from "axios";
import { server } from "../../server";

export const loginSupAdmin = (identifiant, password) => async (dispatch) => {
  try {
    dispatch({
      type: "LoginSupAdminRequest",
    });
    //console.log("req supAdmin login");
    const { data } = await axios.post(
      `${server}/supAdmin/logins`,
      {
        identifiant,
        password,
      },
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "LoginSupAdminSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "LoginSupAdminFail",
      payload: error?.response?.data?.message,
    });
  }
};

// logout supAdmin
export const logoutSupAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "LogoutSupAdminRequest",
    });
    const { data } = await axios.get(
      `${server}/supAdmin/logouts`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "LogoutSupAdminSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "LogoutSupAdminFail",
      payload: error?.response?.data?.message,
    });
  }
};

// get All comptePro
export const getAllComptePro = () => async (dispatch) => {
  try {
    dispatch({
      type: "GetAllCompteProRequest",
    });
    const { data } = await axios.get(
      `${server}/supAdmin/load-cabinets`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "GetAllCompteProSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "GetAllCompteProFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Update info cabinet et admin
export const updateInfoCabinet =
  (
    cabId,
    {
      name,
      cabinetPhone,
      emailCabinet,
      dirigeantCabinetName,
      secteur,
      rc,
      ice,
      email,
      adresse,
      moreInfo,
    }
  ) =>
  async (dispatch) => {
    try {
      //console.log("req from update cabinet admin info");
      //console.log(cabId);

      dispatch({
        type: "UpdateInfoCabinetRequest",
      });

      const { data } = await axios.post(
        `${server}/supAdmin/update-cabinet-admin/${cabId}`,
        {
          name,
          cabinetPhone,
          emailCabinet,
          dirigeantCabinetName,
          secteur,
          rc,
          ice,
          email,
          adresse,
          moreInfo,
        },

        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "UpdateInfoCabinetSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      //console.log(error);
      dispatch({
        type: "UpdateInfoCabinetFail",
        payload: error.response?.data.message,
      });
    }
  };

// Update a  cabinet state and all collaborators and all clients
export const updateCabinetState =
  (cabId, { active }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UpdateCabinetStatelRequest",
      });

      const { data } = await axios.post(
        `${server}/supAdmin/update-activation-cabinet/${cabId}`,
        { active },

        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "UpdateCabinetStateSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "UpdateCabinetStateFail",
        payload: error?.response?.data?.message,
      });
    }
  };

// Create a  Arborescence
export const addArborescenceCabinet =
  (cabId, { name, dossiersParents }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "AddArborescenceRequest",
      });

      const { data } = await axios.post(
        `${server}/supAdmin/create-arbo/${cabId}`,
        { name, dossiersParents: JSON.stringify(dossiersParents) },

        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "AddArborescenceSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "AddArborescenceFail",
        payload: error?.response?.data?.message,
      });
    }
  };

// get All arborescence per cabinet
export const loadArboPerCabinet = (cabId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetArboRequest",
    });
    const { data } = await axios.get(
      `${server}/supAdmin/load-arborescences/${cabId}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "GetArboSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "GetArboFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Delete a specific arborescence
export const deleteAnArbo = (cabId) => async (dispatch) => {
  try {
    dispatch({
      type: "DeleteArboRequest",
    });
    const { data } = await axios.delete(
      `${server}/supAdmin/delete-arbo/${cabId}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "DeleteArboSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "DeleteArboFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Create a  menu client
export const addMenuClient =
  (cabId, { name, categories }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "AddMenuRequest",
      });

      const { data } = await axios.post(
        `${server}/supAdmin/create-menu/${cabId}`,
        { name, categories: JSON.stringify(categories) },

        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "AddMenuSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "AddMenuFail",
        payload: error?.response?.data?.message,
      });
    }
  };

// get All menu per cabinet
export const loadMenuPerCabinet = (menuId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetMenuRequest",
    });
    const { data } = await axios.get(
      `${server}/supAdmin/get-menu/${menuId}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "GetMenuSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "GetMenuFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Delete a specific menu
export const deleteMenu = (cabId) => async (dispatch) => {
  try {
    dispatch({
      type: "DeleteMenuRequest",
    });
    const { data } = await axios.delete(
      `${server}/supAdmin/delete-menu/${cabId}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "DeleteMenuSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "DeleteMenuFail",
      payload: error?.response?.data?.message,
    });
  }
};
