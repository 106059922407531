import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AiFillFileAdd,
  AiFillFolderAdd,
  AiFillHome,
  AiFillFileText,
  AiOutlineFileWord,
} from "react-icons/ai";
import { HiSearch, HiXCircle } from "react-icons/hi";
import {
  FaEllipsisH,
  FaFilePdf,
  FaRegFilePowerpoint,
  FaFileExcel,
} from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi";
import { BsSlashLg } from "react-icons/bs";
import { Modal, Button, Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { PDFDocument } from "pdf-lib";
import { useDispatch, useSelector } from "react-redux";

import { uploadFiles } from "../../redux/actions/fileAction";
import { createFolder } from "../../redux/actions/folderAction";
import Loader from "../../Utils/Loader";

const CabinetHeaderPath = ({
  dossierClient,
  folderFile,
  setOpenedFile,
  getUpdated,
  collaborateurInfo,
  isOpen,
  toggleSidebar,
  searchText,
  setSearchText,
}) => {
  const { id, folderFileId } = useParams();
  const [addFoldarModal, setAddFoldarModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [folderDescription, setFolderDescription] = useState("");
  const [showDropzone, setShowDropzone] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState();
  const [isLoadingFolders, setIsLoadingFolders] = useState();
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const [showDuplicateModals, setShowDuplicateModals] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [isCompressing, setIsCompressing] = useState(false);

  const dispatch = useDispatch();

  const clearSearch = () => {};

  //console.log("folderFile", folderFile);

  const res = useSelector((state) => state.files);
  const resFolder = useSelector((state) => state.folders);
  //console.log("resFolderCreate", resFolder);

  // upload files

  const createdBy = collaborateurInfo?.name;

  const handleOpenDropzone = () => {
    if (
      folderFile?.type === "folder" &&
      folderFile?.parentId?.length <= 1 &&
      folderFile?.name === "Documents Client"
    ) {
      return alert(
        'Vous ne pouvez pas ajouter de fichiers dans "documents client" '
      );
    }
    setShowDropzone(true);
  };

  const handleCloseDropzone = () => {
    setShowDropzone(false);
    setFiles([]);
  };

  const onDrop = async (acceptedFiles) => {
    const updatedFiles = acceptedFiles.filter((file) => {
      const isDuplicate = files.some(
        (existingFile) => existingFile.name === file.name
      );
      if (isDuplicate) {
        toast.warn(`Le fichier '${file.name}' a déjà été ajouté.`);
      }
      return !isDuplicate;
    });

    try {
      const compressedFiles = await Promise.all(
        updatedFiles.map(async (file) => {
          if (file.type.startsWith("image/")) {
            setIsCompressing(true);
            // Compress the image
            const compressedImage = await imageCompression(file, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
            });

            setIsCompressing(false);
            // Create a new file object with the compressed image
            return new File([compressedImage], file.name, {
              type: compressedImage.type,
              lastModified: Date.now(),
            });
          } else if (file.type === "application/pdf") {
            // Compress the PDF
            setIsCompressing(true);
            const compressedPDF = await compressPDF(file);
            setIsCompressing(false);
            // Create a new file object with the compressed PDF
            return new File([compressedPDF], file.name, {
              type: "application/pdf",
              lastModified: Date.now(),
            });
          } else {
            return file;
          }
        })
      );

      // Create URLs of previews using the compressed files
      const updatedFilesWithPreview = compressedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setFiles((prevFiles) => [...prevFiles, ...updatedFilesWithPreview]);
    } catch (error) {
      console.error("Error compressing files:", error);
      toast.error(
        "Une erreur s'est produite lors de la compression des fichiers."
      );
    }
  };

  const compressPDF = async (file) => {
    try {
      const pdfDoc = await PDFDocument.load(await file.arrayBuffer());

      // Perform the PDF compression here using pdf-lib
      // ...

      // Serialize the modified PDF
      const compressedPDFBytes = await pdfDoc.save();

      return compressedPDFBytes;
    } catch (error) {
      console.error("Error compressing PDF:", error);
      throw error;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  useEffect(() => {
    setDuplicateFiles(
      files.filter((file) =>
        folderFile.files.some(
          (existingFile) =>
            existingFile.name.toLowerCase() === file.name.toLowerCase()
        )
      )
    );
    //console.log("filess", files);
  }, [files]);

  const handleReplaceFile = async (file, index) => {
    if (duplicateFiles.length > 1) {
      const updatedDuplicateFiles = duplicateFiles.filter(
        (existingFile) => existingFile.name !== file.name
      );
      setDuplicateFiles(updatedDuplicateFiles);
      setShowDuplicateModals(false);
    } else {
      setIsLoadingFiles(true);
      setShowLoadingModal(true);
      const newForm = new FormData();
      files?.forEach((file) => {
        newForm.append("files", file);
      });

      newForm.append("createdBy", createdBy);
      newForm.append("importedBy", "Collaborateur");
      setShowDropzone(false);

      setShowDuplicateModals(false);
      await dispatch(uploadFiles(id, folderFileId, newForm));
      setFiles([]);
      getUpdated("yes");
      setIsLoadingFiles(false);
      setShowLoadingModal(false);
    }
  };

  const handleCancelImport = (fileToRemove) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file !== fileToRemove);
      return updatedFiles;
    });

    setShowDuplicateModals(false);
  };

  const handleUploadFiles = async () => {
    if (files.length === 0) {
      return toast.warning("Merci de d'importer au moins un fichier");
    }
    if (duplicateFiles.length > 0) {
      setShowDuplicateModals(true);
    } else {
      setIsLoadingFiles(true);
      setShowLoadingModal(true);
      const newForm = new FormData();
      files?.forEach((file) => {
        newForm.append("files", file);
      });

      newForm.append("createdBy", createdBy);
      newForm.append("importedBy", "Collaborateur");
      setShowDropzone(false);

      await dispatch(uploadFiles(id, folderFileId, newForm));
      setFiles([]);
      getUpdated("yes");
      setIsLoadingFiles(false);
      setShowLoadingModal(false);
    }
  };

  useEffect(() => {
    if (isLoadingFiles === false) {
      if (res.files && res.files?.success) {
        toast.success(`Vous avez ajouté ${res.files.nbrOfFiles} fichier(s)`);
      } else if (res.error) {
        toast.warn(res.error);
      }
    }
  }, [isLoadingFiles]);

  // create folder

  const handleAddFolder = () => {
    if (
      folderFile?.type === "folder" &&
      folderFile?.parentId?.length <= 1 &&
      folderFile?.name === "Documents Client"
    ) {
      return alert(
        'Vous ne pouvez pas créer de dossiers dans "documents client" '
      );
    }
    setAddFoldarModal(true);
  };
  const closeModal = () => {
    setAddFoldarModal(false);
    setFolderName("");
  };
  const handleUpdateOpenFile = () => {
    setOpenedFile([dossierClient?.pathById]);
  };
  const handleCreateFolder = async () => {
    if (folderName === "") {
      return toast.warning("Merci de donner un nom au dossier");
    }

    setIsLoadingFolders(true);
    setShowLoadingModal(true);
    await dispatch(
      createFolder(id, folderFileId, folderName, folderDescription, createdBy)
    );
    setFolderName("");
    getUpdated("yes");
    setAddFoldarModal(false);
    setIsLoadingFolders(false);
    setShowLoadingModal(false);
  };
  //console.log("resFolder", resFolder);
  useEffect(() => {
    if (isLoadingFolders === false) {
      //console.log("resFolder 2", resFolder);

      if (resFolder.folder && resFolder.folder?.success && !resFolder.error) {
        toast.success(
          `Dossier ${resFolder.folder.folderObj.name} a été créée avec succès`
        );
      } else if (resFolder.error) {
        toast.warn(resFolder.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [isLoadingFolders]);

  return (
    <div className="800px:flex  items-center justify-between  pt-3 sticky bg-[#fff]  800px:border-b-0 border-b-2 border-gray-200 top-[70px]  z-30 px-6   ">
      <div className=" flex  items-center   mb-2 800px:mb-0">
        <div className="flex items-center flex-wrap ">
          <Link
            to="/clients"
            className="flex text-blue-600 mr-1 hover:text-blue-900"
          >
            <AiFillHome className="mr-1" size={20} />
            <span>Clients</span>
          </Link>
          <BsSlashLg color="rgb(59 130 246)" />
          <div className="flex items-center space-x-1 text-blue-500 ">
            <Link
              to={`/client/${id}`}
              className="ml-1"
              onClick={handleUpdateOpenFile}
            >
              {dossierClient?.name.length > 10
                ? dossierClient?.name.substring(0, 10) + "..."
                : dossierClient?.name}
            </Link>
            {folderFile?.parentId?.length > 1 && folderFileId && (
              <div className="flex items-center text-gray-500  space-x-1">
                <BsSlashLg color="rgb(59 130 246)" /> <FaEllipsisH size={12} />
              </div>
            )}
          </div>
          {folderFileId && folderFile && (
            <div className="flex items-center">
              <BsSlashLg className=" mx-1 " color="rgb(59 130 246)" />
              <span className="text-gray-500 "> {folderFile?.name} </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between">
        <div className=" flex items-center  ">
          {folderFileId && folderFile && (
            <div className="flex  items-center space-x-3">
              <div className="800px:flex hidden items-center relative">
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Rechercher..."
                  className="py-2 pl-8 mr-6 border-b bg-inherit border-gray-500 800px:text-base text-sm 800px:w-full"
                />
                {searchText ? (
                  <div
                    className="absolute left-2 cursor-pointer"
                    onClick={() => setSearchText("")}
                  >
                    <HiXCircle size={18} className="text-gray-500" />
                  </div>
                ) : (
                  <div className="absolute left-2">
                    <HiSearch size={18} className="text-gray-500" />
                  </div>
                )}
              </div>
              <div className="relative">
                {showAddFolder && (
                  <span className="absolute bottom-[-50px] w-auto left-1/2 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                    Nouveau Dossier
                  </span>
                )}
                <AiFillFolderAdd
                  className="cursor-pointer"
                  size={28}
                  color="rgb(59 130 246)"
                  onClick={handleAddFolder}
                  onMouseOver={() => setShowAddFolder(true)}
                  onMouseLeave={() => setShowAddFolder(false)}
                />
              </div>
              <div className="relative">
                {showAdd && (
                  <span className="absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                    Ajouter
                  </span>
                )}
                <AiFillFileAdd
                  className="cursor-pointer"
                  size={23}
                  color="rgb(59 130 246)"
                  onClick={handleOpenDropzone}
                  onMouseOver={() => setShowAdd(true)}
                  onMouseLeave={() => setShowAdd(false)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="800px:hidden block text-end  px-2 w-[50px]   ">
          {isOpen ? (
            <FiX
              onClick={toggleSidebar}
              className="cursor-pointer text-center "
              color="blue"
              size={24}
            />
          ) : (
            <FiMenu
              onClick={toggleSidebar}
              size={24}
              className="cursor-pointer text-center "
              color="blue"
            />
          )}
        </div>
      </div>

      <Modal show={addFoldarModal} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title className="text-center">
            <h7 className="text-[16px] font-bold">
              Créer un sous-dossier du {folderFile?.name}
            </h7>{" "}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModal}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="folderName">
              <Form.Label>
                Nom du dossier <span className="text-red-500">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Donner un nom à ce dossier"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                className="mb-4"
              />
              <Form.Label>Description du dossier</Form.Label>
              <Form.Control
                as="textarea"
                rows={3} // Nombre de lignes du textarea (3 lignes dans cet exemple)
                placeholder="Donner une description à ce dossier"
                value={folderDescription}
                onChange={(e) => setFolderDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setAddFoldarModal(false)}
          >
            Annuler
          </Button>
          <Button className="bg-blue-500" onClick={handleCreateFolder}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDropzone} onHide={handleCloseDropzone}>
        <Modal.Header>
          <Modal.Title className="text-[16px] font-bold">
            Ajouter des fichiers dans {folderFile?.name}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={handleCloseDropzone}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              {...getRootProps({ className: "dropzone" })}
              className="flex items-center justify-center flex-col text-center cursor-pointer"
            >
              <input {...getInputProps()} />

              <Button className="bg-blue-500 rounded-xl py-3 px-3 my-3">
                {isCompressing ? "En cours..." : "Télécharger un fichier"}
              </Button>
              <p>
                Glissez et déposez des fichiers ici, ou cliquez pour
                sélectionner des fichiers
              </p>
            </div>

            <div className="flex flex-wrap text-center justify-center mt-2">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  {file.type === "application/pdf" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <FaFilePdf size={25} color={"red"} />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : file.type.startsWith("image/") ? (
                    <>
                      <img
                        src={file.preview}
                        alt="file"
                        className="w-[60px] h-[50px]"
                      />
                      <span
                        className="remove-file cursor-pointer"
                        onClick={() => removeFile(index)}
                      >
                        &times;
                      </span>
                    </>
                  ) : file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    file.type === "application/vnd.ms-excel" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <FaFileExcel size={25} color="#217346" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <AiOutlineFileWord size={25} color="#1A73E8" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : file.type ===
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                    file.type === "application/vnd.ms-powerpoint" ||
                    file.type === "application/octet-stream" ? (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <FaRegFilePowerpoint size={25} color="#D24726" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" w-[30px] h-[30px]  flex justify-center">
                        <AiFillFileText size={25} />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px]">
                          {file?.name?.length > 6
                            ? file.name.substring(0, 6) + ".."
                            : file.name}
                        </span>
                        <span
                          className="remove-file cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          &times;
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={handleCloseDropzone}
          >
            Annuler
          </Button>

          {isCompressing ? (
            <Button className="bg-blue-300">En cours</Button>
          ) : (
            <Button className="bg-blue-500" onClick={handleUploadFiles}>
              Ajouter
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {showDuplicateModals &&
        duplicateFiles.map((file, index) => (
          <Modal
            show={showDuplicateModals}
            key={file.name}
            onClose={() => {
              const updatedShowModals = [...showDuplicateModals];
              updatedShowModals[index] = false;
              setShowDuplicateModals(updatedShowModals);
            }}
            dialogClassName="max-w-[700px] top-1/4"
            className=" px-2 bg-transparent "
          >
            <Modal.Title className="px-2 py-2">Fichier existant</Modal.Title>

            <p className="text-center px-4">
              Le fichier " <span className="font-bold">{file.name}</span>"
              existe déjà. Voulez-vous le remplacer ?
            </p>
            <div className="flex justify-end space-x-2 px-3 py-3">
              <Button
                className="bg-gray-500 rounded-xl py-3 px-3 my-3 hover:bg-gray-700"
                onClick={() => handleCancelImport(file)}
              >
                Annuler l'importation
              </Button>
              <Button
                className="bg-blue-500 rounded-xl py-3 px-3 my-3"
                onClick={() => handleReplaceFile(file, index)}
              >
                Remplacer le fichier
              </Button>
            </div>
          </Modal>
        ))}
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </div>
  );
};

export default CabinetHeaderPath;
