import React, { useEffect, useState } from "react";

const CollaborateurProtectedRoute = () => {
  const [collaborateurData, setCollaborateurData] = useState(
    JSON.parse(localStorage.getItem("collaborateur"))
  );

  //console.log("collaborateurData", collaborateurData);
  //console.log("collaborateurDataRole", collaborateurData?.user.role);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isCollaborateur, setIsCollaborateur] = useState();
  const [isManager, setIsManager] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [active, setActive] = useState();

  useEffect(() => {
    if (collaborateurData != null) {
      setIsAuthenticated(true);
      if (collaborateurData?.user.active === true) {
        setActive(true);
        if (collaborateurData?.user.role === "Collaborateur") {
          setIsCollaborateur(true);
          setIsAdmin(false);
          setIsManager(false);
        } else if (collaborateurData?.user.role === "Manager") {
          setIsCollaborateur(false);
          setIsAdmin(false);
          setIsManager(true);
        } else if (collaborateurData?.user.role === "Admin") {
          setIsCollaborateur(false);
          setIsAdmin(true);
          setIsManager(false);
        }
      } else {
        setActive(false);
      }
    } else {
      setIsAuthenticated(false);
      setIsCollaborateur(false);
      setIsAdmin(false);
      setIsManager(false);
    }
  }, []);

  return [
    isAuthenticated,
    active,
    isCollaborateur,
    isManager,
    isAdmin,
    collaborateurData,
  ];
};

export default CollaborateurProtectedRoute;
