import React from "react";
import { Link , useParams} from "react-router-dom";
import { AiOutlineCluster } from "react-icons/ai";
import { BsMenuButtonWide } from "react-icons/bs";

const SupAdminSideBar = ({ active }) => {

  const {cabId} = useParams()
  return (
    <div className="w-full  bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to={`/supAdmin/${cabId}/arborescence`} className="w-full flex items-center">
          <AiOutlineCluster
            size={30}
            color={`${active === 1 ? "#4b93f0" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[#4b93f0]" : "text-[#555]"
            }`}
          >
            Arborescences
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to={`/supAdmin/${cabId}/menuClient`} className="w-full flex items-center">
          <BsMenuButtonWide
            size={30}
            color={`${active === 2 ? "#4b93f0" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[#4b93f0]" : "text-[#555]"
            }`}
          >
            Menu CRM
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default SupAdminSideBar;
