import React from 'react'
import ChangePassword from "../../Components/ComptableComponents/ChangePassword.jsx";
const ChangePasswordPage = () => {
  return (
    <div>
    <ChangePassword userType={"pro"}/>
    </div>
  )
}

export default ChangePasswordPage
