export const server = "https://optiwise-app-api.onrender.com/api/v2";

export const backend_url = "https://optiwise-app-api.onrender.com/";
export const backend_url_files =
  "https://optiwise-app-api.onrender.com/get-dossier-client/";
export const backend_url_file = "https://optiwise-app-api.onrender.com/images/";




// export const server = "http://localhost:8000/api/v2";


// export const backend_url = "http://localhost:8000/";
// export const backend_url_files = "http://localhost:8000/get-dossier-client/";
// export const backend_url_file = "http://localhost:8000/images/";