import { React, useState } from "react";
import { FaArrowLeft, FaCopy, FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { geCollaborateursPerCabinet } from "../../redux/actions/collaborateurAction";
import {
  loadArboPerCabinet,
  loadMenuPerCabinet,
} from "../../redux/actions/supAdmin";

const RegisterClient1 = () => {
  const [name, setName] = useState("");
  const [societePhone, setSocietePhone] = useState("");
  const [dirigeantName, setDirigeantName] = useState("");
  const [adressePro, setAdressePro] = useState("");
  const [emailConnexion, setEmailConnexion] = useState("");
  const [password, setPassword] = useState("");
  const [secteur, setSecteur] = useState("");
  const [rc, setRc] = useState("");
  const [ice, setIce] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cin, setCin] = useState("");
  const [poste, setPoste] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [page, setPage] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const [responsableDossier, setResponsableDossier] = useState("");
  const [isLoadingAllColaborateurs, setIsLoadingAllColaborateurs] = useState();
  const [collaborateursList, setCollaborateursList] = useState([]);
  const [arborescence, setArborescence] = useState("");
  const [dossiersParents, setDossiersParents] = useState([]);
  const [menuClient, setMenuClient] = useState("");
  const [categories, setCategories] = useState([]);
  

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const res = useSelector((state) => state.collaborateur);
  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));
  const arbos = useSelector((state) => state.supAdmin);


  const getArbos = async (cabId) => {
    await dispatch(loadArboPerCabinet(cabId));
  };
  const getMenus = async (cabId) => {
    await dispatch(loadMenuPerCabinet(cabId));
  };

  useEffect(() => {
    if (collaborateurStore?.user?.cabinet) {
      getArbos(collaborateurStore?.user?.cabinet);
      getMenus(collaborateurStore?.user?.cabinet);
      allCollaborateurs(collaborateurStore?.user?.cabinet);
    }
  }, []);

  useEffect(() => {
    if (menuClient !== "" && arbos?.menus?.data) {
      const selectedMenu = arbos.menus.data.find(
        (mnu) => mnu._id === menuClient
      );
      if (selectedMenu) {
        setCategories(selectedMenu?.menu);
      }
    } else if (menuClient === "") {
      setCategories([]);
    }
  }, [menuClient, arbos?.menus]);

  useEffect(() => {
    if (arborescence !== "" && arbos?.arbos?.data) {
      const selectedArbo = arbos.arbos.data.find(
        (arb) => arb._id === arborescence
      );
      if (selectedArbo) {
        setDossiersParents(selectedArbo.dossiersParents);
      }
    } else if (arborescence === "") {
      setDossiersParents([]);
    }
  }, [arborescence, arbos?.arbos]);

  useEffect(() => {
    if (isLoadingAllColaborateurs === false) {
      if (
        res &&
        res.allCollaborateursCabinet &&
        res.allCollaborateursCabinet.success === true &&
        res.allCollaborateursCabinet.data
      )
        setCollaborateursList(res?.allCollaborateursCabinet?.data);
    }
  }, [isLoadingAllColaborateurs]);

  const allCollaborateurs = async (cabId) => {
    setIsLoadingAllColaborateurs(true);
    await dispatch(geCollaborateursPerCabinet(cabId));
    setIsLoadingAllColaborateurs(false);
  };

  const handleCopyPassword = () => {
    const passwordInput = document.getElementById("passwordInput");
    passwordInput.select();
    document.execCommand("copy");
    setIsCopied(true); // Mettre à jour l'état pour indiquer que le code est copié
    // Réinitialiser l'état après quelques secondes (facultatif)
    setTimeout(() => setIsCopied(false), 5000); // Remettre à false après 3 secondes
  };

  useEffect(() => {
    //console.log("responsableDossier", responsableDossier);
  }, [responsableDossier]);

  const isNextButtonActive = () => {
    if (page === 1) {
      return dirigeantName !== "" && phoneNumber !== "" && email !== "";
    } else if (page === 2) {
      return name !== "" && societePhone !== "";
    } else if (page === 3) {
      return (
        dossiersParents?.length > 0 &&
        responsableDossier !== "Affecter un collaborateur à ce dossier" &&
        responsableDossier !== ""
      );
    } else if (page === 4) {
      return categories?.length > 0;
    } else if (page === 5) {
      return emailConnexion !== "" && password !== "";
    }
    return false;
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    const nameslice = name.slice(0, 2);

    setPassword(nameslice + randomPassword + nameslice);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      {
        name: "dirigeantName",
        label: "Nom et prénom du Gérant (Interlocuteur)",
      },
      { name: "phoneNumber", label: "Numéro de téléphone" },
      { name: "email", label: "Email" },
      { name: "name", label: "Nom du cabinet" },
      { name: "societePhone", label: "Numéro de téléphone de la société" },
      { name: "emailConnexion", label: "Email de connexion" },
      { name: "password", label: "Mot de passe" },
      { name: "responsableDossier", label: "Responsable du dossier" },
      { name: "menuClient", label: "Menu Inerface Client" },
    ];

    // Créez un objet pour stocker les valeurs des champs
    const formValues = {
      dirigeantName,
      phoneNumber,
      email,
      name,
      societePhone,
      emailConnexion,
      password,
      responsableDossier,
      menuClient,
    };

    // Filtrer les champs vides et récupérer les labels des champs vides
    const emptyFields = requiredFields
      .filter((field) => !formValues[field.name])
      .map((field) => field.label);

    if (emptyFields?.length > 0) {
      // console.log(
      //   "Merci de remplir les informations obligatoires : ",
      //   emptyFields.join(", ")
      // );
      // Afficher une alerte avec les champs vides
      alert(
        "Merci de remplir les informations obligatoires : " +
          emptyFields.join(", ")
      );
      return;
    }
    if (dossiersParents?.length === 0) {
      alert("Merci de sélectionner une arborescence des dossiers racines");
      return;
    }
    if (collaborateurStore === null) {
      alert(
        "Il y a un problème dans la creation d'un nouveau client. Merci de vous reconnecter et refaire ces étapes"
      );
      return;
    }

    await axios
      .post(
        `${server}/client/create-dossier-client`,
        {
          name,
          societePhone,
          dirigeantName,
          adressePro,
          emailConnexion,
          password,
          secteur,
          rc,
          ice,
          email,
          phoneNumber,
          cin,
          poste,
          moreInfo,
          dossiersParents: JSON.stringify(dossiersParents),
          responsableDossier,
          menuClient,
          cabinetId: collaborateurStore?.user?.cabinet,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        //console.log(res);
        if (res && res.status === 200 && res.data?.data?.name) {
          toast.success(
            `le compte et le dossier client ${res.data.data.name} a bien été créée`
          );
          setEmailConnexion("");
          setPassword("");
          navigate("/clients");
        }
      })
      .catch((error) => {
        //console.log(error);
        alert(
          error.response?.data?.message || error.response?.data?.errors[0].msg
        );
      });
      
  };

  return (
    <div className="h-screen bg-gray-50">
      <div className="flex flex-col items-center justify-center pt-10 bg-gray-50 sticky z-10">
        <div className="flex items-center">
          <div
            className={`${
              page === 1 || 2 || 3 || 4 ? "bg-[#355ee2]  " : "bg-gray-500 "
            }w-14 h-14 rounded-full  flex items-center justify-center text-white font-bold`}
          >
            1
          </div>

          <div className="800px:w-10 w-5 h-1 bg-[#90a4e6] "></div>
          <div
            className={`${
              page > 1 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            2
          </div>

          <div className="800px:w-10 w-5 h-1 bg-[#90a4e6]"></div>
          <div
            className={`${
              page > 2 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            3
          </div>
          <div className="800px:w-10 w-5 h-1 bg-[#90a4e6]"></div>
          <div
            className={`${
              page > 3 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            4
          </div>
          <div className="800px:w-10 w-5 h-1 bg-[#90a4e6]"></div>
          <div
            className={`${
              page === 5 ? "bg-[#355ee2] " : "bg-gray-500 "
            }w-14 h-14 rounded-full flex items-center justify-center text-white font-bold`}
          >
            5
          </div>
        </div>
      </div>
      <div className="  flex flex-col justify-center pt-2 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-2">
          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
            Nouveau client
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl ">
          <div className="bg-white pb-10 pt-4 px-4 shadow sm:rounded-lg sm:px-10">
            {page === 1 && (
              <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
                Informations personnelles
              </h1>
            )}

            {page === 2 && (
              <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
                <FaArrowLeft
                  onClick={() => setPage(page - 1)}
                  className="cursor-pointer"
                />
                Informations professionnelles
              </h1>
            )}

            {page === 3 && (
              <div className="mb-6">
                <h1 className=" mb-1 text-center text-2xl font-bold text-gray-900">
                  <FaArrowLeft
                    onClick={() => setPage(page - 1)}
                    className="cursor-pointer"
                  />
                  Architecture des dossiers
                </h1>
                <p className="text-sm text-gray-600 text-center italic">
                  Merci de sélectionner une arborescence{" "}
                  <span className="text-red-500">*</span> <br /> des dossiers
                  parents pour ce dossier client.{" "}
                </p>
              </div>
            )}

            {page === 4 && (
              <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
                <FaArrowLeft
                  onClick={() => setPage(page - 1)}
                  className="cursor-pointer"
                />
                Menu Inerface Client
              </h1>
            )}
            {page === 5 && (
              <h1 className="mb-6 text-center text-2xl font-bold text-gray-900">
                <FaArrowLeft
                  onClick={() => setPage(page - 1)}
                  className="cursor-pointer"
                />
                Informations de connexion du client
              </h1>
            )}

            <form className="space-y-6" onSubmit={handleSubmit}>
              {page === 1 && (
                <>
                  <div className="800px:mr-2">
                    <label
                      htmlFor="nom"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nom et prénom du Gérant (Interlocuteur)
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 ">
                      <input
                        type="text"
                        name="text"
                        value={dirigeantName}
                        onChange={(e) => setDirigeantName(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="800px:mr-4">
                    <label
                      htmlFor="CIN"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Numéro CIN
                    </label>
                    <div className="mt-1 ">
                      <input
                        type="text"
                        name="CIN"
                        value={cin}
                        onChange={(e) => setCin(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="800px:flex  ">
                    <div className="800px:mr-4">
                      <label
                        htmlFor="poste"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Poste dans la société
                      </label>
                      <div className="mt-1 800px:w-[250px]">
                        <input
                          type="text"
                          name="poste"
                          value={poste}
                          onChange={(e) => setPoste(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numéro de téléphone
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 800px:w-[250px]">
                        <input
                          type="tel"
                          name="phone"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adresse mail
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </>
              )}
              {page === 2 && (
                <>
                  <div className="800px:mr-2">
                    <label
                      htmlFor="nom"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nom de la société
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 ">
                      <input
                        type="text"
                        name="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="800px:flex justify-between">
                    <div className="800px:mr-4">
                      <label
                        htmlFor="secteur"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Secteur d'activité
                      </label>
                      <div className="mt-1 800px:w-[250px]">
                        <input
                          type="text"
                          name="secteur"
                          value={secteur}
                          onChange={(e) => setSecteur(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Téléphone société
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 800px:w-[250px]">
                        <input
                          type="text"
                          name="phone"
                          value={societePhone}
                          onChange={(e) => setSocietePhone(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="800px:flex justify-between">
                    <div className="800px:mr-4">
                      <label
                        htmlFor="rc"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numéro RC
                      </label>
                      <div className="mt-1 800px:w-[250px]">
                        <input
                          type="text"
                          name="rc"
                          value={rc}
                          onChange={(e) => setRc(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="ice"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numéro ICE
                      </label>
                      <div className="mt-1 800px:w-[250px]">
                        <input
                          type="text"
                          name="ice"
                          value={ice}
                          onChange={(e) => setIce(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="adressePro"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Siège social
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="adressePro"
                        value={adressePro}
                        onChange={(e) => setAdressePro(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="moreInfo"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Informations supplémentaires
                    </label>
                    <div className="mt-1">
                      <textarea
                        type="text"
                        name="moreInfo"
                        value={moreInfo}
                        onChange={(e) => setMoreInfo(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </>
              )}

              {page === 3 && (
                <div className="container mx-auto text-center mt-8">
                  <div className="flex flex-wrap mt-4 items-center">
                    <label
                      htmlFor="Arborescence"
                      className="block w-[170px] text-sm text-left font-medium text-gray-700"
                    >
                      Arborescence <span className="text-red-500">*</span>
                    </label>

                    <div className="mt-1 flex items-center justify-center">
                      <select
                        name="Arborescence"
                        value={arborescence}
                        onChange={(event) =>
                          setArborescence(event.target.value)
                        }
                        className="block ml-2 w-[250px]  cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value="" className="text-sm w-[200px]">
                          Selectionner une arborescence
                        </option>
                        {arbos?.arbos?.data.map((arbo) => (
                          <>
                            <option
                              key={arbo._id}
                              value={arbo._id}
                              className="text-sm w-[200px]"
                            >
                              {arbo.name}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    {arborescence !== null && dossiersParents?.length > 0 && (
                      <div>
                        {dossiersParents.map((dos, index) => (
                          <div
                            key={index}
                            className="flex flex-wrap mt-1 items-center"
                          >
                            <label
                              htmlFor="dossiersParents"
                              className="block w-[170px] pl-3 text-sm italic text-left font-medium text-gray-700"
                            >
                              Dossier racine {index + 1}{" "}
                            </label>
                            <span className="block ml-2 w-[230px] italic px-2 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              {dos}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-wrap mt-4 items-center">
                    <label
                      htmlFor="responsableDossier"
                      className="block w-[170px] text-sm text-left font-medium text-gray-700"
                    >
                      Responsable du dossier{" "}
                      <span className="text-red-500">*</span>
                    </label>

                    <div className="mt-1 flex items-center justify-center">
                      <select
                        name="responsableDossier"
                        value={responsableDossier}
                        onChange={(event) =>
                          setResponsableDossier(event.target.value)
                        }
                        className="block ml-2 w-[250px]  cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value="">
                          Affecter un collaborateur à ce dossier
                        </option>
                        {collaborateursList?.map((collaborateur) => (
                          <option
                            key={collaborateur._id}
                            value={`${collaborateur.prenom} ${collaborateur.name}`}
                          >
                            {`${collaborateur.prenom} ${collaborateur.name}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {page === 4 && (
                <div className="container mx-auto text-center mt-8">
                  <div className="flex flex-wrap mt-4 items-center">
                    <label
                      htmlFor="MenuClient"
                      className="block w-[170px] text-sm text-left font-medium text-gray-700"
                    >
                      Menu Client <span className="text-red-500">*</span>
                    </label>

                    <div className="mt-1 flex items-center justify-center">
                      <select
                        name="menuClient"
                        value={menuClient}
                        onChange={(event) => setMenuClient(event.target.value)}
                        className="block ml-2 w-[250px]  cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value="" className="text-sm w-[200px]">
                          Selectionner un menu
                        </option>
                        {arbos?.menus?.data?.map((menu) => (
                          <>
                            <option
                              key={menu._id}
                              value={menu._id}
                              className="text-sm w-[200px]"
                            >
                              {menu.name}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    {menuClient !== null && categories?.length > 0 && (
                      <div>
                        {categories.map((cat, index) => (
                          <div
                            key={cat._id}
                            className="flex flex-wrap mt-1 items-center"
                          >
                            <label
                              htmlFor="categories"
                              className="block w-[170px] pl-3 text-sm italic text-left font-medium text-gray-700"
                            >
                              Categorie {index + 1}{" "}
                            </label>
                            <span className="block ml-2 w-[230px] italic px-2 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              {cat.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {page === 5 && (
                <>
                  <div className="">
                    <label
                      htmlFor="mailCompte"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adresse mail
                    </label>
                    <div className="mt-1 ">
                      <input
                        type="email"
                        name="mailCompte"
                        value={emailConnexion}
                        onChange={(e) => setEmailConnexion(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      onClick={generateRandomPassword}
                      className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded"
                    >
                      Générer un mot de passe
                    </button>
                  </div>

                  <div className="mt-4">
                    <label className="block font-medium text-gray-700">
                      Mot de passe à envoyer au client:
                    </label>
                    <div className="relative mt-1">
                      <input
                        type="text"
                        value={password}
                        placeholder="Cliquez sur 'Générer un mot de passe'"
                        onChange={(e) => setPassword(e.target.value)}
                        readOnly
                        id="passwordInput" // Assurez-vous que l'input a un ID pour la sélection
                        className="mt-1 p-2 pl-10 border border-gray-300 800px:text-base text-sm rounded w-full"
                      />
                      <button
                        type="button"
                        onClick={handleCopyPassword}
                        className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                      >
                        {isCopied ? <FaCheck /> : <FaCopy />}
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div>
                {page !== 5 && (
                  <div
                    className={`${
                      page === 1 ? "flex justify-end" : "flex justify-end"
                    }`}
                  >
                    {page === 1 && (
                      <button
                        onClick={() => setPage(page + 1)}
                        className={`bg-blue-${
                          isNextButtonActive() ? "500 hover:bg-blue-700" : "300"
                        } text-white font-bold py-2 px-4 rounded`}
                        disabled={!isNextButtonActive()} // Désactiver le bouton si le bouton n'est pas actif
                      >
                        Suivant
                      </button>
                    )}

                    {page === 2 && (
                      <button
                        onClick={() => setPage(page + 1)}
                        className={`bg-blue-${
                          isNextButtonActive() ? "500 hover:bg-blue-700" : "300"
                        } text-white font-bold py-2 px-4 rounded`}
                        disabled={!isNextButtonActive()} // Désactiver le bouton si le bouton n'est pas actif
                      >
                        Suivant
                      </button>
                    )}

                    {page === 3 && (
                      <button
                        onClick={() => setPage(page + 1)}
                        className={`bg-blue-${
                          isNextButtonActive() ? "500 hover:bg-blue-700" : "300"
                        } text-white font-bold py-2 px-4 rounded`}
                        disabled={!isNextButtonActive()} // Désactiver le bouton si le bouton n'est pas actif
                      >
                        Suivant
                      </button>
                    )}

                    {page === 4 && (
                      <button
                        onClick={() => setPage(page + 1)}
                        className={`bg-blue-${
                          isNextButtonActive() ? "500 hover:bg-blue-700" : "300"
                        } text-white font-bold py-2 px-4 rounded`}
                        disabled={!isNextButtonActive()} // Désactiver le bouton si le bouton n'est pas actif
                      >
                        Suivant
                      </button>
                    )}
                  </div>
                )}
                <div className="flex items-center justify-center">
                  {page === 5 && (
                    <button
                      type="submit"
                      className={`${
                        isNextButtonActive()
                          ? "bg-blue-600 hover:bg-blue-700"
                          : "bg-blue-300"
                      } text-white font-bold py-2 px-4 rounded w-80 `}
                      disabled={!isNextButtonActive()}
                    >
                      Valider
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterClient1;
