import axios from "axios";
import { server } from "../../server";

// in params : id and idFolder and files req, body : created by

export const createFolder =
  (id, folderFileId, folderName, folderDescription, createdBy, visible, tag) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "CreateFolderRequest",
      });
      //console.log("req create folder");

      const { data } = await axios.post(
        `${server}/client/create-folder/${id}/${folderFileId}`,

        {
          name: folderName,
          description: folderDescription,
          createdBy,
          visible,
          tag,
        },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "CreateFolderSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "CreateFolderFail",
        payload:
          error.response?.data?.message || error.response?.data?.errors[0].msg,
      });
    }
  };

export const renameFolder =
  (id, idFolderFile, name, modificatedBy, modificatedAt) =>
  async (dispatch) => {
    //console.log(name);
    try {
      dispatch({
        type: "RenameFolderRequest",
      });
      //console.log("req rename folder");

      const { data } = await axios.post(
        `${server}/client/renameFolder/${id}/${idFolderFile}`,

        { name, modificatedBy, modificatedAt },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "RenameFolderSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "RenameFolderFail",
        payload:
          error.response?.data?.message || error.response?.data?.errors[0].msg,
      });
    }
  };

export const moveFolderAction =
  (id, idFolderFile, idTo, modificatedBy, modificatedAt) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "MoveFolderRequest",
      });
      //console.log("req move file");

      const { data } = await axios.post(
        `${server}/client/moveFile/${id}/${idFolderFile}`,

        { idTo, modificatedBy, modificatedAt },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "MoveFolderSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "MoveFolderFail",
        payload:
          error.response?.data?.message || error.response?.data?.errors[0].msg,
      });
    }
  };

export const deleteFolderAction = (id, idFolderFile) => async (dispatch) => {
  try {
    dispatch({
      type: "DeleteFolderRequest",
    });
    //console.log("req delete folder");

    const { data } = await axios.post(
      `${server}/client/deleteFolder/${id}/${idFolderFile}`,
      {},

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "DeleteFolderSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "DeleteFolderFail",
      payload:
        error.response?.data?.message || error.response?.data?.errors[0].msg,
    });
  }
};
