import React from "react";
import { Link, useParams } from "react-router-dom";

const Sidebar = ({ isOpen, categories, getSelectedCategory }) => {
  const { catId } = useParams();

  //console.log("catId", catId);

  return (
    <div className="flex items-center  justify-between  sticky    z-30  ">
      <div className="800px:block hidden   ">
        <div className=" top-0 left-0 h-[78vh] w-[230px] flex flex-col  text-black  items-start justify-start ">
          {categories.length > 0 &&
            categories.map((cat) => (
              <Link
                to={`/espaceClient/${cat._id}`}
                onClick={() => getSelectedCategory(cat.name)}
                key={cat._id}
                className={` ${
                  catId === cat._id ? "bg-blue-200" : ""
                } cursor-pointer w-full border-t rounded-sm py-2 `}
              >
                <div>
                  <span className="px-4 ">{cat.name}</span>
                </div>
              </Link>
            ))}
        </div>
      </div>
      <div className="800px:hidden block  ">
        <div className=" block absolute  ">
          {isOpen ? (
            <div className=" top-0 left-0 h-[83vh] flex flex-col bg-white w-44  text-black  items-start justify-start  ">
              {categories.length > 0 &&
                categories.map((cat) => (
                  <Link
                    to={`/espaceClient/${cat._id}`}
                    key={cat._id}
                    className={` ${
                      catId === cat._id ? "bg-blue-200" : ""
                    } cursor-pointer w-full border-t rounded-sm py-2 `}
                  >
                    <div>
                      <span className="px-2 text-blue-900">{cat.name} </span>
                    </div>
                  </Link>
                ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
