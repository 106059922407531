import React from "react";
import NewPassword from "../../Components/UserComponents/NewPassword.jsx";

const CollaborateurNewPasswordPage = () => {
  return (
    <div>
      <NewPassword type={"collaborateur"} />
    </div>
  );
};

export default CollaborateurNewPasswordPage;
