import React, { useEffect, useState } from "react";
import { HiFolder } from "react-icons/hi";
import { CiFolderOn } from "react-icons/ci";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  FaCloudDownloadAlt,
  FaExchangeAlt,
  FaFilePdf,
  FaRegFilePowerpoint,
  FaFileExcel,
  FaUser,
} from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import {
  AiFillEdit,
  AiFillInfoCircle,
  AiOutlineFileWord,
  AiFillFileText,
  AiOutlineEyeInvisible,
  AiOutlineEye,
} from "react-icons/ai";
import { toast } from "react-toastify";

import { Worker } from "@react-pdf-viewer/core";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { backend_url_files } from "../../server";
import { backend_url_file } from "../../server";
import ArborescenceSideMove from "./ArborescenceSideMove";
import {
  moveFileAction,
  renameFile,
  deleteFileAction,
  changeCatFile,
} from "../../redux/actions/fileAction";
import Loader from "../../Utils/Loader";

import {
  moveFolderAction,
  renameFolder,
  deleteFolderAction,
} from "../../redux/actions/folderAction";
import { loadSpcificMenuClient } from "../../redux/actions/clientAction";

const DossierContentDetails = ({
  folderFile,
  openedFile,
  setOpenedFile,
  dossierClient,
  collaborateurInfo,
  getUpdated,
  searchText,
}) => {
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [clickedFile, setClickedFile] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [renameModalFolder, setRenameModalFolder] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [isThereContent, setIsThereContent] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [name, setName] = useState("");
  const [nameFolder, setNameFolder] = useState("");
  const [loadingRenameFolder, setLoadingRenameFolder] = useState();
  const [loadingRename, setLoadingRename] = useState();
  const [openedFileContent, setOpenedFileContent] = useState("");
  const [showConfirmMoveModal, setShowConfirmMoveModal] = useState(false);
  const [idFolderMoveTo, setIdFolderMoveTo] = useState(null);
  const [folderMoveTo, setFolderMoveTo] = useState(null);
  const [loadingMoveFile, setLoadingMoveFile] = useState();
  const [loadingMoveFolder, setLoadingMoveFolder] = useState();
  const [loadingDeleteFile, setLoadingDeleteFile] = useState();
  const [loadingDeleteFolder, setLoadingDeleteFolder] = useState();
  const [showTextHoverEdit, setShowTextHoverEdit] = useState(false);
  const [showTextHoverDownLoad, setShowTextHoverDownLoad] = useState(false);
  const [showTextHoverMove, setShowTextHoverMove] = useState(false);
  const [showTextHoverdelete, setShowTextHoverdelete] = useState(false);
  const [showTextHoverInfo, setShowTextHoverInfo] = useState(false);
  const [showTextHoverVisible, setShowTextHoverVisible] = useState(false);
  const [gettingMenuLoader, setGettingMenuLoader] = useState(false);
  const [categories, setCategories] = useState(false);
  const [showVisibilityInfoModal, setShowVisibilityInfoModal] = useState(false);
  const [rubrique, setRubrique] = useState("");
  const [rubriqueName, setRubriqueName] = useState("");
  const [
    showVisibleAndInvisibleAndChangeRubModal,
    setShowVisibleAndInvisibleAndChangeRubModal,
  ] = useState(false);
  const [modalType, setModalType] = useState({});

  const dispatch = useDispatch();
  const { id, folderFileId } = useParams();

  const fileRes = useSelector((state) => state.files);
  const folderRes = useSelector((state) => state.folders);

  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));

  useEffect(() => {
    if (folderFile?.files?.length === 0) {
      return setIsThereContent(false);
    } else {
      setIsThereContent(true);
    }
    handleFilFiles();
    handleFilFolders();
  }, [folderFile]);

  useEffect(() => {
    if (folderFile?.files?.length === 0) {
      return setIsThereContent(false);
    } else {
      setIsThereContent(true);
    }
    handleFilFiles();
    handleFilFolders();
  }, [searchText]);

  // searchText

  function isValidMongoID(id) {
    const mongoIDPattern = /^[0-9a-fA-F]{24}$/;
    return mongoIDPattern.test(id);
  }

  const closeRenameModal = () => {
    setRenameModal(false);
  };

  const handleCloseModalInfo = () => {
    if (renameModal !== false) {
      setRenameModal(false);
      setModalInfoOpen(false);
    } else if (renameModalFolder !== false) {
      setRenameModalFolder(false);
      setModalInfoOpen(false);
    }
    setModalInfoOpen(false);
  };

  const handleRename = () => {
    if (clickedFile?.type === "file") {
      setRenameModal(true);
    } else if (clickedFile?.type === "folder") {
      setRenameModalFolder(true);
    }
  };

  // folder actions

  ////// Rename Folder

  const closeRenameModalFolder = () => {
    setRenameModalFolder(false);
  };

  const handleFilFolders = () => {
    if (folderFile?.files) {
      const arrayFile = folderFile?.files?.filter(
        (file) =>
          file.type === "folder" &&
          file.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFolders(arrayFile);
    }
  };
  const handleOpenFolder = (folder) => {
    //console.log(folder._id);
    setOpenedFile((prevFolders) => [...prevFolders, folder?.pathById]);
  };

  const HandleRenameFolder = async () => {
    if (nameFolder === "") {
      return toast.warning("Merci de saisir le nom");
    }

    const isRootFolder = clickedFile?.parentId.length <= 1;
    //console.log("isRootFolder", isRootFolder);
    //console.log("nameFolder", nameFolder);

    if (isRootFolder) {
      return toast.warning(`Vous ne pouvez pas renommer un dossier racine`);
    }

    setLoadingRenameFolder(true);
    setShowLoadingModal(true);
    setRenameModalFolder(false);

    await dispatch(
      renameFolder(
        id,
        folderFileId,
        nameFolder,
        collaborateurInfo?.name,
        new Date()
      )
    );

    setNameFolder("");
    getUpdated("yes");
    setLoadingRenameFolder(false);
    setShowLoadingModal(false);
  };

  useEffect(() => {
    if (loadingRenameFolder === false) {
      if (
        folderRes &&
        folderRes?.folderRename?.success === true &&
        !folderRes.error
      ) {
        toast.success("Modification effectuée");
      } else if (folderRes && folderRes.error) {
        toast.warn(folderRes.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [loadingRenameFolder]);

  ////// Move Folder

  const handleCloseFile = () => {
    const filteredArray = openedFile?.filter(
      (item) => !item?.startsWith(clickedFile?.pathById)
    );

    setOpenedFile([...filteredArray]);
  };

  const handleMoveFolder = async () => {
    const isRootFolder = clickedFile?.parentId.length <= 1;
    if (isRootFolder) {
      return toast.warning(`Vous ne pouvez pas déplacer un dossier racine`);
    }
    //console.log("isRootFolder", isRootFolder);
    const isExistingInDestinationFolder = folderMoveTo?.files
      ?.filter((file) => file.type === "folder")
      .some((file) => file.name === clickedFile.name);

    if (isExistingInDestinationFolder) {
      setShowConfirmMoveModal(false);
      return toast.warning(
        `Ce nom ${clickedFile.name} est déjà existant dans ${folderMoveTo.name} !!!!`
      );
    }

    handleCloseFile();
    setLoadingMoveFolder(true);
    setShowLoadingModal(true);
    setShowConfirmMoveModal(false);
    setShowMoveModal(false);

    await dispatch(
      moveFolderAction(
        id,
        clickedFile?._id,
        idFolderMoveTo,
        collaborateurInfo?.name,
        new Date()
      )
    );
    getUpdated("yes");
    setLoadingMoveFolder(false);
    setShowLoadingModal(false);
  };

  useEffect(() => {
    if (loadingMoveFolder === false) {
      if (
        folderRes &&
        folderRes.movedFolder &&
        folderRes.movedFolder.success === true &&
        !folderRes.error
      ) {
        toast.success("Le dossier a bien été déplacé");
      } else if (folderRes?.error) {
        toast.warn(folderRes.error);
        dispatch({ type: "clearErrors" });
        //console.log(folderRes.error);
      }
    }
  }, [loadingMoveFolder]);

  /// delete folder

  const handleDeleteFolder = async () => {
    if (clickedFile?.parentId.length <= 1) {
      return alert("Vous ne pouvez pas supprimé un dossier racine");
    } else {
      await handleDeleteAction(deleteFolderAction);
    }
  };

  const handleDeleteAction = async (action) => {
    handleCloseFile();
    setLoadingDeleteFolder(true);
    setShowLoadingModal(true);
    setShowConfirmDeleteModal(false);

    await dispatch(action(id, clickedFile?._id));

    getUpdated("yes");
    setLoadingDeleteFolder(false);
    setShowLoadingModal(false);
  };

  useEffect(() => {
    if (loadingDeleteFolder === false) {
      if (
        folderRes &&
        folderRes.deletedFolder &&
        folderRes.deletedFolder.success === true &&
        folderRes?.deletedFolder?.data
      ) {
        toast.success(
          `le dossier ${folderRes.deletedFolder.data.name} a bien été supprimé`
        );
      } else if (folderRes && folderRes.error) {
        toast.warn(folderRes.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [loadingDeleteFolder]);

  // file actions

  // display Files
  const handleFilFiles = () => {
    if (folderFile?.files) {
      const arrayFile = folderFile?.files?.filter(
        (file) =>
          file.type === "file" &&
          file.name.toLowerCase().includes(searchText?.toLowerCase())
      );

      setFiles(arrayFile);
    }
  };

  // search

  const handleClickedFile = (selectedFile) => {
    setClickedFile(selectedFile);
    if (selectedFile?.type === "file") {
      setName(
        selectedFile?.name?.substring(0, selectedFile?.name.lastIndexOf("."))
      );
    } else if (selectedFile?.type === "folder") {
      setNameFolder(selectedFile?.name);
    }
  };

  const closeModalFile = () => {
    if (renameModal !== false) {
      setRenameModal(false);
      setModalOpen(false);
    }
    if (showVisibilityInfoModal !== false) {
      closeVisibilityInfoModal();
      setModalOpen(false);
    }

    if (
      showVisibilityInfoModal !== false &&
      showVisibleAndInvisibleAndChangeRubModal !== false
    ) {
      setShowVisibleAndInvisibleAndChangeRubModal(false);
      closeVisibilityInfoModal();
      setModalOpen(false);
    }

    setModalOpen(false);
  };

  // handle rename file
  const HandleRenameFile = async () => {
    if (name === "") {
      return toast.warning("Merci de saisir le nom");
    }

    if (
      files.some(
        (file) =>
          file.name.toLowerCase() ===
          name.toLowerCase() + "." + clickedFile.name.split(".").pop()
      )
    ) {
      return toast.warning(`Ce nom ${name} est déjà existant`);
    }

    setLoadingRename(true);
    setShowLoadingModal(true);
    setRenameModal(false);
    await dispatch(
      renameFile(
        id,
        clickedFile._id,
        name + "." + clickedFile.name.split(".").pop(),
        collaborateurInfo?.name,
        new Date()
      )
    );
    setName("");
    getUpdated("yes");
    setLoadingRename(false);
    setShowLoadingModal(false);
  };

  useEffect(() => {
    if (loadingRename === false) {
      if (fileRes && fileRes.file && fileRes.file.success === true) {
        toast.success("Modification effectuée");
      } else if (
        fileRes.error &&
        fileRes.error?.msg === "Merci de saisir le nouveau nom"
      ) {
        toast.warn("Merci de saisir le nouveau nom");
        //console.log("Merci de saisir le nouveau nom");
      }
    }
  }, [loadingRename]);

  ////// Move file

  useEffect(() => {
    if (showMoveModal) setOpenedFileContent(openedFile);
  }, [showMoveModal]);

  //console.log("openedFileContent", openedFileContent);

  useEffect(() => {
    if (openedFileContent?.length > 0) {
      setIdFolderMoveTo(
        openedFileContent[openedFileContent.length - 1].split("/").pop()
      );
    }
  }, [openedFileContent]);

  const getDossierById = (data, id) => {
    // Parcours de la liste des dossiers
    for (const item of data) {
      // Vérification de l'ID du dossier actuel
      if (item._id === id) {
        return item; // Retourne le dossier trouvé
      }

      // Vérification des sous-dossiers
      if (item.files && item.files.length > 0) {
        const subDossier = getDossierById(item.files, id);
        if (subDossier) {
          return subDossier; // Retourne le sous-dossier trouvé
        }
      }
    }

    return null; // Aucun dossier trouvé avec l'ID donné
  };

  const getFolderMoveTo = () => {
    setFolderMoveTo(getDossierById(dossierClient?.files, idFolderMoveTo));
  };

  //console.log("folderMoveTo", folderMoveTo);
  useEffect(() => {
    if (idFolderMoveTo !== null) {
      getFolderMoveTo();
    }
  }, [showConfirmMoveModal]);

  const handleShowConfirmMoveModal = () => {
    if (!isValidMongoID(idFolderMoveTo)) {
      return toast.warn("Merci de sélectionner un dossier valide");
    } else if (openedFileContent?.length <= 1) {
      return toast.warn("Merci de sélectionner un dossier");
    }
    //  getNameFolderMoveTo();

    setShowConfirmMoveModal(true);
  };
  useEffect(() => {
    //console.log("idFolderMoveTo", idFolderMoveTo);
    //console.log("nameFolderMoveTo", folderMoveTo?.name);
  }, [idFolderMoveTo]);

  const handleMoveFileFolder = async () => {
    // verif same name exist in folder move to or dont select a folder
    if (folderFile?._id.toString() === folderMoveTo?._id.toString()) {
      setShowConfirmMoveModal(false);
      return toast.warning(
        `Merci de sélectionner un dossier où vous souhaitez déplacer le fichier ${clickedFile.name}`
      );
    }

    if (clickedFile && clickedFile.type === "file") {
      if (
        folderMoveTo?.files
          ?.filter((file) => file.type === "file")
          .some((file) => file.name === clickedFile.name)
      ) {
        return toast.warning(
          `Ce nom ${clickedFile.name} est déjà existant dans ${folderMoveTo.name} .//.`
        );
      }
      setLoadingMoveFile(true);
      setShowLoadingModal(true);
      setShowConfirmMoveModal(false);
      setShowMoveModal(false);
      await dispatch(
        moveFileAction(
          id,
          clickedFile?._id,
          idFolderMoveTo,
          collaborateurInfo?.name,
          new Date()
        )
      );
      getUpdated("yes");
      setLoadingMoveFile(false);
      setShowLoadingModal(false);
    } else if (clickedFile && clickedFile.type === "folder") {
      handleMoveFolder();
    }
  };

  useEffect(() => {
    if (loadingMoveFile === false) {
      if (fileRes && fileRes.movedFile && fileRes.movedFile.success === true) {
        toast.success("Votre fichier a bien été déplacé");
      } else if (fileRes && fileRes.error) {
        toast.warn(fileRes.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [loadingMoveFile]);

  ////// Delete file

  const handleDelete = async () => {
    if (
      clickedFile &&
      clickedFile.importedBy === "Client" &&
      collaborateurInfo?.role === "Collaborateur"
    ) {
      return alert(
        "Vous ne pouvez pas supprimer un document importé par le client"
      );
    }
    if (clickedFile && clickedFile.type === "file") {
      setLoadingDeleteFile(true);
      setShowLoadingModal(true);
      setShowConfirmDeleteModal(false);

      await dispatch(deleteFileAction(id, clickedFile?._id));
      getUpdated("yes");
      setLoadingDeleteFile(false);
      setShowLoadingModal(false);
    } else if (clickedFile && clickedFile.type === "folder") {
      handleDeleteFolder();
    }
  };

  useEffect(() => {
    if (loadingDeleteFile === false) {
      if (
        fileRes &&
        fileRes.deletedFile &&
        fileRes.deletedFile.success === true &&
        fileRes.deletedFile.data
      ) {
        toast.success(
          `Le fichier ${fileRes.deletedFile.data.name} a bien été supprimé`
        );
      } else if (fileRes && fileRes.error) {
        toast.warn(fileRes.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [loadingDeleteFile]);

  const handleDownload = () => {
    setModalOpen(true);
    setTimeout(() => {
      setModalOpen(false);
    }, 1000);
  };

  // file visibility
  const myDossier = useSelector((state) => state.client);
  const handleFileVisibility = () => {
    //console.log("categories", categories);

    if (clickedFile?.importedBy === "Client") {
      return alert(
        "Les fichiers importés par les clients sont automatiquement visibles. La visibilité de ces fichiers ne peut pas être modifiée."
      );
    }

    if (categories?.length > 0 && rubriqueName !== "") {
      return setShowVisibilityInfoModal(true);
    }
    if (categories.length > 0) {
      setRubriqueName(
        categories?.find((cat) => cat._id === clickedFile.visible)?.name
      );
    }

    // // handleGetMenu()
    setShowVisibilityInfoModal(true);
  };

  const closeVisibilityInfoModal = () => {
    setShowVisibilityInfoModal(false);
    setRubrique("");
  };

  // get categories client (specific menu) en utilisant id menuClient dans dossier client
  // Afficher les catégories dans le modal de la visibilité  => avec validation si déja visible et dans la même rubrique return alert
  const handleGetMenu = async () => {
    setGettingMenuLoader(true);
    //console.log("menuId", dossierClient?.menuClient);
    await dispatch(loadSpcificMenuClient(dossierClient?.menuClient));
    setGettingMenuLoader(false);
  };

  useEffect(() => {
    if (dossierClient !== null) handleGetMenu();
  }, [dossierClient]);

  useEffect(() => {
    if (myDossier?.specificMenu && myDossier?.specificMenu?.menuClient) {
      setCategories(myDossier?.specificMenu?.menuClient?.menu);
    }
  }, [myDossier?.specificMenu]);

  const handleVisibilityOption = (type) => {
    const options = {
      rendreVisible: {
        title: "Rendre le document suivant visible : ",
        body: "Sélectionner une rubrique : ",
      },
      changeRub: {
        title: "Modifier la rubrique dans l'espace client : ",
        body: "Sélectionner la nouvelle rubrique : ",
      },
      rendreInvisible: {
        title: "Rendre le document invisible dans l'espace client : ",
        body: "En cliquant sur 'Confirmer', ce document ne sera plus visible dans l'espace client.",
      },
    };

    if (options[type]) {
      setModalType(options[type]);
      setShowVisibleAndInvisibleAndChangeRubModal(true);
    }
  };

  const handleActionOptions = async (type) => {
    //console.log("type", type);
    if (type === "rendreVisible") {
      if (rubrique === "") {
        return alert("Merci de sélectionner une rubrique");
      }
      setShowLoadingModal(true);
      closeModalFile();
      await dispatch(changeCatFile(id, clickedFile._id, rubrique));
      setRubrique("");
      setShowLoadingModal(false);
      getUpdated("yes");
    } else if (type === "changeRub") {
      if (rubrique === clickedFile.visible) {
        return alert(
          "Le document est déjà répertorié sous cette rubrique dans l'espace client."
        );
      }
      setShowLoadingModal(true);
      closeModalFile();
      await dispatch(changeCatFile(id, clickedFile._id, rubrique));
      setRubrique("");
      setShowLoadingModal(false);
      getUpdated("yes");
    } else if (type === "rendreInvisible") {
      if (clickedFile.visible === null) {
        return alert("Le document n'apprait déjà pas dans l'espace client .");
      }
      setShowLoadingModal(true);
      closeModalFile();
      await dispatch(changeCatFile(id, clickedFile._id, null));
      setRubrique("");
      setShowLoadingModal(false);
      getUpdated("yes");
    }
  };

  return (
    <div className="800px:border-t-2 ">
      {searchText !== "" ? null : (
        <h2 className=" flex flex-wrap mt-2 mb-3 px-4">
          {" "}
          Contenu du dossier :{" "}
          <span className="text-gray-600 ml-1 flex">
            {folderFile?.name}{" "}
            <AiFillInfoCircle
              onClick={() => {
                setModalInfoOpen(true);
                handleClickedFile(folderFile);
              }}
              className="cursor-pointer text-gray-400 ml-1"
              size={20}
            />
          </span>{" "}
        </h2>
      )}
      {searchText !== "" ? (
        <h2 className="text-center mt-2">
          {" "}
          Résultat de rechercher pour "
          <span className="font-bold">{searchText}</span>"{" "}
        </h2>
      ) : null}
      {isThereContent ? (
        <div>
          <div className=" flex w-[100%]  mt-2 ">
            <div className=" h-[150px] overflow-y-auto">
              <h1 className="font-bold mt-2 px-4"> Dossiers </h1>

              <div className="flex flex-wrap  px-4 ">
                {folders?.map((folder) => (
                  <div
                    key={folder._id}
                    onClick={() => handleOpenFolder(folder)}
                    className="  mb-2 ml-6 flex flex-col items-center cursor-pointer overflow-y-scroll transition-all hover:scale-105"
                  >
                    {folder.files.length > 0 ? (
                      <HiFolder className="text-blue-600 " size={70} />
                    ) : (
                      <CiFolderOn className="text-blue-600 " size={65} />
                    )}

                    <h1 className=" text-sm break-all  text-center w-[80px] ">
                      {folder?.name.length > 16
                        ? folder.name.substring(0, 16) + ".."
                        : folder.name}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className=" flex w-[100%]  border-t-2 ">
            <div>
              <h1 className="font-bold mt-2 px-4"> Fichiers </h1>
              <div className="flex flex-wrap mt-4 px-4 ">
                {files?.map((file) => (
                  <div
                    key={file._id}
                    onClick={() => {
                      handleClickedFile(file);
                      setModalOpen(true);
                    }}
                    className="mb-4 ml-6 flex flex-col justify-center items-center cursor-pointer transition-all hover:scale-105"
                  >
                    <div
                      className={`relative w-[60px] h-[70px] ${
                        file.importedBy !== "Client" ? "border-2" : ""
                      }`}
                    >
                      {file.fileType === "application/pdf" ? (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <div className="w-full h-full">
                       
                            <Viewer
                              fileUrl={`${backend_url_files}${file.path.replace(
                                "backend/dossier/",
                                ""
                              )}`}
                              defaultScale={SpecialZoomLevel.PageFit}
                            />
                            <div className="absolute bottom-0 right-0 text-red-600">
                              <FaFilePdf size={22} />
                            </div>
                          </div>
                        </Worker>
                      ) : file.fileType.startsWith("image/") ? (
                        <div>
                        
                        <img
                          src={`${backend_url_files}${file.path.replace(
                            "backend/dossier/",
                            ""
                          )}`}
                          alt="file"
                          className="w-[60px] h-[70px]"
                        />
                        </div>
                      ) : (
                        <div className="flex justify-center items-center ">
                          {file.fileType ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                          file.fileType === "application/vnd.ms-excel" ? (
                            <div className="w-[60px] h-[70px] flex justify-center items-center rounded-sm">
                              <FaFileExcel size={45} color="#217346" />
                            </div>
                          ) : file.fileType ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                            <div className="w-[60px] h-[70px] flex justify-center items-center  rounded-sm">
                              <AiOutlineFileWord size={50} color="#1A73E8" />
                            </div>
                          ) : file.fileType ===
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                            file.fileType === "application/vnd.ms-powerpoint" ||
                            file.fileType === "application/octet-stream" ? (
                            <div className="w-[60px] h-[70px] flex justify-center items-center  rounded-sm">
                              <FaRegFilePowerpoint size={45} color="#D24726" />
                            </div>
                          ) : (
                            <div className="w-[60px] h-[70px] flex justify-center items-center  rounded-sm">
                              <AiFillFileText size={45} />
                            </div>
                          )}
                        </div>
                      )}

                      {file.importedBy !== "Client" && file.visible && (
                        <div className="absolute top-[0] left-0 text-blue-700">
                          <AiOutlineEye size={18} />
                        </div>
                      )}
                      {file.importedBy === "Client" && (
                        <div className="absolute top-[0] left-0 text-blue-700">
                          <FaUser size={18} />
                        </div>
                      )}
                    </div>

                    <span className="text-sm break-all h-10 text-center w-[80px]">
                      {file?.name.lastIndexOf(".") > 0
                        ? file.name.substring(0, file.name.lastIndexOf("."))
                            .length > 16
                          ? file.name
                              .substring(0, file.name.lastIndexOf("."))
                              .substring(0, 16) + ".."
                          : file.name.substring(0, file.name.lastIndexOf("."))
                        : file.name.length > 16
                        ? file.name.substring(0, 16) + ".."
                        : file.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Modal
            size={
              clickedFile.fileType?.startsWith("image/") ||
              clickedFile.fileType === "application/pdf"
                ? "xl"
                : "md"
            }
            dialogClassName={`${
              !clickedFile.fileType?.startsWith("image/") &&
              clickedFile.fileType !== "application/pdf"
                ? "top-1/4"
                : ""
            } `}
            show={modalOpen}
            onHide={closeModalFile}
          >
            <Modal.Header>
              <div className="flex  ">
                <Modal.Title className="text-sm">
                  {clickedFile?.name}
                </Modal.Title>
                <div className="relative">
                  {showTextHoverEdit && (
                    <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                      Renomer
                    </span>
                  )}
                  <AiFillEdit
                    className="cursor-pointer ml-1"
                    size={20}
                    color="rgb(59 130 246)"
                    onClick={handleRename}
                    onMouseOver={() => setShowTextHoverEdit(true)}
                    onMouseLeave={() => setShowTextHoverEdit(false)}
                  />
                </div>
              </div>

              <div className="flex items-center">
                <div className="flex space-x-2 mr-3">
                  <div className="relative">
                    {showTextHoverDownLoad && (
                      <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        Télécharger
                      </span>
                    )}
                    <Link
                      to={`${backend_url_files}${clickedFile?.path?.replace(
                        "backend/dossier/",
                        ""
                      )}`}
                      target={
                        clickedFile?.fileType?.startsWith("image/") ||
                        clickedFile?.fileType === "application/pdf"
                          ? "_blank"
                          : ""
                      }
                      download={clickedFile?.name}
                      onClick={handleDownload}
                    >
                      <FaCloudDownloadAlt
                        className="cursor-pointer"
                        size={20}
                        color="rgb(59, 130, 246)"
                        onMouseOver={() => setShowTextHoverDownLoad(true)}
                        onMouseLeave={() => setShowTextHoverDownLoad(false)}
                      />
                    </Link>
                  </div>

                  <div className="relative">
                    {showTextHoverVisible && (
                      <span className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        {clickedFile?.visible !== null
                          ? "Visible"
                          : "Invisible"}
                      </span>
                    )}
                    {clickedFile.visible !== null ? (
                      <AiOutlineEye
                        className="cursor-pointer"
                        size={19}
                        color="rgb(59, 130, 246)"
                        onClick={handleFileVisibility}
                        onMouseOver={() => setShowTextHoverVisible(true)}
                        onMouseLeave={() => setShowTextHoverVisible(false)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="cursor-pointer"
                        size={19}
                        color="rgb(59, 130, 246)"
                        onClick={handleFileVisibility}
                        onMouseOver={() => setShowTextHoverVisible(true)}
                        onMouseLeave={() => setShowTextHoverVisible(false)}
                      />
                    )}
                  </div>

                  <div className="relative">
                    {showTextHoverMove && (
                      <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        Déplacer
                      </span>
                    )}
                    <FaExchangeAlt
                      className="cursor-pointer"
                      size={19}
                      color="rgb(59 130 246)"
                      onClick={() => setShowMoveModal(true)}
                      onMouseOver={() => setShowTextHoverMove(true)}
                      onMouseLeave={() => setShowTextHoverMove(false)}
                    />
                  </div>

                  <div className="relative">
                    {showTextHoverdelete && (
                      <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        Supprimer
                      </span>
                    )}
                    <MdDelete
                      className="cursor-pointer"
                      size={20}
                      color="rgb(59 130 246)"
                      onClick={() => setShowConfirmDeleteModal(true)}
                      onMouseOver={() => setShowTextHoverdelete(true)}
                      onMouseLeave={() => setShowTextHoverdelete(false)}
                    />
                  </div>
                  <div className="relative">
                    {showTextHoverInfo && (
                      <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        Infos
                      </span>
                    )}
                    <AiFillInfoCircle
                      onClick={() => setModalInfoOpen(true)}
                      color="rgb(59 130 246)"
                      className="cursor-pointer"
                      size={20}
                      onMouseOver={() => setShowTextHoverInfo(true)}
                      onMouseLeave={() => setShowTextHoverInfo(false)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={closeModalFile}
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                    />
                  </svg>
                </button>
              </div>
            </Modal.Header>

            <Modal.Body
              className={`${
                clickedFile.fileType === "application/pdf"
                  ? ""
                  : "flex justify-center items-center"
              }`}
            >
              {clickedFile.type === "file" &&
              clickedFile.fileType.startsWith("image/") ? (
                <img
                src={`${backend_url_files}${clickedFile.path?.replace(
                  "backend/dossier/",
                  ""
                )}`}
                  alt="file"
                  className="w-100"
                />
              ) : clickedFile.fileType === "application/pdf" ? (
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "750px",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                    fileUrl={`${backend_url_files}${clickedFile.path.replace(
                      "backend/dossier/",
                      ""
                    )}`}
                      defaultScale={SpecialZoomLevel.PageFit}
                    />
                  </Worker>
                </div>
              ) : clickedFile.fileType === "application/pdf" ? (
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "750px",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={`${backend_url_files}${clickedFile.path}`}
                      defaultScale={SpecialZoomLevel.PageFit}
                    />
                  </Worker>
                </div>
              ) : clickedFile.fileType ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                clickedFile.fileType === "application/vnd.ms-excel" ? (
                <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                  <FaFileExcel size={45} color="#217346" />
                </div>
              ) : clickedFile.fileType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                  <AiOutlineFileWord size={50} color="#1A73E8" />
                </div>
              ) : clickedFile.fileType ===
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                clickedFile.fileType === "application/vnd.ms-powerpoint" ||
                clickedFile.fileType === "application/octet-stream" ? (
                <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                  <FaRegFilePowerpoint size={45} color="#D24726" />
                </div>
              ) : (
                <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                  <AiFillFileText size={45} />
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <span className="italic text-gray-500 px-4 ">Dossier vide</span>
      )}
      <Modal
        size="auto"
        dialogClassName=" top-1/4"
        show={modalInfoOpen}
        onHide={() => setModalInfoOpen(false)}
      >
        <Modal.Header>
          <div className="flex ">
            <Modal.Title className="text-sm">
              Informations{" "}
              {clickedFile?.type === "folder" ? "Dossier" : "fichier"}
            </Modal.Title>
            {clickedFile?.type === "folder" &&
              clickedFile?.parentId?.length > 1 && (
                <div className="relative">
                  {showTextHoverEdit && (
                    <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                      Renomer
                    </span>
                  )}
                  <AiFillEdit
                    className="cursor-pointer ml-1"
                    size={20}
                    color="rgb(59 130 246)"
                    onClick={handleRename}
                    onMouseOver={() => setShowTextHoverEdit(true)}
                    onMouseLeave={() => setShowTextHoverEdit(false)}
                  />
                </div>
              )}
          </div>
          <div className="flex items-center">
            {clickedFile?.type === "folder" &&
              clickedFile?.parentId?.length > 1 && (
                <div className="flex space-x-2 mr-3">
                  <div className="relative">
                    {showTextHoverMove && (
                      <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        Déplacer
                      </span>
                    )}
                    <FaExchangeAlt
                      className="cursor-pointer"
                      size={19}
                      color="rgb(59 130 246)"
                      onClick={() => setShowMoveModal(true)}
                      onMouseOver={() => setShowTextHoverMove(true)}
                      onMouseLeave={() => setShowTextHoverMove(false)}
                    />
                  </div>

                  <div className="relative">
                    {showTextHoverdelete && (
                      <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        Supprimer
                      </span>
                    )}
                    {collaborateurStore?.user?.role !== "Collaborateur" && (
                      <MdDelete
                        className="cursor-pointer"
                        size={20}
                        color="rgb(59 130 246)"
                        onClick={() => setShowConfirmDeleteModal(true)}
                        onMouseOver={() => setShowTextHoverdelete(true)}
                        onMouseLeave={() => setShowTextHoverdelete(false)}
                      />
                    )}
                  </div>
                </div>
              )}
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700"
              onClick={handleCloseModalInfo}
            >
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                />
              </svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="flex  flex-col px-2  space-y-2 ">
            <h1>
              {" "}
              Nom : <span className="text-gray-500">
                {clickedFile?.name}
              </span>{" "}
            </h1>
            {clickedFile.type === "file" ? (
              <div>
                <h1 className="mb-2">
                  {" "}
                  Importé par :{" "}
                  <span className="text-gray-500">
                    {clickedFile?.createdBy}
                  </span>{" "}
                </h1>
                <h1>
                  {" "}
                  Importé le :{" "}
                  <span className="text-gray-500">
                    {clickedFile?.createdAt?.substring(0, 10) || null}
                  </span>{" "}
                </h1>{" "}
              </div>
            ) : (
              <div>
                <h1 className="mb-2">
                  {" "}
                  Description :{" "}
                  <span className="text-gray-500">
                    {clickedFile?.Description}
                  </span>{" "}
                </h1>
                <h1 className="mb-2">
                  {" "}
                  Créée par :{" "}
                  <span className="text-gray-500">
                    {clickedFile?.createdBy}
                  </span>{" "}
                </h1>
                <h1 className="mb-2">
                  {" "}
                  Créée le :{" "}
                  <span className="text-gray-500">
                    {clickedFile?.createdAt?.substring(0, 10)}
                  </span>{" "}
                </h1>
                {clickedFile?.modificatedAt && (
                  <div>
                    <h1 className="mb-2">
                      {" "}
                      Dernière modification le :{" "}
                      <span className="text-gray-500">
                        {clickedFile?.modificatedAt?.substring(0, 10)}
                      </span>{" "}
                    </h1>{" "}
                    <h1>
                      {" "}
                      Modifié par :{" "}
                      <span className="text-gray-500">
                        {clickedFile?.modificatedBy}
                      </span>{" "}
                    </h1>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>{" "}
      <Modal
        size="auto"
        show={renameModal}
        onHide={closeRenameModal}
        style={{ marginTop: "10%" }}
      >
        <Modal.Header>
          <Modal.Title className="text-sm">
            Modifier le nom du fichier{" "}
            <span className="font-bold">{clickedFile?.name}</span>
          </Modal.Title>

          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeRenameModal}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFileName">
              <Form.Control
                type="text"
                placeholder="Entrez le nouveau nom "
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={closeRenameModal}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={HandleRenameFile}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="auto"
        show={renameModalFolder}
        onHide={closeRenameModalFolder}
        style={{ marginTop: "10%" }}
      >
        <Modal.Header>
          <Modal.Title className="text-sm">
            Modifier le nom du dossier{" "}
            <span className="font-bold">{clickedFile?.name}</span>
          </Modal.Title>

          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeRenameModalFolder}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFileName">
              <Form.Control
                type="text"
                placeholder="Entrez le nouveau nom "
                value={nameFolder}
                onChange={(e) => setNameFolder(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={closeRenameModalFolder}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={HandleRenameFolder}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMoveModal} onHide={() => setShowMoveModal(false)}>
        <Modal.Header>
          <Modal.Title className="text-sm">
            Déplacer{" "}
            {clickedFile?.type === "file" ? "le fichier" : "le dossier"}{" "}
            {clickedFile?.name}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowMoveModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="mb-2 font-bold"> Déplacer vers : </h1>
          <ArborescenceSideMove
            openedFile={openedFileContent}
            setOpenedFile={setOpenedFileContent}
            dossierClient={dossierClient}
            folderFile={folderFile}
            showMoveModal="true"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setShowMoveModal(false)}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={handleShowConfirmMoveModal}
          >
            Déplacer le fichier
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmMoveModal}
        dialogClassName="max-w-[700px] top-1/4"
        className=" px-2 bg-transparent "
        onHide={() => setShowConfirmMoveModal(false)}
      >
        {" "}
        {/* Ajouter la classe "modal-lg" pour le rendre plus large */}
        <Modal.Header>
          <Modal.Title className="text-sm font-bold">
            Confirmation déplacement {clickedFile?.name}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowConfirmMoveModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="mb-2 text-sm">
            {" "}
            Êtes vous sur de vouloir déplacer{" "}
            {clickedFile?.type === "file" ? "le fichier" : "le dossier"}{" "}
            <span className="font-bold">{clickedFile?.name}</span> vers le
            dossier: <span className="font-bold">{folderMoveTo?.name}</span> ?
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setShowConfirmMoveModal(false)}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={handleMoveFileFolder}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmDeleteModal}
        dialogClassName="max-w-[700px] top-1/4"
        className=" px-2 bg-transparent "
        onHide={() => setShowConfirmDeleteModal(false)}
      >
        {" "}
        {/* Ajouter la classe "modal-lg" pour le rendre plus large */}
        <Modal.Header>
          <Modal.Title className="text-sm font-bold">
            Confirmation suppression {clickedFile?.name}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowConfirmDeleteModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="mb-2 text-sm">
            {" "}
            Êtes vous sur de vouloir supprimer{" "}
            {clickedFile?.type === "file" ? "le fichier" : "le dossier"}{" "}
            <span className="font-bold">{clickedFile?.name}</span> ? <br />
            <br />
            {clickedFile?.type === "folder"
              ? "Attention la suppression de ce dossier entraine la suppression défénitive de tous les éléments qu'il contient."
              : null}
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setShowConfirmDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={handleDelete}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="auto"
        show={showVisibilityInfoModal}
        onHide={() => setShowVisibilityInfoModal(false)}
        style={{ marginTop: "10%" }}
      >
        <Modal.Header>
          <Modal.Title className="text-sm">
            Le fichier <span className="font-bold">{clickedFile?.name}</span>{" "}
            {clickedFile?.visible !== null ? " est visible" : " est invisible"}
          </Modal.Title>

          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowVisibilityInfoModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body className="text-center">
          {clickedFile.visible !== null ? (
            <p>
              Ce document est répertorié sous la rubrique "{rubriqueName || ""}"
              dans l'espace client.
            </p>
          ) : (
            <p>Ce document ne figure pas dans l'espace client.</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          {clickedFile.visible !== null ? (
            <>
              <Button
                className="bg-blue-500 hover:bg-blue-700"
                onClick={() => handleVisibilityOption("changeRub")}
              >
                Changer la rubrique
              </Button>
              <Button
                className="bg-blue-500 hover:bg-blue-700"
                onClick={() => handleVisibilityOption("rendreInvisible")}
              >
                Rendre invisible
              </Button>
            </>
          ) : (
            <>
              <Button
                className="bg-gray-500 hover:bg-gray-700"
                onClick={() => setShowVisibilityInfoModal(false)}
              >
                Annuler
              </Button>
              <Button
                className="bg-blue-500 hover:bg-blue-700"
                onClick={() => handleVisibilityOption("rendreVisible")}
              >
                Rendre Visible
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        size="auto"
        show={showVisibleAndInvisibleAndChangeRubModal}
        onHide={() => setShowVisibleAndInvisibleAndChangeRubModal(false)}
        style={{ marginTop: "13%" }}
      >
        <Modal.Header>
          <Modal.Title className="text-sm">
            {modalType?.title}
            {clickedFile?.name}
          </Modal.Title>

          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowVisibleAndInvisibleAndChangeRubModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          {modalType?.body}

          {modalType?.title === "Rendre le document suivant visible : " ||
          modalType?.title ===
            "Modifier la rubrique dans l'espace client : " ? (
            <>
              {gettingMenuLoader ? (
                <Loader />
              ) : (
                <select
                  name="rubrique"
                  value={rubrique}
                  onChange={(event) => setRubrique(event.target.value)}
                  className="block ml-2 800px:w-70 w-[250px] mt-2 cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value="">Sélectionnez une rubrique</option>
                  {categories.length > 0 &&
                    categories.map((cat) => (
                      <option value={cat._id} key={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </select>
              )}
            </>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setShowVisibleAndInvisibleAndChangeRubModal(false)}
          >
            Annuler
          </Button>
          {modalType && (
            <Button
              className="bg-blue-500 hover:bg-blue-700"
              onClick={() =>
                handleActionOptions(
                  modalType?.title === "Rendre le document suivant visible : "
                    ? "rendreVisible"
                    : modalType?.title ===
                      "Modifier la rubrique dans l'espace client : "
                    ? "changeRub"
                    : "rendreInvisible"
                )
              }
            >
              Confirmer
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </div>
  );
};

export default DossierContentDetails;
