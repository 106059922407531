import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../Styles/Styles.js";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import ClientProtectedRoute from "../../Routes/ClientProtectedRoute.js";
import Loader from "../../Utils/Loader.js";
import { useDispatch, useSelector } from "react-redux";
import { loginClient } from "../../redux/actions/clientAction.js";
import logo1 from "../../Files/logo/logo1.png";

const Login = () => {
  const [emailConnexion, setEmailConnexion] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const info = useSelector((state) => state.client);

  const alreadyConnected = JSON.parse(localStorage.getItem("clt"));

  useEffect(() => {
    if (alreadyConnected !== null) {
      navigate("/espaceClient");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (emailConnexion === "") {
      alert("Veuillez saisir votre adresse mail");
      return;
    }
    if (password === "") {
      alert("Veuillez saisir votre mots de passe");
      return;
    }
    setLoading(true);
    await dispatch(loginClient(emailConnexion, password));
    setLoading(false);
  };
  useEffect(() => {
    if (loading === false) {
      //console.log("info client", info);

      //console.log("info client", info?.client?.client?.active);

      if (
        info?.client?.success === true &&
        info?.client?.client_token &&
        info.client?.client?.active !== false
      ) {
        setEmailConnexion("");
        setPassword("");
        navigate("/espaceClient");
        localStorage.setItem("clt", JSON.stringify(info?.client?.client));
      } else if (info.client?.client?.active === false) {
        setEmailConnexion("");
        setPassword("");
        return alert("Votre compte a été désactivé par un administrateur");
      }
    }
    if (!info?.isAuthenticated && info?.error) {
      //console.log("info", info.error);
      setEmailConnexion("");
      setPassword("");
      alert(info.error);
      dispatch({ type: "clearErrors" });
    }
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="min-h-screen bg-gray-50">
          <div className="pl-6 pt-6 ">
            <Link to="https://www.optiwise.ma" className="">
              <img src={logo1} alt="" className="w-[80px] h-[60px]" />
            </Link>
          </div>
          <div className=" flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Connexion à votre compte
              </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adresse mail
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        autoComplete="email"
                        value={emailConnexion}
                        onChange={(e) => setEmailConnexion(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mots de passe
                    </label>
                    <div className="mt-1 relative">
                      <input
                        type={visible ? "text" : "password"}
                        name="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      {visible ? (
                        <AiOutlineEye
                          className="absolute right-2 top-2 cursor-pointer"
                          size={25}
                          onClick={() => setVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute right-2 top-2 cursor-pointer"
                          size={25}
                          onClick={() => setVisible(true)}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${styles.noramlFlex} justify-end`}>
                    <div className="text-sm">
                      <Link
                        to="/forgot-password"
                        className="font-medium text-blue-600 hover:text-blue-500"
                      >
                        Mots de passe oublié
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                    >
                      Submit
                    </button>
                  </div>
                  <div className={`${styles.noramlFlex} w-full`}>
                    <h4>Devenir client?</h4>
                    <Link
                      to="https://optiwise.ma/contact/"
                      className="text-blue-600 pl-2"
                    >
                      Contact
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
