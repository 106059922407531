import React from "react";
import SupAdminCreateCabinetd from "../../Components/SupAdminComponents/SupAdminCreateCabinetd.jsx";

const SupAdminCreateCabinetdPage = () => {
  return (
    <div>
      <SupAdminCreateCabinetd />
    </div>
  );
};

export default SupAdminCreateCabinetdPage;
