import React, { useEffect, useState } from "react";

const ClientProtectedRoute = () => {
  const [clientData, setClientData] = useState(
    JSON.parse(localStorage.getItem("client"))
  );

  //console.log("supAdminData", clientData);
  //console.log("supAdminData", clientData?.active);
  const [isAuthentificated, setIsAuthentificated] = useState();
  const [activeClient, setActiveClient] = useState();

  useEffect(() => {
    //console.log("clientData", clientData);
    setClientData(JSON.parse(localStorage.getItem("client")));
    if (clientData != null) {
      setIsAuthentificated(true);
      if (clientData?.active === true) {
        setActiveClient(true);
      } else {
        setActiveClient(false);
      }
    } else {
      setIsAuthentificated(false);
      setActiveClient(false);
    }
  }, []);

  return [isAuthentificated, activeClient, setIsAuthentificated];
};

export default ClientProtectedRoute;
