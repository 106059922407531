import React, { useEffect, useState } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { FaCheck, FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AdminComptableRegister = () => {
  const [name, setName] = useState("");
  const [prenom, setPrenom] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [role, setRole] = useState("");
  const [service, setService] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [poste, setPoste] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [cabId, setCabId] = useState(false);
  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));

  useEffect(() => {
    if (collaborateurStore !== null) {
      setCabId(collaborateurStore?.user?.cabinet);
    }
  }, [collaborateurStore]);

  //console.log("cabId", cabId);

  const navigate = useNavigate();

  const handleCopyPassword = () => {
    const passwordInput = document.getElementById("passwordInput");
    passwordInput.select();
    document.execCommand("copy");
    setIsCopied(true); // Mettre à jour l'état pour indiquer que le code est copié
    // Réinitialiser l'état après quelques secondes (facultatif)
    setTimeout(() => setIsCopied(false), 5000); // Remettre à false après 3 secondes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //console.log(role);

    if (
      name === "" ||
      prenom === "" ||
      identifiant === "" ||
      password === "" ||
      role === "" ||
      password === ""
    ) {
      return toast.warn("Merci de remplir toutes les informations requises");
    }
    if (!cabId) {
      localStorage.removeItem("collaborateur");
      navigate("/login-pro");

      return toast.warn(
        "Merci de vous reconnecter pour pouvoir créer un nouveau collaborateur"
      );
    }

    await axios
      .post(
        `${server}/collaborateur/create-collaborateur`,
        {
          name,
          prenom,
          role,
          identifiant,
          service,
          email,
          password,
          poste,
          cabinetId: cabId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data && res.data.data.identifiant) {
          toast.success(
            `Le collaborateur ${res.data.data.identifiant} a été créée avec succes`
          );
        }

        setName("");
        setPrenom("");
        setIdentifiant("");
        setService("");
        setEmail("");
        setRole("");
        setPoste("");
        setPassword("");
        navigate("/dashboard/collaborateurs");
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            error?.response?.data?.errors[0]?.msg
        );
      });
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    const nameslice = name.slice(0, 2);
    const prenomslice = prenom.slice(0, 2);
    const generatedPassword = prenomslice + randomPassword + nameslice;
    setPassword(generatedPassword);
    setGeneratedPassword(generatedPassword);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full p-6 bg-white rounded shadow-md">
        <h2 className="mb-6 text-2xl font-bold text-center text-gray-900">
          Nouveau Collaborateur
        </h2>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="nom"
              className="block text-sm font-medium mr-2 mb-1 text-gray-700"
            >
              Nom du collaborateur <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Entrez le nom"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="prenom"
              className="block text-sm  mr-2 mb-1 font-medium text-gray-700"
            >
              Prénom du collaborateur <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="prenom"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              placeholder="Entrez le prénom"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <label
            htmlFor="role"
            className="block text-sm  mr-2 mb-1 font-medium text-gray-700"
          >
            Rôle <span className="text-red-500">*</span>
          </label>
          <select
            name="role"
            value={role}
            onChange={(event) => setRole(event.target.value)}
            className="block ml-2 800px:w-70 w-[250px] cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Les habilités attribuées</option>

            <option value="collaborateur">Collaborateur</option>
            <option value="Manager">Manager</option>
          </select>

          <div>
            <label
              htmlFor="service"
              className="block text-sm mr-2 mb-1 font-medium text-gray-700"
            >
              Service
            </label>
            <input
              type="text"
              name="service"
              value={service}
              onChange={(e) => setService(e.target.value)}
              placeholder="Entrez le service"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="poste"
              className="block text-sm mr-2 mb-1 font-medium text-gray-700"
            >
              Poste
            </label>
            <input
              type="text"
              name="poste"
              value={poste}
              onChange={(e) => setPoste(e.target.value)}
              placeholder="Entrez le poste"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm mr-2  mb-1 font-medium text-gray-700"
            >
              Adresse mail <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Entrez l'adresse mail"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="identifiant"
              className="block text-sm  mr-2 mb-1 font-medium text-gray-700"
            >
              Identifiant <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="identifiant"
              value={identifiant}
              onChange={(e) => setIdentifiant(e.target.value)}
              placeholder="Identifiant pour connexion"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={generateRandomPassword}
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
            >
              Générer un mot de passe
            </button>
          </div>
          {generatedPassword && (
            <div className="space-y-2">
              <label className="block font-medium text-gray-700">
                Mot de passe à transmettre au collaborateur{" "}
                <span className="text-red-500">*</span>
              </label>
              <div className="relative mt-1">
                <input
                  type="text"
                  value={password}
                  placeholder="Cliquez sur le bouton 'Générer un mot de passe'"
                  onChange={(e) => setPassword(e.target.value)}
                  readOnly
                  id="passwordInput" // Assurez-vous que l'input a un ID pour la sélection
                  className="mt-1 p-2 pl-10 border border-gray-300 rounded w-full"
                />
                <button
                  type="button"
                  onClick={handleCopyPassword}
                  className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer"
                >
                  {isCopied ? <FaCheck /> : <FaCopy />}
                </button>
              </div>
            </div>
          )}
          <div className="flex justify-end">
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
            >
              Valider
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminComptableRegister;
