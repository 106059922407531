import React from "react";
import Lottie from "react-lottie";
import animationData from "../Assets/Animations/dlf10_HqLUTu6oSk.json";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    pathOptions: {
      stroke: "black",
      fill: "black",
    },
  };
  return (
    <div className="w-full  ">
      <Lottie
        options={defaultOptions}
        width={300}
        height={300}
        
      />
    </div>
  );
};

export default Loader;
