import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { logOutClient } from "../../redux/actions/clientAction";
import logo from "../../Files/logo/logo.png";

const Header = ({ myDossier }) => {
  const [dossierClient, setDossierClient] = useState("");
  const [isLoading, setIsLoading] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (myDossier.client) {
      // console.log(
      //   "myDossier.client.dossierClient",
      //   myDossier?.client?.dossierClient
      // );
      setDossierClient(myDossier?.client?.dossierClient);
    }
  }, [myDossier]);

  let initiales;
  let name;

  if (dossierClient !== "") {
    name = dossierClient?.name;
    initiales = name?.substring(0, 2);
  }

  useEffect(() => {
    if (isLoading === false) {
      navigate("/login");
    }
  }, [isLoading]);

  const handleLogout = async () => {
    setIsLoading(true);
    await dispatch(logOutClient());
    localStorage.removeItem("clt");
    localStorage.removeItem("keyword");
    setIsLoading(false);
  };

  return (
    <div className="w-full h-[10vh] bg-white  shadow-sm sticky top-0 left-0 z-40 flex items-center justify-between px-3">
      <div className="ml-4 ">
        <Link to="/espaceClient" className="">
          <img src={logo} alt="" className="w-[80px] h-[60px]" />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className=" relative  h-[40px] w-[40px] bg-blue-500 rounded-full flex items-center justify-center cursor-pointer  "
            >
              <span className=" text-white text-xl font-bold uppercase ">
                {initiales}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="z-50 w-full">
              <Dropdown.ItemText className="text-center">
                {name}
              </Dropdown.ItemText>
              <Dropdown.ItemText className="text-center text-sm hover:text-blue-600">
                <Link to={"/modifier-mots-de-passe"}>Changer le MDP</Link>
              </Dropdown.ItemText>

              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout} className="text-center">
                Déconnexion
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
