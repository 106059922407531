import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SupAdminSideBar from "../../Components/SupAdminComponents/SupAdminSideBar.jsx";
import Arborescences from "../../Components/SupAdminComponents/Arborescences.jsx";

import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import SupHeader from "../../Components/SupAdminComponents/SupHeader";
import { loadArboPerCabinet } from "../../redux/actions/supAdmin.js";
import Loader from "../../Utils/Loader";

const SupAdminArboPage = () => {
  const { cabId } = useParams();
  //console.log(cabId);

  const [getUpdated, setGetUpdated] = useState(false);

  const dispatch = useDispatch();
  const arbos = useSelector((state) => state.supAdmin);
  //console.log(arbos);
  //console.log(arbos.loadingArbos);
  //console.log(arbos.arbos?.data);

  const getArbos = async (cabId) => {
    await dispatch(loadArboPerCabinet(cabId));
  };

  useEffect(() => {
    getArbos(cabId);
  }, []);

  useEffect(() => {
    if (getUpdated === true) getArbos(cabId);
  }, [getUpdated]);

  return (
    <div>
      <SupHeader />
      {arbos?.loadingArbos === true ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-end px-3 mb-4 ">
            <Link to={`/supAdmin/${cabId}/add-arbo`}>
              <div className="w-[180px] bg-blue-600 h-[50px] mr-6 mt-3 flex items-center justify-center rounded-xl cursor-pointer hover:bg-blue-700">
                <h1 className="text-[#fff] flex items-center">
                  {" "}
                  Ajouter Arbo <IoIosArrowForward className="ml-1" />
                </h1>
              </div>
            </Link>
          </div>
          <div className="flex ">
            <div className="w-[80px]  800px:w-[280px] mr-[1rem] 800px:mr-[3rem]">
              <SupAdminSideBar active={1} />
            </div>
            <div className="flex flex-wrap ">
              {arbos.arbos?.data?.length > 0 ? (
                arbos.arbos?.data?.map((arbo) => (
                  <Arborescences
                    key={arbo._id}
                    data={arbo}
                    setGetUpdated={setGetUpdated}
                  />
                ))
              ) : (
                <h1 className="text-center">
                  {" "}
                  Aucune arborescence n'a été ajoutée
                </h1>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SupAdminArboPage;
