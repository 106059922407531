import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
};

export const supAdminReducer = createReducer(initialState, {
  LoginSupAdminRequest: (state) => {
    state.loading = true;
  },
  LoginSupAdminSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.supAdmin = action.payload;
  },
  LoginSupAdminFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  // logout collaborateur
  LogoutSupAdminRequest: (state) => {
    state.loading = true;
  },
  LogoutSupAdminSuccess: (state, action) => {
    state.isAuthenticated = false;
    state.loading = false;
    state.supAdmin = action.payload;
  },
  LogoutSupAdminFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // get all comptes pro
  GetAllCompteProRequest: (state) => {
    state.loadingCabinets = true;
  },
  GetAllCompteProSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loadingCabinets = false;
    state.comptePro = action.payload;
  },
  GetAllCompteProFail: (state, action) => {
    state.isAuthenticated = true;
    state.loadingCabinets = false;
    state.error = action.payload;
  },

  // update informations cabinet et admin
  UpdateInfoCabinetRequest: (state) => {
    state.loading = true;
  },
  UpdateInfoCabinetSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.updateComptePro = action.payload;
  },
  UpdateInfoCabinetFail: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.error = action.payload;
  },

  // update activation state cabinet et admin et client
  UpdateCabinetStatelRequest: (state) => {
    state.stateloading = true;
  },
  UpdateCabinetStateSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.stateloading = false;
    state.cabinetState = action.payload;
  },
  UpdateCabinetStateFail: (state, action) => {
    state.stateloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // Add arborescence
  AddArborescenceRequest: (state) => {
    state.AddArblaoding = true;
  },
  AddArborescenceSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.AddArblaoding = false;
    state.arborescence = action.payload;
  },
  AddArborescenceFail: (state, action) => {
    state.AddArblaoding = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // get all arborescence per cabinet
  GetArboRequest: (state) => {
    state.loadingArbos = true;
  },
  GetArboSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loadingArbos = false;
    state.arbos = action.payload;
  },
  GetArboFail: (state, action) => {
    state.isAuthenticated = true;
    state.loadingArbos = false;
    state.error = action.payload;
  },

  // Delete an arbo
  DeleteArboRequest: (state) => {
    state.deleteArboloading = true;
  },
  DeleteArboSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.deleteArboloading = false;
    state.deletedArbo = action.payload;
  },
  DeleteArboFail: (state, action) => {
    state.deleteArboloading = false;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // Add menu client
  AddMenuRequest: (state) => {
    state.addMenuloading = true;
  },
  AddMenuSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.addMenuloading = false;
    state.addMenu = action.payload;
  },
  AddMenuFail: (state, action) => {
    state.addMenuloading = false;
    state.error = action.payload;
    state.isAuthenticated = true;
  },


  // get all arborescence per cabinet
  GetMenuRequest: (state) => {
    state.loadingMenu = true;
  },
  GetMenuSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loadingMenu = false;
    state.menus = action.payload;
  },
  GetMenuFail: (state, action) => {
    state.isAuthenticated = true;
    state.loadingMenu = false;
    state.error = action.payload;
  },

// Delete a menu
DeleteMenuRequest: (state) => {
  state.deleteMenuloading = true;
},
DeleteMenuSuccess: (state, action) => {
  state.isAuthenticated = true;
  state.deleteMenuloading = false;
  state.deletedMenu = action.payload;
},
DeleteMenuFail: (state, action) => {
  state.deleteMenuloading = false;
  state.error = action.payload;
  state.isAuthenticated = true;
},


  // Update  client state
  UpdateClientRequest: (state) => {
    state.clientsloading = true;
  },
  UpdateClientSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.collaborateursloading = false;
    state.updatedClient = action.payload;
  },
  UpdateClientFail: (state, action) => {
    state.usersloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // Update  collaborateur by admin
  UpdateCollaborateurRequest: (state) => {
    state.clientsloading = true;
  },
  UpdateCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.collaborateursloading = false;
    state.updatedCollaborateur = action.payload;
  },
  UpdateCollaborateurFail: (state, action) => {
    state.usersloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
