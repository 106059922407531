import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import Store from "./redux/store";

import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  <Provider store={Store}>
    <App />
  </Provider>
);

reportWebVitals();
