import React, { useState } from "react";
import { HiSearch, HiFolder, HiXCircle } from "react-icons/hi";
import { Link } from "react-router-dom";

import CabinetHeader from "../../Components/ComptableComponents/CabinetHeader.jsx";
import ListClients from "../../Components/ComptableComponents/ListClients.jsx";

const ComptableDashboardPage = () => {
  const [searchClient, setSearchClient] = useState(
    localStorage.getItem("searchClient") || ""
  );

  const reloadPage = () => {
    window.location.reload();
  };

  const onChangeValue = (e) => {
    localStorage.setItem("searchClient", e.target.value);
    setSearchClient(e.target.value);
  };

  const clearSearch = () => {
    localStorage.setItem("searchClient", "");
    setSearchClient("");
  };
  return (
    <div className="">
      <CabinetHeader />
      <div className="mt-3 w-10/12 mx-auto">
        <div className="items-center flex justify-between text-center">
          <h1
            onClick={reloadPage}
            className="font-bold 800px:text-3xl text-xl py-3 800px:ml-12 text-blue-600 cursor-pointer"
          >
            {" "}
            Clients{" "}
          </h1>

          <div className="relative 800px:mx-4 800px:w-[250px] w-[160px]">
            <div className="flex items-center">
              <input
                type="text"
                value={searchClient}
                onChange={onChangeValue}
                placeholder="Recherche client..."
                className="py-2 pl-7 pr-4 border rounded-md text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm w-full"
              />
              {searchClient ? (
                <div
                  className="absolute left-2 cursor-pointer"
                  onClick={clearSearch}
                >
                  <HiXCircle size={18} className="text-gray-500" />
                </div>
              ) : (
                <div className="absolute left-2">
                  <HiSearch size={18} className="text-gray-500" />
                </div>
              )}
            </div>
          </div>
        </div>
        <ListClients searchClient={searchClient} />
      </div>
    </div>
  );
};

export default ComptableDashboardPage;
