import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useState } from "react";
import { Modal, Form } from "react-bootstrap";

import { AiOutlineDelete, AiOutlineEye, AiFillEdit } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  allClientsCabinetCollaborateur,
  deleteOneClientAndDossierClient,
  updateClientState,
} from "../../redux/actions/collaborateurAction";
import Loader from "../../Utils/Loader";

const AllClients = () => {
  const [clients, setClients] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalType, setModalType] = useState({});
  const [specificClient, setSpecificClient] = useState("");
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showModalClientState, setShowModalClientState] = useState(false);
  const [clientState, setClientState] = useState("");
  const [isLoading, setIsLoading] = useState();
  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));
  const cabId = collaborateurStore?.user.cabinet;

  //console.log("cabId", cabId);

  const dispatch = useDispatch();

  const allClients = useSelector((state) => state.collaborateur);
  //console.log("allClients", allClients);

  // Get all clients
  const handleDispatchGetAllClients = async () => {
    // //console.log("isLoading1", isLoading);
    await dispatch(allClientsCabinetCollaborateur(cabId));
  };

  useEffect(() => {
    if (allClients?.clientsCabinet?.clients) {
      setClients(allClients?.clientsCabinet?.clients);
      //console.log(allClients?.clientsCabinet?.clients);
    }
  }, [allClients]);

  useEffect(() => {
    setIsLoading(true);
    handleDispatchGetAllClients();
    setIsLoading(false);
  }, [dispatch]);
  //console.log("isLoading2", isLoading);

  let active;
  const handleConfirmAction = async () => {
    const id = specificClient?.id;
    if (id === undefined || id === null || id === "") {
      return toast.warn("Merci de cliquer sur un client");
    }
    if (modalType.type === "suppression") {
      //console.log("specificClient", specificClient);
      setShowLoadingModal(true);
      setShowConfirmModal(false);

      await dispatch(deleteOneClientAndDossierClient(id));
      setShowLoadingModal(false);

      handleDispatchGetAllClients();
    }
    if (modalType.type === "Modification de l'état d'activation du compte") {
      //console.log("specificClient", specificClient);
      active = clientState === "Active" ? true : false;

      setShowLoadingModal(true);
      setShowConfirmModal(false);
      setShowModalClientState(false);

      await dispatch(updateClientState(id, active));
      setClientState("");
      setShowLoadingModal(false);

      handleDispatchGetAllClients();
      //console.log("updatedClient", allClients?.deletedCollaborateur?.data);
    }
  };
  // Delete one client

  const handleDelete = async (params) => {
    //console.log("params", params.row);
    await setSpecificClient(params.row);
    await setModalType({
      type: "suppression",
      confirmation: "supprimer",
      message:
        "Attention, la suppression du client entraînera la suppression définitive de l'ensemble du dossier client.",
    });

    setShowConfirmModal(true);
  };

  // Update client state

  const updateState = async (params) => {
    setSpecificClient(params.row);
    //console.log("paramsRow", params.row);
    //console.log("setSpecificClient", params.row);
    // //console.log("state", state);
    setShowModalClientState(true);
  };

  const closeModalClientState = () => {
    setShowModalClientState(false);
    setClientState("");
  };

  const handleUpdate = async () => {
    //console.log("clientState", clientState);
    //console.log("specificClient", specificClient.Active);
    if (clientState !== "Active" && clientState !== "Inactive") {
      return toast.warn("Merci de choisir une option");
    }

    const clientStateBoolean = clientState === "Active"; // Convert to boolean

    if (clientStateBoolean === specificClient.Active) {
      const message = clientStateBoolean ? "activé" : "désactivé";
      return toast.warn(`Le compte est déjà ${message}`);
    }
    setShowConfirmModal(true);
    const confirmation =
      clientState === "Active"
        ? "activer"
        : clientState === "Inactive"
        ? "désactiver"
        : "";
    await setModalType({
      type: "Modification de l'état d'activation du compte",
      confirmation: confirmation,
    });
  };

  const columns = [
    {
      field: "Société",
      headerName: "Société",
      minWidth: 40,
      flex: 0.7,
    },
    {
      field: "Gérant",
      headerName: "Gérant",
      minWidth: 40,
      flex: 0.7,
    },

    {
      field: "Téléphone",
      headerName: "Téléphone",
      minWidth: 100,
      flex: 0.7,
    },

    {
      field: "Email",
      headerName: "Email",
      minWidth: 100,
      flex: 0.7,
    },
    {
      field: "Active",
      headerName: "Active",
      minWidth: 40,
      flex: 0.6,
      renderCell: (params) => {
        const { Active } = params?.row;

        if (Active === true) {
          return <FaCheckCircle size={20} color="green" />;
        } else if (Active === false) {
          return <FaTimesCircle size={20} color="red" />;
        }

        return "État inconnu";
      },
    },
    {
      field: "Responsable_dossier",
      headerName: "Responsable dossier",
      minWidth: 100,
      flex: 0.7,
    },
    {
      field: "Voir/Modifier",
      flex: 0.7,
      minWidth: 100,
      headerName: "Voir/Modifier",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/client/${params.id}`}>
              <Button>
                <AiOutlineEye /> / <AiFillEdit />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Désactiver",
      flex: 0.7,
      minWidth: 100,
      headerName: "Désactiver",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        const { Active } = params?.row;

        if (Active === true) {
          return (
            <Button onClick={() => updateState(params)}>Désactiver</Button>
          );
        } else if (Active === false) {
          return <Button onClick={() => updateState(params)}>Activer</Button>;
        }

        return "État inconnu";
      },
    },

    {
      field: "Supprimer",
      flex: 0.7,
      minWidth: 100,
      headerName: "Supprimer",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  clients &&
    clients?.forEach((item) => {
      row.push({
        id: item?._id,
        Société: item?.name,
        Gérant: item?.dirigeantName,
        Téléphone: item?.societePhone,
        Email: item?.emailConnexion,
        Active: item?.active,
        Responsable_dossier: item?.responsableDossier,
      });
    });

  return (
    <>
      <div className="w-full mx-8 pt-1 mt-10 bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>

      <Modal
        show={showConfirmModal}
        dialogClassName="max-w-[700px] top-1/4"
        className=" px-2 bg-transparent "
        onHide={() => setShowConfirmModal(false)}
      >
        {" "}
        {/* Ajouter la classe "modal-lg" pour le rendre plus large */}
        <Modal.Header>
          <Modal.Title className="text-sm font-bold">
            Confirmation : {modalType.type} {specificClient?.Société}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setShowConfirmModal(false)}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="mb-2 text-sm">
            {" "}
            Êtes vous sur de vouloir {modalType.confirmation}{" "}
            <span className="font-bold">{specificClient?.Société}</span> ?{" "}
            <br />
            <br />
            {modalType.message}
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={() => setShowConfirmModal(false)}
          >
            Annuler
          </Button>
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            onClick={handleConfirmAction}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalClientState} onHide={closeModalClientState}>
        <Modal.Header>
          <Modal.Title className="text-sm">
            Modifier l'état d'activation du compte {specificClient?.Société}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeModalClientState}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formResponsableName">
            <select
              name="clientState"
              value={clientState}
              onChange={(event) => setClientState(event.target.value)}
              className="block ml-2 w-70 cursor-pointer px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="">Etat du compte</option>
              <option value="Active">Activer</option>
              <option value="Inactive">Désactiver</option>
            </select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 hover:bg-gray-700"
            onClick={closeModalClientState}
          >
            Annuler
          </Button>
          <Button className="bg-blue-500" onClick={handleUpdate}>
            Mettre à jour
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </>
  );
};

export default AllClients;
