import axios from "axios";
import { server } from "../../server";

export const loadMyDossierClient = () => async (dispatch) => {
  try {
    //console.log("hello from action getMe");
    dispatch({
      type: "LoadMyDossierClientRequest",
    });
    const { data } = await axios.get(
      `${server}/client/getMe`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "LoadMyDossierClientSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "LoadMyDossierClientFail",
      payload: error.response?.data?.message,
    });
  }
};

export const loginClient = (emailConnexion, password) => async (dispatch) => {
  try {
    //console.log("hello from action login");
    dispatch({
      type: "LoginClientRequest",
    });
    const { data } = await axios.post(
      `${server}/client/login-client`,
      { emailConnexion, password },

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "LoginClientSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "LoginClientFail",
      payload:
        error.response?.data?.message || error.response?.data?.errors[0].msg,
    });
  }
};

export const logOutClient = () => async (dispatch) => {
  try {
    //console.log("hello from action logout");
    dispatch({
      type: "LogOutClientRequest",
    });
    const { data } = await axios.get(
      `${server}/client/logout`,

      {
        withCredentials: true,
      }
    );
    //console.log("data logout", data);
    dispatch({
      type: "LogOutClientSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "LogOutClientFail",
      payload: error?.response?.data?.message,
    });
  }
};

// get  a Specific menuclient
export const loadSpcificMenuClient = (menuId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetSpecificMenuRequest",
    });
    //console.log("req from action get categories");
    const { data } = await axios.get(
      `${server}/supAdmin/get-menuClient/${menuId}`,

      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "GetSpecificMenuSuccess",
      payload: data,
    });
    //console.log(data);
  } catch (error) {
    dispatch({
      type: "GetSpecificMenuFail",
      payload: error?.response?.data?.message,
    });
  }
};

// Update client password
export const updateClientPassword =
  (oldPassword, newPassword, confirmPassword) => async (dispatch) => {
    try {
      dispatch({
        type: "UpdateClientPasswordRequest",
      });

      const { data } = await axios.put(
        `${server}/client/update-client-password`,
        { oldPassword, newPassword, confirmPassword },
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "UpdateClientPasswordSuccess",
        payload: data,
      });
      //console.log(data);
    } catch (error) {
      dispatch({
        type: "UpdateClientPasswordFail",
        payload: error?.response?.data?.message,
      });
    }
  };
