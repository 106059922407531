import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
};

export const clientReducer = createReducer(initialState, {
  // load a specific client by collaborateur

  LoginClientRequest: (state) => {
    state.loading = true;
  },
  LoginClientSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.client = action.payload;
  },
  LoginClientFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  LoadMyDossierClientRequest: (state) => {
    state.loading = true;
  },
  LoadMyDossierClientSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.client = action.payload;
  },
  LoadMyDossierClientFail: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.error = action.payload;
  },

  LogoutClientRequest: (state) => {
    state.loading = true;
  },
  LogoutClientSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.client = action.payload;
  },
  LogoutClientFail: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.error = action.payload;
  },

  GetSpecificMenuRequest: (state) => {
    state.getMenuLoading = true;
  },
  GetSpecificMenuSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.getMenuLoading = false;
    state.specificMenu = action.payload;
  },
  GetSpecificMenuFail: (state, action) => {
    state.isAuthenticated = true;
    state.getMenuLoading = false;
    state.error = action.payload;
  },
  UpdateClientPasswordRequest: (state) => {
    state.loading = true;
  },
  UpdateClientPasswordSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.updatedClientPassword = action.payload;
  },
  UpdateClientPasswordFail: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
