import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../Styles/Styles";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { loginSupAdmin } from "../../redux/actions/supAdmin";

const LoginSupAdmin = () => {
  const [identifiant, setIdentifiant] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.supAdmin);

  const alreadyConnected = JSON.parse(localStorage.getItem("SupAdmin"));

  useEffect(() => {
    if (alreadyConnected !== null) {
      navigate("/supAdmin/dashboard");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (identifiant === "" || password === "") {
      toast.error("Merci de saisir vos informations de connexion");
      return;
    }

    setLoading(true);
    await dispatch(loginSupAdmin(identifiant, password));
    setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      //console.log(data);
      if (
        data?.isAuthenticated === true &&
        data.supAdmin?.success === true &&
        data.supAdmin?.user?.active !== false
      ) {
        toast.success("Connexion résussie!");
        setIdentifiant("");
        setPassword("");
        navigate("/supAdmin/dashboard");
        localStorage.setItem("SupAdmin", JSON.stringify(data?.supAdmin?.user));
      } else if (data.supAdmin?.user?.active === false) {
        setIdentifiant("");
        setPassword("");
        return alert("Votre compte a été désactivé par un administrateur");
      } else if (!data?.isAuthenticated && data?.error) {
        alert.error(data?.error);
      }
    }
  }, [loading]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="identifiant"
                className="block text-sm font-medium text-gray-700"
              >
                Identifiant
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="identifiant"
                  autoComplete="identifiant"
                  value={identifiant}
                  placeholder="Veuillez saisir votre identifiant"
                  onChange={(e) => setIdentifiant(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Mots de passe
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  placeholder="Veuillez saisir votre mots de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  title="Veuillez remplir ce champ"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginSupAdmin;
