import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logoutCollaborateur } from "../../redux/actions/collaborateurAction";
import logo from "../../Files/logo/logo.png";

const CabinetHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const collaborateurStore = JSON.parse(localStorage.getItem("collaborateur"));
  const role = collaborateurStore?.user?.role;
  let initiales;
  let name;
  let prenom;
  let service;
  if (collaborateurStore) {
    name = collaborateurStore.user?.name;
    prenom = collaborateurStore.user?.prenom;
    const nameFirstLetter = name.charAt(0);
    const prenomFirstLetter = prenom.charAt(0);
    initiales = nameFirstLetter + prenomFirstLetter;
    service = collaborateurStore.user?.service;
  }

  

  const handleLogout = async () => {
    await dispatch(logoutCollaborateur());
    localStorage.removeItem("collaborateur");
    localStorage.removeItem("searchClient");
    localStorage.removeItem("searchClient");
    navigate("/login-pro");
  };

  return (
    <div className="w-full h-[10vh] bg-white  shadow-sm sticky top-0 left-0 z-40 flex items-center justify-between px-10">
      <div>
        <Link to="/dashboard">
          <img src={logo} alt="" className="w-[80px] h-[60px]" />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className=" relative  h-[40px] w-[40px] bg-blue-500 rounded-full flex items-center justify-center cursor-pointer  "
            >
              <span className=" text-white text-xl font-bold uppercase ">
                {initiales}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="z-50">
              <Dropdown.ItemText className="text-center">
                {name} {prenom}
              </Dropdown.ItemText>
              <Dropdown.ItemText className="text-center">
                {role}
              </Dropdown.ItemText>
              <Dropdown.ItemText className="text-center">
                {service}
              </Dropdown.ItemText>

              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout} className="text-center">
                Déconnexion
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default CabinetHeader;
