import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
};

export const foldersReducer = createReducer(initialState, {
  CreateFolderRequest: (state) => {
    state.loading = true;
  },
  CreateFolderSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.folder = action.payload;
  },
  CreateFolderFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },
  CreateParentFolderRequest: (state) => {
    state.loading = true;
  },
  CreateParentSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.parentFolder = action.payload;
  },
  CreateParentFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  RenameFolderRequest: (state) => {
    state.loading = true;
  },
  RenameFolderSuccess: (state, action) => {
    state.loading = false;
    state.folderRename = action.payload;
  },
  RenameFolderFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  RenameRacineFolderRequest: (state) => {
    state.loading = true;
  },
  RenameRacineFolderSuccess: (state, action) => {
    state.loading = false;
    state.racineFolderRename = action.payload;
  },
  RenameRacineFolderFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },


  MoveFolderRequest: (state) => {
    state.loading = true;
  },
  MoveFolderSuccess: (state, action) => {
    state.loading = false;
    state.movedFolder = action.payload;
  },
  MoveFolderFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  MoveRacineFolderRequest: (state) => {
    state.loading = true;
  },
  MoveRacineFolderSuccess: (state, action) => {
    state.loading = false;
    state.movedRacineFolder = action.payload;
  },
  MoveRacineFolderFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  DeleteFolderRequest: (state) => {
    state.loading = true;
  },
  DeleteFolderSuccess: (state, action) => {
    state.loading = false;
    state.deletedFolder = action.payload;
  },
  DeleteFolderFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  DeleteRootFolderRequest: (state) => {
    state.loading = true;
  },
  DeleteRootFolderSuccess: (state, action) => {
    state.loading = false;
    state.deletedRootFolder = action.payload;
  },
  DeleteRootFolderFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
