import React, { useEffect, useState } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Modal, Form, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import {
  FaFilePdf,
  FaFileExcel,
  FaRegFilePowerpoint,
  FaExchangeAlt,
  FaCloudDownloadAlt,
  FaUserTie,
} from "react-icons/fa";
import {
  AiFillFileText,
  AiOutlineFileWord,
  AiFillEdit,
  AiFillInfoCircle,
} from "react-icons/ai";
import { backend_url_files } from "../../server";
import { useDispatch, useSelector } from "react-redux";
import {
  renameFile,
  deleteFileAction,
  changeCatFile,
} from "../../redux/actions/fileAction";
import Loader from "../../Utils/Loader";

const ClientContent = ({
  categoryFiles,
  setIsUpdated,
  myDossier,
  categories,
  selectedCategory,
  isOpen,
  keyword,
}) => {
  const [clientFiles, setClientFiles] = useState([]);
  const [dossierClient, setDossierClient] = useState("");
  const [name, setName] = useState("");
  const [clickedFile, setClickedFile] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [showTextHoverEdit, setShowTextHoverEdit] = useState(false);
  const [showTextHoverDownLoad, setShowTextHoverDownLoad] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [loadingChangeCatFile, setLoadingChangeCatFile] = useState();
  const [loadingRename, setLoadingRename] = useState();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showConfirmMoveModal, setShowConfirmMoveModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [showTextHoverMove, setShowTextHoverMove] = useState(false);
  const [showTextHoverdelete, setShowTextHoverdelete] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showTextHoverInfo, setShowTextHoverInfo] = useState(false);
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const [renameModalFolder, setRenameModalFolder] = useState(false);
  const [loadingDeleteFile, setLoadingDeleteFile] = useState();
  const [clickedCat, setClickedCat] = useState(null);

  const dispatch = useDispatch();
  const fileRes = useSelector((state) => state.files);
  //console.log("categoryFiles content", categoryFiles);

  const { catId } = useParams();

  useEffect(() => {
    if (myDossier?.client) {
      // console.log(
      //   "myDossier.client.dossierClient",
      //   myDossier?.client?.dossierClient
      // );
      setDossierClient(myDossier?.client?.dossierClient);
    }
  }, [myDossier?.client]);

  let id;

  if (dossierClient !== "" && dossierClient?.files?.length > 0) {
    id = dossierClient.client;
  }

  ////// Rename file

  const handleClickedFile = (selectedFile) => {
    setClickedFile(selectedFile);
    setName(
      selectedFile?.name?.substring(0, selectedFile?.name.lastIndexOf("."))
    );
  };

  useEffect(() => {
    //console.log("clickedFile", clickedFile);
  }, [clickedFile]);

  const handleRenameModal = () => {
    if (clickedFile.importedBy !== "Client") {
      return alert(
        "Vous ne pouvez pas renomer les fichiers importés par des collaborateurs"
      );
    }

    setRenameModal(true);
  };

  const closeModalFile = () => {
    if (renameModal !== false) {
      setRenameModal(false);
      setModalOpen(false);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (dossierClient !== "" && dossierClient?.files?.length > 0) {
      setClientFiles(
        dossierClient.files.find((dos) => dos.name === "Documents Client").files
      );
    }
  }, [dossierClient]);

  const HandleRenameFile = async () => {
    if (name === "") {
      return alert("Merci de saisir le nom");
    }

    if (
      clientFiles.some(
        (file) =>
          file.name.toLowerCase() ===
          name.toLowerCase() + "." + clickedFile.name.split(".").pop()
      )
    ) {
      return alert(
        `Le nom "${name}" est déjà utilisé par un autre fichier dans votre espace client.`
      );
    }

    setLoadingRename(true);
    setShowLoadingModal(true);
    setRenameModal(false);
    await dispatch(
      renameFile(
        id,
        clickedFile._id,
        name + "." + clickedFile.name.split(".").pop(),
        "Client",
        new Date()
      )
    );
    setName("");
    setIsUpdated(true);
    setLoadingRename(false);
    setShowLoadingModal(false);
  };

  useEffect(() => {
    if (loadingRename === false) {
      if (fileRes && fileRes.file && fileRes.file.success === true) {
        toast.success("Modification effectuée");
      } else if (
        fileRes.error &&
        fileRes.error?.msg === "Merci de saisir le nouveau nom"
      ) {
        toast.warn("Merci de saisir le nouveau nom");
        //console.log("Merci de saisir le nouveau nom");
      }
    }
  }, [loadingRename]);

  const handleCloseModalInfo = () => {
    if (renameModal !== false) {
      setRenameModal(false);
      setModalInfoOpen(false);
    } else if (renameModalFolder !== false) {
      setRenameModalFolder(false);
      setModalInfoOpen(false);
    }
    setModalInfoOpen(false);
  };

  const handleDownload = () => {
    setModalOpen(true);
    setTimeout(() => {
      setModalOpen(false);
    }, 1000);
  };

  ////// Delete file

  const handleDeleteModal = () => {
    if (clickedFile.importedBy !== "Client") {
      return alert(
        "Vous ne pouvez pas supprimer les fichiers importés par des collaborateurs"
      );
    }

    setShowConfirmDeleteModal(true);
  };

  const handleDelete = async () => {
    setLoadingDeleteFile(true);
    setShowLoadingModal(true);
    setShowConfirmDeleteModal(false);

    await dispatch(deleteFileAction(id, clickedFile?._id));
    setIsUpdated(true);
    setLoadingDeleteFile(false);
    setShowLoadingModal(false);
  };

  useEffect(() => {
    if (loadingDeleteFile === false) {
      if (
        fileRes &&
        fileRes.deletedFile &&
        fileRes.deletedFile.success === true &&
        fileRes.deletedFile.data
      ) {
        toast.success(
          `Le fichier ${fileRes.deletedFile.data.name} a bien été supprimé`
        );
      } else if (fileRes && fileRes.error) {
        toast.warn(fileRes.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [loadingDeleteFile]);

  // move file to another category

  const handleCloseMoveModal = () => {
    setShowMoveModal(false);
    setClickedCat(null);
  };

  const handlePrepareMove = (categoryId) => {
    //console.log("categoryId", categoryId);
    setClickedCat(categoryId);
  };
  let category;

  if (categories?.length > 0 && clickedCat !== null) {
    category = categories.find((cat) => cat._id === clickedCat);
  }

  const handleMoveFileFolder = async () => {
    // clickedCat
    if (catId === clickedCat) {
      setShowConfirmMoveModal(false);
      return alert(
        `Ce fichier se trouve déjà dans la rubrique "${category?.name}"`
      );
    }
    setLoadingChangeCatFile(true);
    setShowLoadingModal(true);
    handleCloseMoveModal();
    setShowConfirmMoveModal(false);
    await dispatch(changeCatFile(id, clickedFile._id, clickedCat));
    setLoadingChangeCatFile(false);
    setShowLoadingModal(false);
    setIsUpdated(true);
  };

  useEffect(() => {
    if (loadingChangeCatFile === false) {
      //console.log("fileRes", fileRes);
      if (
        fileRes &&
        fileRes.fileChangeCat &&
        fileRes.fileChangeCat.success === true &&
        fileRes.fileChangeCat.message
      ) {
        toast.success(fileRes.fileChangeCat.message);
      } else if (fileRes && fileRes.error) {
        toast.warn(fileRes.error);
        dispatch({ type: "clearErrors" });
      }
    }
  }, [loadingChangeCatFile]);

  return (
    <div>
      {catId === undefined ? (
        <h1 className="mt-4 px-4">
          Espace Client : Vous pouvez consulter vos documents en parcourant les
          différentes catégories à votre disposition dans le menu.
        </h1>
      ) : (
        <div className="flex flex-col ">
          {keyword !== "" ? (
            <h2 className="text-center mt-2">
              Résultat de recherche pour "
              <span className="font-bold">{keyword}</span>"{" "}
              {selectedCategory
                ? `dans la rubrique ${selectedCategory}`
                : "dans cette rubrique"}
            </h2>
          ) : (
            <h1 className="800px:pl-6 800px:pt-10  pt-4 pl-2 font-bold italic">
              {" "}
              {selectedCategory}{" "}
            </h1>
          )}

          <div className="flex flex-wrap mt-4 px-4 ">
            {catId !== undefined && categoryFiles?.length === 0 ? (
              keyword !== "" ? (
                <h1>
                  Il n'y a aucun document dans cette rubrique contenant{" "}
                  <span className="font-bold">{keyword}</span>"{" "}
                </h1>
              ) : (
                <h1>Il n'y a aucun document dans cette rubrique</h1>
              )
            ) : (
              <div className="flex flex-wrap mt-4 px-4 ">
                {categoryFiles?.map((file) => (
                  <div
                    key={file._id}
                    onClick={() => {
                      handleClickedFile(file);
                      setModalOpen(true);
                    }}
                    className="mb-4 ml-6 flex flex-col justify-center items-center cursor-pointer transition-all hover:scale-105"
                  >
                    <div
                      className={`relative w-[60px] h-[70px] ${
                        file.importedBy !== "Client" ? "border-2" : ""
                      }`}
                    >
                      {file.fileType === "application/pdf" ? (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <div className="w-full h-full">
                            <Viewer
                              fileUrl={`${backend_url_files}${file.path.replace(
                                "backend/dossier/",
                                ""
                              )}`}
                              defaultScale={SpecialZoomLevel.PageFit}
                            />
                            <div className="absolute bottom-0 right-0 text-red-600">
                              <FaFilePdf size={22} />
                            </div>
                          </div>
                        </Worker>
                      ) : file.fileType.startsWith("image/") ? (
                        <img
                          src={`${backend_url_files}${file.path.replace(
                            "backend/dossier/",
                            ""
                          )}`}
                          alt="file"
                          className="w-[60px] h-[70px]"
                        />
                      ) : (
                        <div className="flex justify-center items-center">
                          {file.fileType ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                          file.fileType === "application/vnd.ms-excel" ? (
                            <div className="w-[60px] h-[70px] flex justify-center items-center  rounded-sm">
                              <FaFileExcel size={45} color="#217346" />
                            </div>
                          ) : file.fileType ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                            <div className="w-[60px] h-[70px] flex justify-center items-center  rounded-sm">
                              <AiOutlineFileWord size={50} color="#1A73E8" />
                            </div>
                          ) : file.fileType ===
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                            file.fileType === "application/vnd.ms-powerpoint" ||
                            file.fileType === "application/octet-stream" ? (
                            <div className="w-[60px] h-[70px] flex justify-center items-center  rounded-sm">
                              <FaRegFilePowerpoint size={45} color="#D24726" />
                            </div>
                          ) : (
                            <div className="w-[60px] h-[70px] flex justify-center items-center  rounded-sm">
                              <AiFillFileText size={45} />
                            </div>
                          )}
                        </div>
                      )}

                      {file.importedBy !== "Client" && (
                        <div className="absolute top-[0] left-0 text-blue-700">
                          <FaUserTie size={18} />
                        </div>
                      )}
                    </div>

                    <span className="text-sm break-all h-10 text-center w-[80px]">
                      {file?.name.lastIndexOf(".") > 0
                        ? file.name.substring(0, file.name.lastIndexOf("."))
                            .length > 16
                          ? file.name
                              .substring(0, file.name.lastIndexOf("."))
                              .substring(0, 16) + ".."
                          : file.name.substring(0, file.name.lastIndexOf("."))
                        : file.name.length > 16
                        ? file.name.substring(0, 16) + ".."
                        : file.name}
                    </span>
                  </div>
                ))}
              </div>
            )}
            <Modal
              size={
                clickedFile.fileType?.startsWith("image/") ||
                clickedFile.fileType === "application/pdf"
                  ? "xl"
                  : "md"
              }
              dialogClassName={`${
                !clickedFile.fileType?.startsWith("image/") &&
                clickedFile.fileType !== "application/pdf"
                  ? "top-1/4"
                  : ""
              } `}
              show={modalOpen}
              onHide={() => setModalOpen(false)}
            >
              <Modal.Header>
                <div className="flex  ">
                  <Modal.Title className="text-sm">
                    {clickedFile?.name}
                  </Modal.Title>
                  <div className="relative">
                    {showTextHoverEdit && (
                      <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                        Renomer
                      </span>
                    )}
                    <AiFillEdit
                      className="cursor-pointer ml-1"
                      size={20}
                      color="rgb(59 130 246)"
                      onClick={handleRenameModal}
                      onMouseOver={() => setShowTextHoverEdit(true)}
                      onMouseLeave={() => setShowTextHoverEdit(false)}
                    />
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="flex space-x-2 mr-3">
                    <div className="relative">
                      {showTextHoverDownLoad && (
                        <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                          Télécharger
                        </span>
                      )}
                      <Link
                        to={`${backend_url_files}${clickedFile?.path?.replace(
                          "backend/dossier/",
                          ""
                        )}`}
                        target={
                          clickedFile?.fileType?.startsWith("image/") ||
                          clickedFile?.fileType === "application/pdf"
                            ? "_blank"
                            : ""
                        }
                        download={clickedFile?.name}
                        onClick={handleDownload}
                      >
                        <FaCloudDownloadAlt
                          className="cursor-pointer"
                          size={20}
                          color="rgb(59, 130, 246)"
                          onMouseOver={() => setShowTextHoverDownLoad(true)}
                          onMouseLeave={() => setShowTextHoverDownLoad(false)}
                        />
                      </Link>
                    </div>

                    <div className="relative">
                      {showTextHoverMove && (
                        <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                          Déplacer
                        </span>
                      )}
                      <FaExchangeAlt
                        className="cursor-pointer"
                        size={19}
                        color="rgb(59 130 246)"
                        onClick={() => setShowMoveModal(true)}
                        onMouseOver={() => setShowTextHoverMove(true)}
                        onMouseLeave={() => setShowTextHoverMove(false)}
                      />
                    </div>

                    <div className="relative">
                      {showTextHoverdelete && (
                        <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                          Supprimer
                        </span>
                      )}
                      <MdDelete
                        className="cursor-pointer"
                        size={20}
                        color="rgb(59 130 246)"
                        onClick={handleDeleteModal}
                        onMouseOver={() => setShowTextHoverdelete(true)}
                        onMouseLeave={() => setShowTextHoverdelete(false)}
                      />
                    </div>
                    <div className="relative">
                      {showTextHoverInfo && (
                        <span className="absolute top-[-30px] left-1/4 transform -translate-x-1/2 text-sm bg-gray-500 text-white px-2 py-1 rounded">
                          Infos
                        </span>
                      )}
                      <AiFillInfoCircle
                        onClick={() => setModalInfoOpen(true)}
                        color="rgb(59 130 246)"
                        className="cursor-pointer"
                        size={20}
                        onMouseOver={() => setShowTextHoverInfo(true)}
                        onMouseLeave={() => setShowTextHoverInfo(false)}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="text-gray-500 hover:text-gray-700"
                    onClick={closeModalFile}
                  >
                    <svg
                      className="w-6 h-6 fill-current"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                      />
                    </svg>
                  </button>
                </div>
              </Modal.Header>

              <Modal.Body
                className={`${
                  clickedFile.fileType === "application/pdf"
                    ? ""
                    : "flex justify-center items-center"
                }`}
              >
                {clickedFile.type === "file" &&
                clickedFile.fileType.startsWith("image/") ? (
                  <img
                    src={`${backend_url_files}${clickedFile.path.replace(
                      "backend/dossier/",
                      ""
                    )}`}
                    alt="file"
                    className="w-100"
                  />
                ) : clickedFile.fileType === "application/pdf" ? (
                  <div
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      height: "750px",
                    }}
                  >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={`${backend_url_files}${clickedFile.path.replace(
                          "backend/dossier/",
                          ""
                        )}`}
                        defaultScale={SpecialZoomLevel.PageFit}
                      />
                    </Worker>
                  </div>
                ) : clickedFile.fileType === "application/pdf" ? (
                  <div
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      height: "750px",
                    }}
                  >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={`${backend_url_files}${clickedFile.path.replace(
                          "backend/dossier/",
                          ""
                        )}`}
                        defaultScale={SpecialZoomLevel.PageFit}
                      />
                    </Worker>
                  </div>
                ) : clickedFile.fileType ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                  clickedFile.fileType === "application/vnd.ms-excel" ? (
                  <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                    <FaFileExcel size={45} color="#217346" />
                  </div>
                ) : clickedFile.fileType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                  <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                    <AiOutlineFileWord size={50} color="#1A73E8" />
                  </div>
                ) : clickedFile.fileType ===
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                  clickedFile.fileType === "application/vnd.ms-powerpoint" ||
                  clickedFile.fileType === "application/octet-stream" ? (
                  <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                    <FaRegFilePowerpoint size={45} color="#D24726" />
                  </div>
                ) : (
                  <div className="w-[60px] h-[70px] flex justify-center items-center border-2 rounded-sm">
                    <AiFillFileText size={45} />
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <Modal
              size="auto"
              show={renameModal}
              onHide={() => setRenameModal(false)}
              style={{ marginTop: "10%" }}
            >
              <Modal.Header>
                <Modal.Title className="text-sm">
                  Modifier le nom du fichier{" "}
                  <span className="font-bold">{clickedFile?.name}</span>
                </Modal.Title>

                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setRenameModal(false)}
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                    />
                  </svg>
                </button>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formFileName">
                    <Form.Control
                      type="text"
                      placeholder="Entrez le nouveau nom "
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="bg-gray-500 hover:bg-gray-700"
                  onClick={() => setRenameModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  className="bg-blue-500 hover:bg-blue-700"
                  onClick={HandleRenameFile}
                >
                  Valider
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showConfirmDeleteModal}
              dialogClassName="max-w-[700px] top-1/4"
              className=" px-2 bg-transparent "
              onHide={() => setShowConfirmDeleteModal(false)}
            >
              {" "}
              {/* Ajouter la classe "modal-lg" pour le rendre plus large */}
              <Modal.Header>
                <Modal.Title className="text-sm font-bold">
                  Confirmation suppression {clickedFile?.name}
                </Modal.Title>
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setShowConfirmDeleteModal(false)}
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                    />
                  </svg>
                </button>
              </Modal.Header>
              <Modal.Body>
                <h1 className="mb-2 text-sm">
                  {" "}
                  Êtes vous sur de vouloir supprimer{" "}
                  {clickedFile?.type === "file"
                    ? "le fichier"
                    : "le dossier"}{" "}
                  <span className="font-bold">{clickedFile?.name}</span> ?{" "}
                  <br />
                  <br />
                  {clickedFile?.type === "folder"
                    ? "Attention la suppression de ce dossier entraine la suppression défénitive de tous les éléments qu'il contient."
                    : null}
                </h1>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="bg-gray-500 hover:bg-gray-700"
                  onClick={() => setShowConfirmDeleteModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  className="bg-blue-500 hover:bg-blue-700"
                  onClick={handleDelete}
                >
                  Confirmer
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              size="auto"
              dialogClassName=" top-1/4"
              show={modalInfoOpen}
              onHide={() => setModalInfoOpen(false)}
            >
              <Modal.Header>
                <div className="flex ">
                  <Modal.Title className="text-sm">
                    Informations{" "}
                    {clickedFile?.type === "folder" ? "Dossier" : "fichier"}
                  </Modal.Title>
                </div>
                <div className="flex items-center">
                  <button
                    type="button"
                    className="text-gray-500 hover:text-gray-700"
                    onClick={handleCloseModalInfo}
                  >
                    <svg
                      className="w-6 h-6 fill-current"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                      />
                    </svg>
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="flex  flex-col px-2  space-y-2 ">
                  <h1>
                    {" "}
                    Nom :{" "}
                    <span className="text-gray-500">
                      {clickedFile?.name}
                    </span>{" "}
                  </h1>
                  {clickedFile.type === "file" ? (
                    <div>
                      <h1 className="mb-2">
                        {" "}
                        Importé par :{" "}
                        <span className="text-gray-500">
                          {clickedFile?.createdBy}
                        </span>{" "}
                      </h1>
                      <h1>
                        {" "}
                        Importé le :{" "}
                        <span className="text-gray-500">
                          {clickedFile?.createdAt?.substring(0, 10) || null}
                        </span>{" "}
                      </h1>{" "}
                    </div>
                  ) : (
                    <div>
                      <h1 className="mb-2">
                        {" "}
                        Description :{" "}
                        <span className="text-gray-500">
                          {clickedFile?.Description}
                        </span>{" "}
                      </h1>
                      <h1 className="mb-2">
                        {" "}
                        Créée par :{" "}
                        <span className="text-gray-500">
                          {clickedFile?.createdBy}
                        </span>{" "}
                      </h1>
                      <h1 className="mb-2">
                        {" "}
                        Créée le :{" "}
                        <span className="text-gray-500">
                          {clickedFile?.createdAt?.substring(0, 10)}
                        </span>{" "}
                      </h1>
                      {clickedFile?.modificatedAt && (
                        <div>
                          <h1 className="mb-2">
                            {" "}
                            Dernière modification le :{" "}
                            <span className="text-gray-500">
                              {clickedFile?.modificatedAt?.substring(0, 10)}
                            </span>{" "}
                          </h1>{" "}
                          <h1>
                            {" "}
                            Modifié par :{" "}
                            <span className="text-gray-500">
                              {clickedFile?.modificatedBy}
                            </span>{" "}
                          </h1>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={showMoveModal} onHide={handleCloseMoveModal}>
              <Modal.Header>
                <Modal.Title className="text-sm">
                  Déplacer{" "}
                  {clickedFile?.type === "file" ? "le fichier" : "le dossier"}{" "}
                  {clickedFile?.name}
                </Modal.Title>
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={handleCloseMoveModal}
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                    />
                  </svg>
                </button>
              </Modal.Header>
              <Modal.Body>
                <h1 className="mb-2 font-bold"> Déplacer vers :</h1>
                {categories.length > 0 &&
                  categories.map((cat) => (
                    <div
                      key={cat._id}
                      onClick={() => handlePrepareMove(cat._id)}
                      className={`cursor-pointer ${
                        clickedCat === cat._id ? "bg-gray-200" : ""
                      }`}
                    >
                      <span className="px-2 text-blue-900">{cat.name} </span>
                    </div>
                  ))}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="bg-gray-500 hover:bg-gray-700"
                  onClick={handleCloseMoveModal}
                >
                  Annuler
                </Button>
                <Button
                  className="bg-blue-500 hover:bg-blue-700"
                  onClick={() => setShowConfirmMoveModal(true)}
                >
                  Déplacer le fichier
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showConfirmMoveModal}
              dialogClassName="max-w-[700px] top-1/4"
              className=" px-2 bg-transparent "
              onHide={() => setShowConfirmMoveModal(false)}
            >
              {" "}
              {/* Ajouter la classe "modal-lg" pour le rendre plus large */}
              <Modal.Header>
                <Modal.Title className="text-sm font-bold">
                  Confirmation déplacement {clickedFile?.name}
                </Modal.Title>
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setShowConfirmMoveModal(false)}
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
                    />
                  </svg>
                </button>
              </Modal.Header>
              <Modal.Body>
                <h1 className="mb-2 text-sm">
                  {" "}
                  Êtes vous sur de vouloir déplacer{" "}
                  {clickedFile?.type === "file"
                    ? "le fichier"
                    : "le dossier"}{" "}
                  <span className="font-bold">{clickedFile?.name}</span> vers la
                  rubrique: {category?.name} <span className="font-bold"></span>{" "}
                  ?
                </h1>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="bg-gray-500 hover:bg-gray-700"
                  onClick={() => setShowConfirmMoveModal(false)}
                >
                  Annuler
                </Button>
                <Button
                  className="bg-blue-500 hover:bg-blue-700"
                  onClick={handleMoveFileFolder}
                >
                  Confirmer
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showLoadingModal}>
              <Loader />
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientContent;
