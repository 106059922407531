import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Utils/Loader";

const ProtectedRouteClt = ({ children }) => {
  const data = useSelector((state) => state.client);
  const connectedUser = JSON.parse(localStorage.getItem("clt"));

  // //console.log("reducer_collaborateur", data);
  // //console.log("reducer_collaborateur2", data?.isAuthenticated);
  // //console.log("reducer_collaborateur3", data?.collaborateur?.user?.role);
  // //console.log("allowed", allowed);
  // //console.log("alreadyConnected", alreadyConnected);

  if (connectedUser === null) {
    if (data?.isAuthenticated === undefined) {
      return <Loader />;
    } else if (data?.isAuthenticated === false) {
      return <Navigate to="/login" replace />;
    } else if (
      data?.isAuthenticated === true &&
      data?.client?.client?.active === false
    ) {
      toast.warning("Votre compte a été désactivé par un administrateur");
      localStorage.removeItem("clt");
      return <Navigate to="/login" replace />;
    }
  } else if (connectedUser !== null) {
    if (connectedUser?.active === false) {
      toast.warning("Votre compte a été désactivé par un administrateur ");
      return <Navigate to="/login" replace />;
    }
  }

  return children ? children : <Outlet />;
};

export default ProtectedRouteClt;
