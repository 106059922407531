import { useState } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../Utils/Loader";
import { deleteMenu } from "../../redux/actions/supAdmin";

function MenuCrm({ data, setGetUpdated, pasDelete }) {
  //console.log("data", data);

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const dispatch = useDispatch();
  const handleDeleteMenu = async () => {
    setShowLoadingModal(true);
    setDeleteModal(false);
    setConfirmModal(false);
    await dispatch(deleteMenu(data._id));
    setGetUpdated(true);
    setShowLoadingModal(false);
  };

  return (
    <div>
      <Card className="w-[14rem] sm:w-[18rem] md:w-[20rem] mx-2 my-2">
        <Card.Body>
          <Card.Title className="flex justify-between items-center">
            {data.name || ""}{" "}
            <AiOutlineDelete
              className={`cursor-pointer ${
                pasDelete === "pasDelete" ? "hidden" : ""
              }`}
              onClick={() => setDeleteModal(true)}
            />
          </Card.Title>
          <Card.Text>Liste des Catégories : </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          {data &&
            data.menu.length > 0 &&
            data.menu.map((category) => (
              <ListGroup.Item key={category._id}>
                {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Card>
      <Modal show={deleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title className="text-sm">
            Supprimer le menu client {data.name || ""}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeDeleteModal}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center"> Voulez-vous supprimer ce menu ?</h1>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 mr-2 hover:bg-gray-700"
            onClick={closeDeleteModal}
          >
            Annuler
          </Button>
          <Button className="bg-blue-500" onClick={() => setConfirmModal(true)}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmModal}
        dialogClassName="max-w-[700px] top-1/4"
        className=" px-2 bg-transparent "
        onHide={() => setConfirmModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="text-sm">
            Confirmation suppression menu {data.name || ""}
          </Modal.Title>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={closeDeleteModal}
          >
            <span className="sr-only">Fermer</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7 5.3c.4.4.4 1 0 1.4L13.4 12l5.3 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L10.6 12 5.3 6.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 10.6l5.3-5.3c.4-.4 1-.4 1.4 0z"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center">
            {" "}
            Etes-vous vouloir supprimer cette arborescence ?
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-500 mr-2 hover:bg-gray-700"
            onClick={closeDeleteModal}
          >
            Annuler
          </Button>
          <Button className="bg-blue-500" onClick={handleDeleteMenu}>
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLoadingModal}>
        <Loader />
      </Modal>
    </div>
  );
}

export default MenuCrm;
