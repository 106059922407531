import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
};

export const collaborateurReducer = createReducer(initialState, {
  LoginCollaborateurRequest: (state) => {
    state.loading = true;
  },
  LoginCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.collaborateur = action.payload;
  },
  LoginCollaborateurFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  // logout collaborateur
  LogoutCollaborateurRequest: (state) => {
    state.loading = true;
  },
  LogoutCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = false;
    state.loading = false;
    state.collaborateur = action.payload;
  },
  LogoutCollaborateurFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // load a specific client by collaborateur
  LoadDossierClientByCollaborateurRequest: (state) => {
    state.loading = true;
  },
  LoadDossierClientByCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.client = action.payload;
  },
  LoadDossierClientByCollaborateurFail: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.error = action.payload;
  },

  // update dossier client infos by collaborateur
  UpdateDossierClientByCollaborateurRequest: (state) => {
    state.loading = true;
  },
  UpdateDossierClientByCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.updatedClient = action.payload;
  },
  UpdateDossierClientByCollaborateurFail: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.error = action.payload;
  },

  // Get all user by collaborateur
  AllClientsCollaborateurRequest: (state) => {
    state.clientsloading = true;
  },
  AllClientsCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.clientsloading = false;
    state.clients = action.payload;
  },
  AllClientsCollaborateurFail: (state, action) => {
    state.clientsloading = false;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

// Get all user by collaborateur per cabinet
AllClientsCabinetCollaborateurRequest: (state) => {
  state.clientsloading = true;
},
AllClientsCabinetCollaborateurSuccess: (state, action) => {
  state.isAuthenticated = true;
  state.clientsloading = false;
  state.clientsCabinet = action.payload;
},
AllClientsCabinetCollaborateurFail: (state, action) => {
  state.usersloading = true;
  state.error = action.payload;
  state.isAuthenticated = true;
},


  // Get all collaborateurs
  GetCollaborateursRequest: (state) => {
    state.clientsloading = true;
  },
  GetCollaborateursSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.collaborateursloading = false;
    state.allCollaborateurs = action.payload;
  },
  GetCollaborateursFail: (state, action) => {
    state.usersloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

 // Get  collaborateurs per cabinet
 GetCollaborateursCabinetRequest: (state) => {
  state.clientsloading = true;
},
GetCollaborateursCabinetSuccess: (state, action) => {
  state.isAuthenticated = true;
  state.collaborateursloading = false;
  state.allCollaborateursCabinet = action.payload;
},
GetCollaborateurCabinetsFail: (state, action) => {
  state.usersloading = true;
  state.error = action.payload;
  state.isAuthenticated = true;
},


  // Delete one collaborateur
  DeleteCollaborateurRequest: (state) => {
    state.clientsloading = true;
  },
  DeleteCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.collaborateursloading = false;
    state.deletedCollaborateur = action.payload;
  },
  DeleteCollaborateurFail: (state, action) => {
    state.usersloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // Delete one client
  DeleteClientRequest: (state) => {
    state.clientsloading = true;
  },
  DeleteClientSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.collaborateursloading = false;
    state.deletedClient = action.payload;
  },
  DeleteClientFail: (state, action) => {
    state.usersloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // Update  client state
  UpdateClientRequest: (state) => {
    state.clientsloading = true;
  },
  UpdateClientSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.collaborateursloading = false;
    state.updatedClient = action.payload;
  },
  UpdateClientFail: (state, action) => {
    state.usersloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  // Update  collaborateur by admin
  UpdateCollaborateurRequest: (state) => {
    state.clientsloading = true;
  },
  UpdateCollaborateurSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.collaborateursloading = false;
    state.updatedCollaborateur = action.payload;
  },
  UpdateCollaborateurFail: (state, action) => {
    state.usersloading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

   // Update  collaborateur by admin
   UpdateCollaborateurPasswordRequest: (state) => {
    state.loading = true;
  },
  UpdateCollaborateurPasswordSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.updatedPasswordCollaborateur = action.payload;
  },
  UpdateCollaborateurPasswordFail: (state, action) => {
    state.loading = true;
    state.error = action.payload;
    state.isAuthenticated = true;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
