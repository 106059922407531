import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";

const NewPassword = ({ type }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "client") {
      if (localStorage.getItem("emailClient") === null) {
        alert(
          "Merci de recommencer les étapes de réinitialisation du mots de passe"
        );
        return navigate("/forgot-password");
      }
      setEmail(JSON.parse(localStorage.getItem("emailClient")));
    } else if (type === "collaborateur") {
      if (localStorage.getItem("emailCollaborateur") === null) {
        alert(
          "Merci de recommencer les étapes de réinitialisation du mots de passe"
        );
        return navigate("/collaborateur-forgot-password");
      }
      setEmail(JSON.parse(localStorage.getItem("emailCollaborateur")));
    }
  }, []);

  //console.log("email", email);
  // email stocké dans localStorag

  const validatePassword = (value) => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword === "" || confirmPassword === "") {
      return alert("Merci de saisir les informations demandées");
    }
    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule et un chiffre."
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      return alert(
        "Le nouveau mots de passe ne correspond pas au mots de passe confirmé"
      );
    }

    if (newPassword !== confirmPassword) {
      return alert(
        "Le nouveau mot de passe ne correspond pas au mot de passe confirmé"
      );
    }

    setLoading(true);

    const endpoint = type === "client" ? "client" : "collaborateur";

    try {
      const response = await axios.put(
        `${server}/${endpoint}/resetPassword`,
        { email, newPassword },
        { withCredentials: true }
      );

      setNewPassword("");
      setConfirmPassword("");

      if (type === "client") {
        toast.success("Votre mot de passe a été réinitialisé avec succès");
        localStorage.removeItem("emailClient");
        navigate("/login");
      } else if (type === "collaborateur") {
        toast.success(
          "Le mots de passe du collaborateur a été réinitialisé avec succès"
        );
        localStorage.removeItem("emailCollaborateur");
        navigate("/dashboard/collaborateurs");
      }
    } catch (error) {
      alert("Code de réinitialisation n'a pas été vérifié");
    }

    setLoading(false);
  };

  return (
    <div>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="container mx-auto p-4">
          <h2 className="text-2xl font-bold mb-6 mt-4 text-center">
            Votre nouveau mots de passe
          </h2>
          <form onSubmit={handleSubmit} className="max-w-md mx-auto pt-4">
            <div className="mb-4">
              <label htmlFor="email" className="block font-medium mb-1">
                Votre nouveau mots de passe :
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="text"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 border rounded "
                  placeholder="Nouveau mots de passe"
                />
                <span
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block font-medium mb-1">
                Confirmez votre nouveau mots de passe :
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="text"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Confirmer le mots de passe"
                />
                <span
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Réinitialiser le mot de passe
            </button>
          </form>
          {passwordError && (
            <p className="text-red-500 mt-2">{passwordError}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NewPassword;
