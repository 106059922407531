import React, { useEffect } from "react";
import {  useNavigate, useParams } from "react-router-dom";

import ClosedFolderFile from "./ClosedFolderFile.jsx";
import OpenedFolderFile from "./OpenedFolderFile.jsx";

const ArborescenceSide = ({
  dossierClient,
  openedFile,
  setOpenedFile,
  folderFile,
  
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const position = dossierClient?.parentId?.length;
  useEffect(() => {
    if (openedFile?.length <= 1) {
      navigate(`/client/${id}`);
    }
  }, [openedFile]);

  // page folderFile du dossier client : charger from reducer

  return (
    <div className="">
  
  
      {dossierClient?.files?.map((file1) => {
        if (file1.type === "folder" && openedFile?.includes(file1?.pathById)) {
          return (
            <div key={file1._id}>
              <OpenedFolderFile
                file={file1}
                position={position}
                setOpenedFile={setOpenedFile}
                openedFile={openedFile}
                showMoveModal="false"
              />
              {file1?.files?.length > 0 ? (
                <ArborescenceSide
                  dossierClient={file1}
                  folderFile={file1}
                  openedFile={openedFile}
                  setOpenedFile={setOpenedFile}
                  showMoveModal="false"
                />
              ) : (
                <div className="text-center">
                  <span className="italic text-gray-500">Dossier vide</span>
                </div>
              )}
            </div>
          );
        } else if (file1?.type === "folder") {
          return (
            <ClosedFolderFile
              key={file1._id}
              file={file1}
              setOpenedFile={setOpenedFile}
              openedFile={openedFile}
              position={position}
              folderFile={folderFile}
              showMoveModal="false"
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default ArborescenceSide;
