import React from "react";
import { Link } from "react-router-dom";
import { PiFoldersFill } from "react-icons/pi";
import { IoIosPeople } from "react-icons/io";
import { AiOutlineCluster } from "react-icons/ai";
import { BsMenuButtonWide } from "react-icons/bs";

const ComptabledSideBar = ({ active }) => {
  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/dashboard/collaborateurs" className="w-full flex items-center">
          <IoIosPeople
            size={30}
            color={`${active === 1 ? "#4b93f0" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[#4b93f0]" : "text-[#555]"
            }`}
          >
            Collaborateurs
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard/clients" className="w-full flex items-center">
          <PiFoldersFill
            size={30}
            color={`${active === 2 ? "#4b93f0" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[#4b93f0]" : "text-[#555]"
            }`}
          >
            Clients
          </h5>
        </Link>
      </div>
      <div className="w-full flex items-center p-4">
      <Link to="/dashboard/arborescences" className="w-full flex items-center">
      <AiOutlineCluster
          size={30}
          color={`${active === 3 ? "#4b93f0" : "#555"}`}
        />
        <h5
          className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
            active === 3 ? "text-[#4b93f0]" : "text-[#555]"
          }`}
        >
          Arborescences
        </h5>
      </Link>
    </div>
    <div className="w-full flex items-center p-4">
    <Link to="/dashboard/menus" className="w-full flex items-center">
    <BsMenuButtonWide
        size={30}
        color={`${active === 4 ? "#4b93f0" : "#555"}`}
      />
      <h5
        className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
          active === 4 ? "text-[#4b93f0]" : "text-[#555]"
        }`}
      >
        Menu Espace client
      </h5>
    </Link>
  </div>



     
    </div>
  );
};


export default ComptabledSideBar
