import React from "react";
import CabinetHeader from "../../Components/ComptableComponents/CabinetHeader";
import ComptabledSideBar from "../../Components/ComptableComponents/ComptabledSideBar.jsx";
import AllClients from "../../Components/ComptableComponents/AllClients.jsx";
import styles from "../../Styles/Styles";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const GestionClients = () => {
  return (
    <div>
      <CabinetHeader />
      <div className="flex justify-end px-3 ">
        <Link to="/client-register">
          <div className="w-[180px] bg-blue-600 h-[50px] mr-6 mt-3 flex items-center justify-center rounded-xl cursor-pointer hover:bg-blue-700">
            <h1 className="text-[#fff] flex items-center">
              {" "}
              Ajouter un client <IoIosArrowForward className="ml-1" />
            </h1>
          </div>
        </Link>
      </div>
      <div className="flex justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <ComptabledSideBar active={2} />
        </div>
        <div className="w-full justify-center flex">
          <AllClients />
        </div>
      </div>
    </div>
  );
};

export default GestionClients;
